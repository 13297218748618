import React from 'react';

interface Props {
    id: React.Key | null | undefined;
    replies: any;
    isVertical: boolean;
    fullContent: any;
    onSubmit: (message: any, postBack: any) => void;
}
export interface ReplyWithPostBack {
    label: any;
    postBack: any;
}
export const formatMessageWithPostBack = (reply: ReplyWithPostBack): string => {
    return `{"speech":"${reply.label}","postback":"${reply.postBack}"}`;
};
const ChatbotQuickReplies = (props: Props) => {
    const repliesWithPostBack = JSON.parse(props.fullContent).replies.map(
        (repl: any): ReplyWithPostBack => {
            return {
                label: repl.label,
                postBack: repl.payload,
            } as ReplyWithPostBack;
        }
    );

    const renderItem = (reply: ReplyWithPostBack, index: any) => (
        <li
            className={`chatbot__quick-replies--item-${
                props.isVertical ? 'vertical' : 'horizontal'
            }`}
            key={props.id + index}
        >
            <button
                className={`chatbot-replies-button-${
                    props.isVertical ? 'vertical' : 'horizontal'
                }`}
                onClick={() =>
                    props.onSubmit(
                        reply.label,
                        formatMessageWithPostBack(reply)
                    )
                }
            >
                {reply.label}
            </button>
        </li>
    );

    return (
        <ul
            className={`${
                props.isVertical
                    ? 'chatbot__quick-replies--vertical'
                    : 'chatbot__quick-replies--horizontal'
            }`}
        >
            {repliesWithPostBack.map(renderItem)}
        </ul>
    );
};

export default ChatbotQuickReplies;
