import { useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { ServiceLocationDetails } from '../../models/osb-model/osb-service-location';
import {
    StoreState,
    StorePayload,
    ServiceLocationStepState,
} from '../../models/osb-model/osb-store-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { ServiceData } from '../../models/osb-model/osb-dealerservice-info';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { useOSBStep } from './use-osb-step';

export const getInitialServiceLocationState = (): ServiceLocationDetails => {
    return {
        streetName: '',
        houseNumber: '',
        postalCode: '',
        town: '',
        parkingType: '',
        comment: '',
        selectedOtherOptions: [],
        street: '',
    };
};
//Configure and initialize service location step state in global store
const configureServiceLocationStepStore = () => {
    const actions = {
        //Action to set the service location step form details to global store
        SET_SERVICE_LOCATION_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as ServiceLocationStepState)
                    .serviceLocationStep,
                ...(payload as ServiceLocationDetails),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_LOCATION_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { serviceLocationStep: newState };
        },

        SET_SERVICE_LOCATION_STEP_PAYLOAD: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as ServiceLocationStepState)
                    .serviceLocationStep,
                ...(payload as {
                    [key: string]: string | ServiceData[];
                }),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_LOCATION_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { serviceLocationStep: newState };
        },
    };
    initializeStore(actions, {
        serviceLocationStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_LOCATION_STEP_KEY
            ) || getInitialServiceLocationState(),
    });
};

configureServiceLocationStepStore();

// custom hook for service location step to handle the actions and updating payload in global store
export const useServiceLocationStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalServiceLocationDetail = (
        serviceLocationDetails: ServiceLocationDetails,
        shouldReload = true
    ) => {
        dispatch(
            'SET_SERVICE_LOCATION_STEP',
            serviceLocationDetails,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const setGlobalServiceLocationPayload = (
        payload: {
            [key: string]: string | ServiceData[];
        },
        shouldReload = true
    ) => {
        dispatch(
            'SET_SERVICE_LOCATION_STEP_PAYLOAD',
            payload,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetServiceLocationStep = (shouldReload = true) => {
        dispatch(
            'SET_SERVICE_LOCATION_STEP',
            getInitialServiceLocationState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);

    return {
        osbServiceLocationDetail: (globalState as ServiceLocationStepState)
            .serviceLocationStep,
        setOSBServiceLocationStep: setGlobalServiceLocationDetail,
        setOSBServiceLocationStepPayload: setGlobalServiceLocationPayload,
        resetServiceLocationStep: resetServiceLocationStep,
        callContentService: callContentService,
    };
};
