import { ERROR_TYPES, ERROR_TIMERS } from './emplifi-wrapper';
import {
    UPDATE_SNACKBAR_STATE,
    UPDATE_ERROR_LABEL,
    UPDATE_SNACKBAR_TYPE,
    UPDATE_ERROR_REASON,
} from './actionTypes';

export const resetChatbotError = (
    dispatch: (arg0: { type: string; payload: string | boolean }) => void
) => {
    dispatch({ type: UPDATE_SNACKBAR_STATE, payload: false });
    dispatch({ type: UPDATE_ERROR_LABEL, payload: '' });
    dispatch({ type: UPDATE_SNACKBAR_TYPE, payload: '' });
};

export const createErrorHandler = (
    dispatch: (arg0: { type: string; payload: any }) => void
) => (errorLabel: any, snackbarType: any) => {
    dispatch({ type: UPDATE_SNACKBAR_STATE, payload: true });
    dispatch({ type: UPDATE_ERROR_LABEL, payload: errorLabel });
    dispatch({ type: UPDATE_SNACKBAR_TYPE, payload: snackbarType });
};

export const parseChatbotError = (
    error: { message: any },
    dispatch: (arg0: { type: string; payload: string }) => void,
    connectionErrorMessage: any,
    generalErrorMessage: any
) => {
    const handleError = createErrorHandler(dispatch);

    switch (error.message) {
        case 'Unable to connect to server':
            handleError(connectionErrorMessage, ERROR_TYPES.ACTIONABLE);
            break;
        case "Can't send the message":
            dispatch({ type: UPDATE_ERROR_REASON, payload: 'cant-send' });
            handleError(connectionErrorMessage, ERROR_TYPES.ACTIONABLE);
            break;
        default:
            handleError(generalErrorMessage, ERROR_TYPES.SELF_CLOSING);
            break;
    }
};

export const setErrorTimers = (
    dispatch: (arg0: { type: string; payload: any }) => void,
    stillLoadingLabel: any,
    unableToConnectLabel: any
) => {
    const handleError = createErrorHandler(dispatch);

    const stillLoadingTimer = setTimeout(() => {
        handleError(stillLoadingLabel, ERROR_TYPES.SELF_CLOSING);
    }, ERROR_TIMERS.STILL_LOADING);

    const unableToConnectTimer = setTimeout(() => {
        handleError(unableToConnectLabel, ERROR_TYPES.ACTIONABLE);
    }, ERROR_TIMERS.UNABLE_TO_CONNECT);

    return {
        stillLoadingTimer,
        unableToConnectTimer,
    };
};
