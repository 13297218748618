import React, { useEffect, useState } from 'react';
import { ExperienceFragmentModel } from '../../../services/content-service/content-service.interface';
import serverSideService from '../../../services/server-side-service/server-side-service';
import { FdsChevron } from '../chevron/fds-chevron';
import './country-dial-code-picker.scss';
export interface CountryDialCodePickerContent extends ExperienceFragmentModel {
    dropdownOptions: CountryDialCodePickerOptions[];
    ':type': string;
}

export interface CountryDialCodePickerOptions {
    countryDisplayName: string;
    countryRegex: string;
    countryIconPath: string;
    countryDialCode: string;
    countryCode: string;
}
interface Props {
    countryDialCodeContent: CountryDialCodePickerContent | undefined;
    displayDefaultMarketCode?: string;
    selectedCountry: (
        country: CountryDialCodePickerOptions | undefined,
        initialLoad: boolean
    ) => void;
}
function CountryDialCodePicker(props: Props) {
    // master list of country and dial code data
    // Custom hook to fetch data from AEM - experience fragmant, if no props provided
    const countryDialCodeContent = props.countryDialCodeContent;

    // flag to show the combobox menu
    const [isComboboxListVisible, setIsComboboxListVisible] = useState(false);

    // this can be used for filtering - may not have all country data always,
    // by default all country data or empty array
    const [filteredListOfCountryData, setFilteredListOfCountryData] = useState(
        countryDialCodeContent?.dropdownOptions || []
    );

    function getCountryDialCodeIndex(countryCode: string): number {
        const index = countryDialCodeContent?.dropdownOptions.findIndex(
            (item: { countryCode: string }) =>
                item.countryCode.trim().toLowerCase() ===
                countryCode.trim().toLowerCase()
        );

        return index !== undefined && index >= 0 ? index : -1;
    }

    // index of user selected country
    const [selectedCountryIndex, setSelectedCountryIndex] = useState<number>(
        -1
    );

    const [cursor, setCursor] = useState(0);

    function getCountrydata(
        index: number
    ): CountryDialCodePickerOptions | undefined {
        if (index >= 0) {
            if (filteredListOfCountryData.length > 0) {
                return filteredListOfCountryData[index];
            } else {
                return countryDialCodeContent?.dropdownOptions[index];
            }
        }
        return undefined;
    }

    // user entered data - used mainly for keyboard input handling
    const [userInput, setUserInput] = useState('');

    function filterCountryData(value: string) {
        return countryDialCodeContent?.dropdownOptions.filter(countryObj => {
            return countryObj.countryDisplayName
                .toLowerCase()
                .includes(value.toLowerCase());
        });
    }

    function handleUserInputChange(e: { target: { value: any } }) {
        setUserInput(e.target.value);
        setIsComboboxListVisible(true);
        setFilteredListOfCountryData(filterCountryData(e.target.value) || []);
    }

    function renderFlag(imagePath: string): React.ReactNode {
        if (imagePath.length <= 0) return <img src={''} />;
        try {
            return (
                <img
                    style={{ width: 33, height: 22 }}
                    src={`${process.env.REACT_APP_AEM_BASE_URL}${imagePath}`}
                />
            );
        } catch (e) {
            console.error('ERROR - ', e);
            return <img src={''} />;
        }
    }
    function handleKeyDown(e: { keyCode: number }) {
        // arrow up/down button should select next/previous
        // list element and enter key to select
        if (e.keyCode === 38 && cursor > 0) {
            setCursor(cursor - 1);
        } else if (
            e.keyCode === 40 &&
            cursor < filteredListOfCountryData.length - 1
        ) {
            setCursor(cursor + 1);
        } else if (e.keyCode === 13) {
            setSelectedCountryIndex(cursor);
            setUserInput(getCountrydata(cursor)?.countryDisplayName || '');
            props.selectedCountry(getCountrydata(cursor), false);
            setIsComboboxListVisible(false);
        }
    }

    function handleListClick(index: number) {
        setSelectedCountryIndex(index);
        setIsComboboxListVisible(false);
        setCursor(index);
        // Sets the user input text immediately after the user selects it from the combo list
        setUserInput(
            index >= 0
                ? filteredListOfCountryData[index].countryDisplayName
                : ''
        );
        props.selectedCountry(getCountrydata(index), false);
    }

    useEffect(() => {
        // outside focus and click handler
        const concernedElement = document.getElementById(
            'cdc-custom-combobox-container-id'
        );
        function clickedOutsideOfCombobox(event: any) {
            if (!concernedElement?.contains(event.target as Node)) {
                setIsComboboxListVisible(false);
            }
        }
        if (serverSideService.isClientSide()) {
            window.addEventListener('mouseup', clickedOutsideOfCombobox);
            return () => {
                window.removeEventListener('mouseup', clickedOutsideOfCombobox);
            };
        }
    }, []);

    useEffect(() => {
        if (
            countryDialCodeContent?.dropdownOptions &&
            countryDialCodeContent?.dropdownOptions.length > 0
        ) {
            // by default all country data
            setFilteredListOfCountryData(
                countryDialCodeContent?.dropdownOptions
            );
            // by default, current market info
            const currentIndex = getCountryDialCodeIndex(
                props.displayDefaultMarketCode || ''
            );
            setSelectedCountryIndex(currentIndex);
            // default current market name with dial code
            setUserInput(
                getCountrydata(currentIndex)?.countryDisplayName || ''
            );
            // passing default country info to the parent
            props.selectedCountry(getCountrydata(currentIndex), true);
        }
    }, [countryDialCodeContent?.dropdownOptions]);

    return (
        // custom country dial code combobox
        <div
            className="cdc-custom-combobox-container"
            id="cdc-custom-combobox-container-id"
        >
            {/* custom combobox selection */}
            <div
                id="selectionDivId"
                className="cdc-custom-combobox-selection"
                onClick={() => {
                    setIsComboboxListVisible(!isComboboxListVisible);
                }}
            >
                {/* country flag icon rendering */}
                <>
                    {selectedCountryIndex >= 0
                        ? renderFlag(
                              getCountrydata(selectedCountryIndex)
                                  ?.countryIconPath || ''
                          )
                        : ''}
                    {/* selected country name with code  */}
                    <input
                        data-testid="SelectCountryDialCode"
                        type="text"
                        className="cdc-country-name-text"
                        value={userInput}
                        onChange={handleUserInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button type="button" aria-label="choose your country">
                        <FdsChevron
                            direction={isComboboxListVisible ? 'up' : 'down'}
                            type="unfilled"
                        />
                    </button>
                </>
            </div>
            {/* combobox country list */}
            {isComboboxListVisible ? (
                <div className="cdc-custom-country-list-holder">
                    {/* iterating country data and display country flag icon and country name with dial code */}
                    {filteredListOfCountryData.map((country, index) => (
                        <div
                            data-testid={country.countryDisplayName}
                            key={country.countryCode}
                            className={`cdc-custom-combobox-country-item ${
                                cursor === index ? 'cdc-active' : ''
                            }`}
                            onClick={() => handleListClick(index)}
                        >
                            {/* country flag display */}
                            {renderFlag(country.countryIconPath)}
                            {/* country name with code diaplay */}
                            <p>{country.countryDisplayName}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
export default CountryDialCodePicker;
