import {
    AemVideoQuietCardDetails,
    SubCategoryViewContent,
    SubCategoryViewFragment,
    VideoQuietCardDetails,
} from '../../../models/experiencefragments/category-view';
import {
    DisclaimerContent,
    DisclaimersService,
    ProcessedField,
} from '../../../services/disclaimers-service/disclaimers-service';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

export interface UseSubCategoryViewContentReturn {
    disclaimers: DisclaimerContent[];
    content: SubCategoryViewContent;
}

const processCardFields = (card: FMCardProps, fields: any) => {
    return {
        ...card,
        title: fields[0]?.plain,
        titleWithDisclaimer: fields[0]?.node,
        description: fields[1]?.plain,
        descriptionWithDisclaimer: fields[1]?.node,
    };
};

export function useSubCategoryViewContent(
    subCategoryViewFragment: SubCategoryViewFragment
): UseSubCategoryViewContentReturn | undefined {
    const content: SubCategoryViewContent = {};
    let disclaimers: DisclaimerContent[] = [];

    if (subCategoryViewFragment) {
        if (
            subCategoryViewFragment.masthead &&
            !subCategoryViewFragment.masthead.hide
        ) {
            const [
                processedArticle,
                processedDisclaimers,
            ] = DisclaimersService.processArticle(
                subCategoryViewFragment.masthead,
                disclaimers
            );
            content.masthead = processedArticle;
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (subCategoryViewFragment.imgCxArticles) {
            const [
                processedContent,
                newDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['text'],
                'disclaimer',
                disclaimers,
                subCategoryViewFragment.imgCxArticles.heroArticleList,
                (article, fields) => {
                    return {
                        ...article,
                        text: fields[0]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(newDisclaimers);
            content.imgCxArticles = {
                ...subCategoryViewFragment.imgCxArticles,
                heroArticleList: processedContent,
            };
        }

        if (
            subCategoryViewFragment.featureTrio &&
            !subCategoryViewFragment.featureTrio.hide
        ) {
            const [
                processedFields,
                processedDisclaimers,
            ] = DisclaimersService.processFieldsWithDisclaimers(
                ['title', 'subtitle'],
                'disclaimer',
                disclaimers,
                subCategoryViewFragment.featureTrio
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            {
                const [
                    processedCards,
                    processedDisclaimers,
                ] = DisclaimersService.processItemsWithDisclaimers(
                    ['title', 'description'],
                    'disclaimer',
                    disclaimers,
                    subCategoryViewFragment.featureTrio.cards,
                    (card, fields) => processCardFields(card, fields)
                );
                disclaimers = disclaimers.concat(processedDisclaimers);
                content.featureTrio = {
                    ...subCategoryViewFragment.featureTrio,
                    title: processedFields[0]?.plain || '',
                    titleWithDisclaimer: processedFields[0]?.node,
                    subtitle: processedFields[1]?.plain || '',
                    subtitleWithDisclaimer: processedFields[1]?.node,
                    cards: processedCards,
                };
            }
        }

        if (
            subCategoryViewFragment.videoQuietCard &&
            !subCategoryViewFragment.videoQuietCard.hide &&
            subCategoryViewFragment.videoQuietCard.videoQuietCardDetails
        ) {
            const [
                processedVideoQuietCardDetails,
                processedDisclaimers,
            ] = DisclaimersService.processItemWithDisclaimers<
                AemVideoQuietCardDetails,
                VideoQuietCardDetails,
                keyof AemVideoQuietCardDetails
            >(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                subCategoryViewFragment.videoQuietCard.videoQuietCardDetails,
                (item, fields) => {
                    return {
                        ...item,
                        title: fields[0]?.node,
                        description: fields[1]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            content.videoQuietCard = {
                videoQuietCardDetails: processedVideoQuietCardDetails,
            };
        }

        if (
            subCategoryViewFragment.keyValueItemsBar &&
            !subCategoryViewFragment.keyValueItemsBar.hide &&
            subCategoryViewFragment.keyValueItemsBar.keyValueItems.length > 0
        ) {
            content.keyValueItemsBar = subCategoryViewFragment.keyValueItemsBar;
        }

        if (
            subCategoryViewFragment.simpleCards &&
            !subCategoryViewFragment.simpleCards.hide &&
            subCategoryViewFragment.simpleCards.simpleCards.length > 0
        ) {
            const [
                processedCards,
                newDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['title', 'body'],
                'disclaimer',
                disclaimers,
                subCategoryViewFragment.simpleCards.simpleCards,
                (aemCard, fields) => {
                    return {
                        ...aemCard,
                        title: fields[0]?.node,
                        plainTitle: fields[0]?.plain || '',
                        body: fields[1]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(newDisclaimers);
            content.simpleCards = {
                ...subCategoryViewFragment.simpleCards,
                simpleCards: processedCards,
            };
        }

        if (
            subCategoryViewFragment.heroArticle &&
            !subCategoryViewFragment.heroArticle.hide
        ) {
            const [
                processedHeroArticle,
                processedDisclaimers,
            ] = DisclaimersService.processItemWithDisclaimers(
                ['text'],
                'disclaimer',
                disclaimers,
                subCategoryViewFragment.heroArticle,
                (article, fields) => {
                    return {
                        ...article,
                        text: fields[0]?.node,
                    };
                }
            );
            content.heroArticle = processedHeroArticle;
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            subCategoryViewFragment.latestArticles &&
            !subCategoryViewFragment.latestArticles.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                subCategoryViewFragment.latestArticles.articles,
                disclaimers
            );
            content.latestArticles = {
                ...subCategoryViewFragment.latestArticles,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            subCategoryViewFragment.componentBox5050 &&
            !subCategoryViewFragment.componentBox5050.hide
        ) {
            const [
                processedArticle,
                processedDisclaimers,
            ] = DisclaimersService.processArticle(
                subCategoryViewFragment.componentBox5050,
                disclaimers
            );
            content.componentBox5050 = processedArticle;
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            subCategoryViewFragment.popularLinksSection &&
            !subCategoryViewFragment.popularLinksSection.hide
        ) {
            content.popularLinksSection =
                subCategoryViewFragment.popularLinksSection;
        }

        if (
            subCategoryViewFragment.fiftyFiftyCarousel &&
            !subCategoryViewFragment.fiftyFiftyCarousel.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                subCategoryViewFragment.fiftyFiftyCarousel.articles,
                disclaimers
            );
            content.fiftyFiftyCarousel = {
                ...subCategoryViewFragment.fiftyFiftyCarousel,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            subCategoryViewFragment.faqs &&
            !subCategoryViewFragment.faqs?.hide &&
            subCategoryViewFragment.faqs?.questions
        ) {
            let savedFields: (ProcessedField | null)[] = [];
            const [
                ,
                processedDisclaimers,
            ] = DisclaimersService.processItemWithDisclaimers(
                ['title', 'subtitle'],
                'disclaimer',
                disclaimers,
                subCategoryViewFragment.faqs,
                (_item, fields) => {
                    savedFields = fields;
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            {
                const [
                    processedQuestions,
                    processedDisclaimers,
                ] = DisclaimersService.processItemsWithDisclaimers(
                    ['question', 'text'],
                    'disclaimer',
                    disclaimers,
                    subCategoryViewFragment.faqs.questions,
                    (_item, fields) => {
                        return {
                            question: fields[0]?.node,
                            text: fields[1]?.node,
                        };
                    }
                );
                disclaimers = disclaimers.concat(processedDisclaimers);
                content.faqs = {
                    title: savedFields[0]?.node,
                    subtitle: savedFields[1]?.node,
                    questions: processedQuestions,
                };
            }
        }

        return {
            disclaimers,
            content: content,
        };
    }
}
