import React, { useCallback, useEffect, useRef } from 'react';
import { MESSAGE_TYPES } from './emplifi-wrapper';
import ChatbotQuickReplies from './ChatbotQuickReplies';
import ChatbotCards from './ChatbotCards';
import ChatBotCollapsibleMessage from './ChatBotCollapsibleMessage';
import parseHTML from 'html-react-parser';
interface Props {
    chatHistory?: any;
    children?: any;
    onSubmit: any;
    userSentAccessibilityMessage: string;
    chatbotSentAccessibilityMessage: string;
    showLessLabel: string;
    showMoreLabel: string;
    errorReason: string;
    sendFailureMessage: string;
    retryCtaMessage: string;
    retryAction: any;
    isTypingVisible: boolean;
    chatVisible: boolean;
    showMoreAriaLabel: string;
    showLessAriaLabel: string;
    retryMessageAriaLabel: string;
}
const ChatbotWindow = (props: Props) => {
    const chatBotHistory = useRef<HTMLDivElement>(null);
    const renderChatMessage = useCallback(
        (message: {
            type: any;
            content:
                | string
                | number
                | boolean
                | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                  >
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined;
            id: React.Key | null | undefined;
            isVertical: any;
            fullContent: any;
            origin: string;
        }) => {
            switch (message.type) {
                case MESSAGE_TYPES.TEXT:
                    return (
                        <div
                            className={`chatbot-${message.origin}`}
                            aria-label={
                                message.origin === 'user'
                                    ? props.userSentAccessibilityMessage
                                    : props.chatbotSentAccessibilityMessage
                            }
                            key={message.id}
                        >
                            <div
                                className={`chatbot-${message.origin}-message`}
                            >
                                {parseHTML(message.content as string)}
                            </div>
                        </div>
                    );
                case MESSAGE_TYPES.QUICK_REPLIES:
                    return (
                        <ChatbotQuickReplies
                            key={message.id}
                            id={message.id}
                            replies={message.content}
                            isVertical={message.isVertical}
                            fullContent={message.fullContent}
                            onSubmit={props.onSubmit}
                        />
                    );
                case MESSAGE_TYPES.CARDS:
                    return (
                        <ChatbotCards
                            key={message.id}
                            id={message.id}
                            cards={[message.content]}
                            chatbotSentAccessibilityMessage={
                                props.chatbotSentAccessibilityMessage
                            }
                        />
                    );
                case MESSAGE_TYPES.KNOWLEDGE_BASE:
                    return (
                        <ChatBotCollapsibleMessage
                            key={message.id}
                            content={message.content}
                            fullContent={message.fullContent}
                            chatbotSentAccessibilityMessage={
                                props.chatbotSentAccessibilityMessage
                            }
                            showLessLabel={props.showLessLabel}
                            showMoreLabel={props.showMoreLabel}
                            submitMessage={props.onSubmit}
                            showLessAriaLabel={props.showLessAriaLabel}
                            showMoreAriaLabel={props.showMoreAriaLabel}
                        />
                    );
            }
        },
        [
            props.showLessLabel,
            props.showMoreLabel,
            props.userSentAccessibilityMessage,
            props.chatbotSentAccessibilityMessage,
        ]
    );
    useEffect(() => {
        const observer = new MutationObserver((mutations: MutationRecord[]) => {
            for (const mutation of mutations) {
                if (mutation.type === 'childList') {
                    chatBotHistory.current &&
                        chatBotHistory.current.scrollIntoView({ block: 'end' });
                }
            }
        });

        if (props.chatVisible && chatBotHistory.current) {
            observer.observe(chatBotHistory.current, {
                childList: true,
                subtree: true,
            });
            chatBotHistory.current.scrollIntoView({ block: 'end' });
        } else {
            observer.disconnect();
        }
    }, [props.chatVisible]);

    return (
        <div
            className="chatbot__history"
            ref={chatBotHistory}
            aria-live="polite"
        >
            {props.chatHistory.map(renderChatMessage)}
            {props.isTypingVisible && (
                <div className="chatbot-bot chatbot-bot-message chatbot__message--typing">
                    <span className="chatbot__typing--dot" />
                    <span className="chatbot__typing--dot" />
                    <span className="chatbot__typing--dot" />
                </div>
            )}
            {props.errorReason === 'cant-send' && !props.isTypingVisible && (
                <div className="chatbot-error">
                    <span>{props.sendFailureMessage}</span>
                    <button
                        className="chatbot-button-error-retry"
                        onClick={props.retryAction}
                        aria-label={props.retryMessageAriaLabel}
                    >
                        {props.retryCtaMessage}
                    </button>
                </div>
            )}
        </div>
    );
};
export default ChatbotWindow;
