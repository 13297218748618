import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import HttpService from '../http-service/http-service';
import { AxiosResponse } from 'axios';

export interface PreferredDealerResponse {
    disambiguation: boolean;
    dealerResultList: DealerResult[];
}

interface DealerResult {
    dealerId: string;
    dealerName: string;
    city: string;
    country: string;
    cupid: string;
    url: string;
    errorMessage: string;
    scheduleServiceUrl: string;
    smarttprovider: string;
    smarttsubset: string;
    unEncryptedDlrpa: string;
}

export default class PreferredDealerService {
    public getPreferredDealer(vin: string, profile: any) {
        if (vin && profile) {
            let dealerId = '';
            const selectedVehicle = profile.vehicles.find(
                (vehicle: any) => vehicle.vin === vin
            );
            if (selectedVehicle && selectedVehicle.preferredDealer)
                dealerId = selectedVehicle.preferredDealer;
            return dealerId;
        }
        return null;
    }

    public request(dealerId: string): Promise<PreferredDealerResponse | null> {
        const client = 'myford';
        const ovtype = 2;
        const count = 1;
        const isNum = /^\d+$/.test(dealerId);

        const params = {
            client,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            f_cupid: dealerId,
            ovtype,
            count,
            f_dlrpa: '',
        };

        if (isNum) {
            params.f_cupid = '';
            params.f_dlrpa = Buffer.from(dealerId).toString('base64'); // encodeBase64;
        }

        const dealersUrl = DSL_BASE_URL + DSL_API_PATH.DEALER_LIST;

        const response = HttpService.get<PreferredDealerResponse>(
            dealersUrl,
            true,
            {
                params,
            }
        );

        return response.then(this.parseResponse);
    }

    private parseResponse(
        response: AxiosResponse<PreferredDealerResponse>
    ): PreferredDealerResponse | null {
        if (response.data) {
            return response.data;
        }
        return null;
    }
}
