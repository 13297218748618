export const loopFocusWithinComponent = (
    event: KeyboardEvent,
    ref: HTMLDivElement
) => {
    const focusableElementsSelector =
        'button:not([disabled], [tabindex="-1"]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    const focusableElements: HTMLElement[] = Array.from(
        ref.querySelectorAll(focusableElementsSelector)
    );
    const first = focusableElements[0];
    const last = focusableElements[focusableElements.length - 1];
    const active = document.activeElement;
    const index = focusableElements.indexOf(
        focusableElements.filter(el => el == active)[0]
    );
    if (!event.shiftKey) {
        if (index === focusableElements.length - 1) {
            event.preventDefault();
            first.focus();
        }
    } else {
        if (index === 0) {
            event.preventDefault();
            last.focus();
        }
    }
};
