import { useContext, useEffect, useState } from 'react';
import contentService from '../services/content-service/content-service';
import { AemDyfEligibleModelResponse } from '../models/experiencefragments/dyf-eligible-model';
import ServerContext from '../contexts/serverContext';
import { EligibleModelData } from './dyf/use-dyf-eligible-model/use-dyf-eligible-model';
import { DYF_ELIGIBLE_YEARS } from '../constants';
import { StringUtil } from '../components/utils/string-util/string-util';

interface HookState {
    models: EligibleModelData[];
    hasLoaded: boolean;
}

export function useAllDyfEligibleModels(): HookState {
    const { brand, currentRegionCode, currentLanguageRegionCode } = useContext(
        ServerContext
    );
    const [hookState, setHookState] = useState<HookState>({
        models: [],
        hasLoaded: false,
    });

    useEffect(() => {
        DYF_ELIGIBLE_YEARS.forEach((year, index) => {
            contentService
                .getExperience<AemDyfEligibleModelResponse>(
                    `dyf/${year}/`,
                    'dyf-eligible-models',
                    'dyf_eligible_models',
                    brand,
                    currentRegionCode,
                    currentLanguageRegionCode
                )
                .then(result => {
                    if (result.models && result.models?.length > 0) {
                        const modelsToSet: EligibleModelData[] = result.models.map(
                            m => ({
                                ...m,
                                modelYear: `${year}`,
                                modelMake: brand,
                                modelMakeDisplayName: StringUtil.capitalizeFirstLetter(
                                    brand
                                ),
                            })
                        );

                        setHookState(prev => ({
                            ...prev,
                            models: prev.models.concat(modelsToSet),
                        }));
                    }
                });
            if (index == DYF_ELIGIBLE_YEARS.length - 1)
                setHookState(prev => ({
                    ...prev,
                    hasLoaded: true,
                }));
        });
    }, []);
    return hookState;
}
