import React, { ReactNode } from 'react';
import Link from '../link/link';
import { FdsChevron } from '../chevron/fds-chevron';

import './secondary-button.scss';

interface Props {
    onClick?: (e: React.MouseEvent) => void;
    link?: string;
    linkTarget?: string;
    children: ReactNode;
    className?: string;
    noChevron?: boolean;
    dataTestId?: string;
    type?: any;
    preventCtaDefault?: boolean;
    direction?: string;
    disabled?: boolean;
}
const SecondaryButton = (props: Props & React.HTMLProps<HTMLAnchorElement>) => {
    const showChevron =
        props.noChevron === undefined || props.noChevron === false;
    const chevronDirection = props.direction === 'left' ? 'left' : 'right';
    const buttonContent = (
        <>
            {showChevron && (
                <FdsChevron type="filled" direction={chevronDirection} />
            )}
            <span className="secondary-button-text">{props.children}</span>
        </>
    );

    if (!props.children) return <div className="secondary-button" />;

    return (
        <div className="secondary-button" data-testid={props.dataTestId}>
            {props.link ? (
                <Link
                    onClick={props.onClick}
                    role="link"
                    className={`secondary-button-link ${props.className}`}
                    href={props.link}
                    target={props.linkTarget}
                    aria-label={props['aria-label']}
                    preventdefault={`${!!props.preventCtaDefault}`}
                >
                    {buttonContent}
                </Link>
            ) : (
                <button
                    onClick={props.onClick}
                    role="button"
                    type={props.type}
                    className={[
                        `secondary-button-link ${props.className}`,
                        props.disabled ? 'disabled' : '',
                    ]
                        .join(' ')
                        .trim()}
                    aria-label={props['aria-label']}
                    data-testid={`${props.dataTestId}-button`}
                    disabled={props.disabled}
                >
                    {buttonContent}
                </button>
            )}
        </div>
    );
};

export default SecondaryButton;
