import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../../constants';

export class Emplifi {
    userMessage?: string;
    async createConversation() {
        const locale = new AppConfigurationService().currentLanguageRegionCode.replace(
            '-',
            '_'
        );
        const url = `${DSL_BASE_URL}${DSL_API_PATH.HELP_ROUTER_EMPLIFI_CONVERSATION}?locale=${locale}`;
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            contentType: 'application/json',
        };

        return fetch(url, request).then(response => response.json());
    }

    async setConversationLanguage(conversationId: string) {
        const locale = new AppConfigurationService().currentLanguageRegionCode.replace(
            '-',
            '_'
        );
        const url = `${DSL_BASE_URL}${DSL_API_PATH.HELP_ROUTER_EMPLIFI_CONTEXT}?locale=${locale}&id=${conversationId}`;
        const request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return fetch(url, request).then(response => response.json());
    }

    async getBotResponse(
        userMessage: string,
        conversationId: string,
        contentType?: string
    ) {
        const url = `${DSL_BASE_URL}${DSL_API_PATH.HELP_ROUTER_EMPLIFI_SEND_CONVERSATION}?id=${conversationId}`;
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            contentType: 'application/json',
            body: JSON.stringify({
                contentType: contentType || 'text',
                content: userMessage,
            }),
        };

        return fetch(url, request).then(response => response.json());
    }

    async getConversation(conversationId: string) {
        const url = `${DSL_BASE_URL}${DSL_API_PATH.HELP_ROUTER_EMPLIFI_GET_CONVERSATION}?id=${conversationId}`;
        const request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return fetch(url, request).then(response => response.json());
    }
}
