import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VehiclePortal } from '../../components/sections';
import { useAnalytics } from '../../hooks/use-analytics';
import { VehicleAttributes } from '../../models/vehicle-attributes';

import './vehicle-ymm-view.scss';
import useSeoConfig from '../../hooks/use-seo-config';
import ServerContext from '../../contexts/serverContext';
import { StringUtil } from '../../components/utils/string-util/string-util';
import { useServerData } from '../../hooks/use-server-data';
import { getAemVehicleModelData } from '../../hooks/use-aem-vehicle-model-data';
import { VehicleSelector } from '../../components/sections/vehicle-portal/components/vehicle-selector/vehicle-selector';
import { BEV_SEO_KEY_CANDIDATES } from '../../constants';

interface YmmViewPreloadData {
    vehicleAttributes: VehicleAttributes;
}
export const VehicleYmmView = () => {
    const { brand, currentRegionCode, currentLanguageRegionCode } = useContext(
        ServerContext
    );
    const { year = '', model = '' } = useParams<{
        year: string;
        model: string;
    }>();

    const serverDataCallback = async (): Promise<
        YmmViewPreloadData | undefined
    > => {
        const vehicleYmm = await getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            year,
            model,
            'SEO_KEY'
        );
        if (vehicleYmm) {
            const vehicleAttributes: VehicleAttributes = {
                year: parseInt(year, 10),
                make: StringUtil.capitalizeFirstLetter(brand),
                model: vehicleYmm?.model || '',
                ownerState: 1,
                seoKey: model,
                fuelType: BEV_SEO_KEY_CANDIDATES.includes(model) ? 'E' : 'G',
            };
            return Promise.resolve({
                vehicleAttributes,
            });
        } else return Promise.resolve(undefined);
    };

    const [serverData] = useServerData('vehicleYmmView', serverDataCallback, [
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        year,
        model,
    ]);

    const vehicleAttributes = serverData?.data?.vehicleAttributes;
    const [fireEvents] = useAnalytics();
    useEffect(() => {
        if (vehicleAttributes) {
            fireEvents(
                ['target-trigger-view', 'owner-veh-info'],
                'target-owner-veh-info',
                {
                    ymm: vehicleAttributes,
                }
            );
        }
    }, [vehicleAttributes]);

    const isFrCa = currentLanguageRegionCode?.toLowerCase() === 'fr-ca';
    const fieldName = isFrCa ? 'mmy' : 'ymm';
    const frenchCanadaYMM = isFrCa
        ? `${vehicleAttributes?.make} ${vehicleAttributes?.model} ${vehicleAttributes?.year}`
        : `${vehicleAttributes?.year} ${vehicleAttributes?.make} ${vehicleAttributes?.model}`;
    const fieldValue = vehicleAttributes ? frenchCanadaYMM : '';

    useSeoConfig('vehicle-portal', undefined, [
        {
            fieldName,
            fieldValue,
        },
        {
            fieldName: 'year',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.year}` : '',
        },
        {
            fieldName: 'make',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.make}` : '',
        },
        {
            fieldName: 'model',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.model}` : '',
        },
    ]);

    return (
        <>
            {vehicleAttributes && (
                <VehiclePortal
                    vehicleAttributes={vehicleAttributes}
                    page="YMM"
                    topMasthead={
                        <VehicleSelector
                            currentSearchVehicle={vehicleAttributes}
                        />
                    }
                />
            )}
        </>
    );
};

export default VehicleYmmView;
