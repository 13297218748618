import React, { useEffect } from 'react';

import { ViewportContextProvider } from '../../../contexts/owners-osb';
import { buildNavigationUrl } from '../../../components/sections/owners-osb/osb-utils';
import { OsbPathVehicleStep } from '../../../components/sections/osb-v3/osb-view-controller';
import { useHistory } from 'react-router-dom';
import OSBProgressBar from '../../../components/sections/osb-v3/osb-progress-bar/osb-progress-bar';
import { useOSBStep } from '../../../hooks/owners-osb';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import { API_REQUEST_SOURCE_TYPE } from '../../../components/sections/owners-osb/osb-constant';

export function OsbMainWrapperView(props: any) {
    const history = useHistory();
    const { setOSBStepPayload } = useOSBStep();
    const authService = new AuthenticationService();
    const queryParams = history.location.search;
    useEffect(() => {
        history.push(buildNavigationUrl(OsbPathVehicleStep(), queryParams));
    }, []);

    useEffect(() => {
        setOSBStepPayload({
            source:
                authService.getwebviewBundle()?.source ||
                API_REQUEST_SOURCE_TYPE.WEB,
        });
    }, []);

    return (
        <ViewportContextProvider>
            <div className="osb-main-wrapper-view">
                <OSBProgressBar />
                {props.children}
            </div>
        </ViewportContextProvider>
    );
}
