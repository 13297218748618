import React, { useEffect, useState } from 'react';
import { FdsChevron } from '../chevron/fds-chevron';
import PrimaryButton from '../primary-button/primary-button';
import { usePaginationCxContent } from './hooks/use-pagination-cx';
import { useLocation, useParams } from 'react-router-dom';
import { useBreakpoints } from '../../../hooks/use-breakpoints';
import serverSideService from '../../../services/server-side-service/server-side-service';
import './pagination-cx.scss';
interface Props {
    data: any;
    desktopItemsPerPage: number;
    mobileItemsPerPage: number;
    totalItems: number;
}

export const PaginationCX = (props: Props) => {
    const [currentData, setCurrentData] = useState<JSX.Element[]>();
    const [currentPage, setCurrentPage] = useState(1);
    const { pathname } = useLocation();
    const { mobile } = useBreakpoints();
    const itemsPerPage = mobile
        ? props.mobileItemsPerPage
        : props.desktopItemsPerPage;

    const maxPage = Math.ceil(props.data.length / itemsPerPage);
    const paginationCxContent = usePaginationCxContent();
    const data = () => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        const postData = props.data.slice(begin, end);
        setCurrentData(postData);
    };
    const featureTrioTitleScroll = serverSideService.isServerSide()
        ? document.querySelector('.scroll-into-view')
        : null;
    const showingTextScroll = serverSideService.isServerSide()
        ? document.querySelector('.pagination-cx-current-view')
        : null;

    const scrollView = featureTrioTitleScroll ?? showingTextScroll;
    const preLastItem = currentPage * itemsPerPage;
    const firstItem = preLastItem - itemsPerPage + 1;

    const lastItem =
        preLastItem > props.totalItems ? props.totalItems : preLastItem;
    const next = () => {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
        currentPage < maxPage &&
            scrollView?.scrollIntoView({
                behavior:
                    'scrollBehavior' in document.documentElement.style
                        ? 'smooth'
                        : 'auto',
            });
    };

    const prev = () => {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
        currentPage > 1 &&
            scrollView?.scrollIntoView({
                behavior:
                    'scrollBehavior' in document.documentElement.style
                        ? 'smooth'
                        : 'auto',
            });
    };
    const { year } = useParams<{
        year: string;
    }>();
    const IsCategoryPage = !(
        pathname?.endsWith(year) || pathname?.endsWith(`${year}/`)
    );

    useEffect(() => {
        data();
    }, [currentPage]);

    if (paginationCxContent && IsCategoryPage) {
        return (
            <div className="pagination-cx">
                <p
                    className="pagination-cx-current-view"
                    id="pagination-cx-view"
                >
                    {paginationCxContent.showingText}
                    <strong>
                        {' '}
                        {firstItem} - {lastItem}{' '}
                    </strong>
                    {paginationCxContent.ofText}
                    <strong> {props.totalItems} </strong>
                    {paginationCxContent.articlesText}
                </p>
                <section className="feature-trio-cards">{currentData}</section>
                {props.totalItems > itemsPerPage && (
                    <nav className="pagination" aria-label="pagination">
                        <div className="fds-pagination">
                            <PrimaryButton
                                className={`pagination-cx-button ${
                                    currentPage === 1 ? 'disabled' : ''
                                }`}
                                color="dark"
                                fill="fill"
                                chevron={false}
                                onClick={prev}
                                ariaLabel={
                                    paginationCxContent.previousAriaLabel ??
                                    'Previous'
                                }
                            >
                                <FdsChevron type="unfilled" direction="left" />
                            </PrimaryButton>
                            <ul className="mobile-pagination">
                                <li className="fds-pagination__item">
                                    <span className="fds-pagination__link">
                                        {currentPage}
                                        <span className="pagination-of">/</span>
                                        {maxPage}
                                    </span>
                                </li>
                            </ul>
                            <PrimaryButton
                                className={`pagination-cx-button ${
                                    currentPage === maxPage ? 'disabled' : ''
                                }`}
                                color="dark"
                                fill="fill"
                                chevron={false}
                                onClick={next}
                                ariaLabel={
                                    paginationCxContent.nextAriaLabel ?? 'Next'
                                }
                            >
                                <FdsChevron type="unfilled" direction="right" />
                            </PrimaryButton>
                        </div>
                    </nav>
                )}
            </div>
        );
    }
    return <section className="feature-trio-cards">{props.data}</section>;
};
