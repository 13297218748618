import { SiteMapResponse } from '../../models/siteMapResponse';
import { Topic } from '../../models/topic';
import {
    ASTUTE,
    BRAND_ATTRIBUTES,
    DESCRIPTION_ELLIPSIS,
    SUPPORT_HUB_PREFIXES_NOT_FOR_BBT,
    SUPPORT_HUB_PREFIXES_NOT_FOR_SITEMAP,
    SUPPORT_HUB_REGEX,
} from '../../support-constants';
import siteMapService from '../site-map-service/site-map-service';
import xss from 'xss';
import { PlaceholderResponse } from '../../models/place-holder-response';
import { TopicPage } from '../../models/topic-page';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { SuggestedTopic } from '../../models/suggested-topic';
import cacheService from '../../../services/cache-service/cache-service';
import { CategoryPage } from '../../models/category-page';
import { SubCategoryPage } from '../../models/sub-category-page';
import ServiceHandler from '../../../services/service-handler';
import serverSideService from '../../../services/server-side-service/server-side-service';
import HttpService from '../../../services/http-service/http-service';
import { Category } from '../../models/category';
import { CategoryValue } from '../../models/categoryValue';
import { CatSubcatFilteredResponse } from '../../models/cat-subcat-filtered-response';
import slugify from 'slugify';
import RteService from './rte-service';
import EmplifiService from './emplifi-service';
import {
    FlatArticle,
    SiteArticleSingleResponse,
} from '../../models/dyf-filtered-articles';
const httpsAgent = new HttpsProxyAgent(
    process.env.http_proxy || 'http://internet.ford.com:83'
);
export interface AstuteCacheResponse {
    [prefix: string]: Topic[];
}

interface BrandAndLanguageRegionCode {
    languageRegionCode: string;
    brand: string;
}

export default class AstuteService {
    private emplifiService = new EmplifiService();
    private rteService = new RteService();

    public async getTopicById(
        category: string,
        subCategory: string,
        topicLabel: string,
        languageRegionCode: string,
        domain: string,
        root: string,
        brand: string
    ): Promise<TopicPage> {
        let topicPage: TopicPage = {
            topicLabel: '',
            topicHeading: '',
            bodyWoHeading: '',
            metaDescription: '',
            hrefLangs: '',
            canonical: '',
            topicAbsoluteSeo: '',
            topicId: 0,
            sessionId: '',
        };
        try {
            const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
            const siteMapData = await this.getSitemapDataFromCache(
                languageRegionCode,
                sitemapTag,
                brand,
                true
            );
            let topicPageCanonical = '';
            let categorySubCategoryFound = '';
            const topics = siteMapData?.filter(topic => {
                return (
                    topicLabel === topic.topicLabelSeo ||
                    topic.oldTopicLabels?.includes(topicLabel)
                );
            });
            const topicFound = topics?.filter(topic => {
                topicPageCanonical = this.getTopicAbsoluteSeo(topic);
                const topicSplit = topicPageCanonical?.split('/');
                const canonicalCategory = topicSplit && topicSplit[1];
                const canonicalSubCategory = topicSplit && topicSplit[2];
                if (
                    this.validateCanonicalCategoryAndSubCategory(
                        canonicalCategory,
                        category,
                        canonicalSubCategory,
                        subCategory
                    )
                )
                    categorySubCategoryFound = this.getTopicAbsoluteSeo(topic);

                return (
                    canonicalCategory === category &&
                    canonicalSubCategory === subCategory &&
                    topic.topicLabelSeo === topicLabel
                );
            });
            if (this.isValidTopicFound(topicFound)) {
                topicPage.topicAbsoluteSeo = categorySubCategoryFound
                    ? categorySubCategoryFound
                    : topicPageCanonical;
                return topicPage;
            }
            const sessionID = await this.getSessionIdForSearch(
                languageRegionCode,
                brand
            );
            const topicContent = await this.getTopicContentByTopicId(
                topicFound[0],
                siteMapData,
                languageRegionCode,
                root,
                sessionID,
                brand,
                false
            );
            topicPage = topicContent || topicPage;
            const translatedRoute = siteMapService
                .getPathFromRouterConfigs(
                    languageRegionCode,
                    brand,
                    'AstuteTopic',
                    categorySubCategoryFound?.substring(1).split('/') || []
                )
                ?.concat(categorySubCategoryFound?.endsWith('/') ? '/' : '');

            const currentRoot = root.substring(0, root.length - 1);
            const link = `https://${domain}${currentRoot}${translatedRoute}`;
            topicPage.bodyWoHeading = topicPage.bodyWoHeading?.replace(
                SUPPORT_HUB_REGEX.HREF_HASH,
                `href="${link}#`
            );
            topicPage.canonical = link;
            topicPage.sessionId = sessionID;
            return topicPage;
        } catch (error) {
            console.error(
                `getTopicById - ${error} ${category} ${subCategory} ${topicLabel}`
            );
            throw new Error(`getTopicById - ${error}`);
        }
    }

    public async getTopicContentByTopicId(
        topic: Topic,
        siteMapData: Topic[],
        languageRegionCode: string,
        root: string,
        sessionID: string,
        brand: string,
        isSearch: boolean
    ) {
        const topicPage: TopicPage = {
            topicLabel: '',
            categoryName: '',
            subCategoryName: '',
            topicHeading: '',
            bodyWoHeading: '',
            metaDescription: '',
            hrefLangs: '',
            topicId: 0,
        };
        try {
            topicPage.topicId = topic.topicId;
            const topicSplit = topic.topicAbsoluteSeo?.split('/');
            topicPage.topicLabel = topic.topicLabel;
            topicPage.noIndex = topic.noIndex;
            topic.categories?.forEach(category => {
                this.assignCategoryNameForTopicPage(
                    topicSplit,
                    category,
                    topicPage
                );
            });

            if (!isSearch) {
                const topicseo = await Promise.all(
                    this.getHrefLangsForSupportedMarkets(
                        topicPage.topicId,
                        brand
                    )
                );
                topicPage.hrefLangs = topicseo && topicseo.join(' ');
            }
            const topicData = await this.astuteCallByTopicId(
                topicPage.topicId,
                languageRegionCode,
                brand,
                sessionID
            );
            if (topicData) {
                topicData.response = await this.buildAdditionalTopicUrls(
                    topicData,
                    siteMapData,
                    root,
                    languageRegionCode,
                    brand
                );
                const sanitizedHtml = this.sanitizeHtml(topicData.response);
                const topicDataSeparated = this.getTopicBodyWoHeading(
                    sanitizedHtml
                );
                topicPage.bodyWoHeading = topicDataSeparated.topicData;
                topicPage.topicHeading = topicDataSeparated.topicHeading;
                let metaDescription;
                try {
                    metaDescription = await this.getTopicAttributes(
                        brand,
                        topicPage.topicId,
                        languageRegionCode
                    );
                } catch (e) {
                    console.error(
                        `Inside getTopicContentByTopicId - getTopicAttributes - ${e} ${topic.topicId}`
                    );
                }
                topicPage.metaDescription = this.validateForMetaDescription(
                    metaDescription,
                    topicPage.bodyWoHeading
                );
                this.astuteSearch(
                    topicPage.topicLabel || 'Default',
                    sessionID,
                    brand,
                    languageRegionCode,
                    topicPage.topicId
                ).then(searchResult => {
                    if (searchResult?.status && searchResult.status != 1) {
                        console.error(
                            'Invalid status',
                            searchResult.status,
                            'for topic id & label',
                            topicPage.topicId,
                            topicPage.topicLabel
                        );
                    }
                });
            }
            return topicPage;
        } catch (error) {
            console.error(
                `getTopicContentByTopicId - ${error} ${topic.topicId}`
            );
            throw new Error(`getTopicContentByTopicId - ${error}`);
        }
    }

    private validateCanonicalCategoryAndSubCategory(
        canonicalCategory: undefined | string,
        category: string,
        canonicalSubCategory: undefined | string,
        subCategory: string
    ) {
        return (
            canonicalCategory === category ||
            canonicalSubCategory === subCategory
        );
    }

    private isValidTopicFound(topicFound: Topic[]) {
        return !topicFound || topicFound.length === 0;
    }

    private getTopicAbsoluteSeo(topic: Topic) {
        return topic.topicAbsoluteSeo || '';
    }

    private getTopicName(topic: Topic) {
        return topic.topicName || '';
    }

    private getTopicLabelSeo(topic: Topic) {
        return topic.topicLabelSeo || '';
    }

    private getOldTopicLabels(topic: Topic) {
        return topic.oldTopicLabels || [];
    }

    private validateForMetaDescription(
        metaDescription: any,
        topicPageBodyWoHeading: string
    ) {
        return metaDescription?.Description
            ? metaDescription?.Description
            : this.getMetaDescription(topicPageBodyWoHeading) || '';
    }

    private assignCategoryNameForTopicPage(
        topicSplit: string[] | undefined,
        category: Category,
        topicPage: TopicPage
    ) {
        if (topicSplit && category.localizedCategoryNameSeo === topicSplit[1]) {
            topicPage.categoryName = category.localizedCategoryName;

            category.categoryValues.forEach(subCategory => {
                this.assignSubCategoryNameForTopicPage(
                    topicSplit,
                    subCategory,
                    topicPage
                );
            });
        }
    }

    private assignSubCategoryNameForTopicPage(
        topicSplit: string[] | undefined,
        subCategory: CategoryValue,
        topicPage: TopicPage
    ) {
        if (
            topicSplit &&
            subCategory.localizedCategoryValueSeo === topicSplit[2]
        )
            topicPage.subCategoryName = subCategory.localizedCategoryValue;
    }

    public async buildAdditionalTopicUrls(
        topicData: PlaceholderResponse,
        siteMapData: Topic[],
        root: string,
        languageRegionCode: string,
        brand: string
    ): Promise<string> {
        let markup = topicData.response;

        topicData.embeddedContents.forEach(content => {
            if (content.type === 'TOPIC') {
                const topic = siteMapData.find(
                    topic => topic.topicId.toString() === content.contentId
                );

                const currentRoot = root.substring(0, root.length - 1);
                const topicAbsoluteSeo =
                    topic && topic.topicAbsoluteSeo?.endsWith('/') ? '/' : '';
                const url = topic
                    ? `${currentRoot}${siteMapService
                          .getPathFromRouterConfigs(
                              languageRegionCode,
                              brand,
                              'AstuteTopic',
                              topic.topicAbsoluteSeo?.substring(1).split('/') ||
                                  []
                          )
                          ?.concat(topicAbsoluteSeo)}`
                    : `${currentRoot}${siteMapService.getPathFromRouterConfigs(
                          languageRegionCode,
                          brand,
                          'AstuteSearch',
                          [content.text]
                      )}`;
                const snippet = `<a href="${url}">${content.text}</a>`;
                markup = markup.replace(content.snippet, snippet);
            }
        });
        return markup;
    }
    public sanitizeString(source: string, languageCode: string) {
        const copyRightsRemoved = source?.replace(
            SUPPORT_HUB_REGEX.COPYRIGHTS_TRADE_REGISTERED_MARKS,
            ''
        );
        const slugifiedUrl = ASTUTE.SKIP_SLUGIFY_LANGUAGES.includes(
            languageCode
        )
            ? copyRightsRemoved
                  ?.toLocaleLowerCase(languageCode)
                  ?.replace(SUPPORT_HUB_REGEX.SPACES, '-')
                  ?.replace(SUPPORT_HUB_REGEX.FORWARD_SLASH, '')
            : copyRightsRemoved &&
              slugify(copyRightsRemoved, {
                  locale: languageCode,
                  lower: true,
              });

        return slugifiedUrl
            ?.replace(SUPPORT_HUB_REGEX.PERIOD, '-')
            ?.replace(SUPPORT_HUB_REGEX.INVALID_CHARS, '');
    }
    public sanitizeHtml(rawHtml: string): string {
        const dangerouslySetInnerHTML = xss(rawHtml, {
            onIgnoreTag(tag, html) {
                if (ASTUTE.WHITELIST_XSS_MARKUP.includes(tag)) {
                    return html;
                }
                return '';
            },
            onIgnoreTagAttr: function(tag, name, value) {
                if (ASTUTE.WHITELIST_XSS_ATTRIBUTES.includes(name)) {
                    return name + '="' + value + '"';
                }
            },
        });
        return dangerouslySetInnerHTML;
    }
    public getMetaDescription(topicData: string): string {
        const metaDescription = topicData
            .replace(SUPPORT_HUB_REGEX.ALL_MARKUP_TAGS, '')
            .replace(SUPPORT_HUB_REGEX.FIND_ALL_TABS_NEW_LINE, '')
            .replace(SUPPORT_HUB_REGEX.NON_BREAKING_SPACE, ' ')
            .replace(SUPPORT_HUB_REGEX.FIND_FIRST_260_CHARS, '$1')
            .trim()
            .concat(DESCRIPTION_ELLIPSIS);
        return metaDescription;
    }
    public getTopicBodyWoHeading(topicData: string) {
        const astuteData = topicData;
        const markupTopicHeading =
            astuteData.indexOf(`<${ASTUTE.HEADING_TAG}>`) >= 0
                ? astuteData.substring(
                      astuteData.indexOf(`<${ASTUTE.HEADING_TAG}>`),
                      astuteData.indexOf(`</${ASTUTE.HEADING_TAG}>`)
                  )
                : astuteData.substring(
                      astuteData.indexOf('<h4>'),
                      astuteData.indexOf('</h4>')
                  );

        return {
            topicHeading: markupTopicHeading,
            topicData: astuteData.replace(markupTopicHeading, '').trim(),
        };
    }
    private redisCache = (
        prefix: string,
        supportedMarket: string,
        brand: string
    ) => {
        return cacheService.getFromRedis(
            ASTUTE.SITE_MAP_KEY_PREFIX + prefix + supportedMarket + '-' + brand
        );
    };
    public async getSitemapDataFromRedisCache(
        supportedMarket: string,
        prefixes: string[],
        brand: string
    ): Promise<Topic[]> {
        const topics: Topic[][] = [];
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        const bbtTag = (BRAND_ATTRIBUTES as any)[brand].bbt;
        let redisCacheResponse = await Promise.all(
            prefixes.map(async prefix => {
                return await this.redisCache(prefix, supportedMarket, brand);
            })
        );
        const prefixesArrToExclude =
            prefixes === sitemapTag
                ? SUPPORT_HUB_PREFIXES_NOT_FOR_SITEMAP
                : SUPPORT_HUB_PREFIXES_NOT_FOR_BBT;

        if (prefixes === sitemapTag || prefixes === bbtTag) {
            let arrAb = await Promise.all(
                prefixesArrToExclude.map(async prefix => {
                    return await this.redisCache(
                        prefix,
                        supportedMarket,
                        brand
                    );
                })
            );
            arrAb = arrAb.flat();
            const abTopics: any = {};
            arrAb.forEach(ab => {
                abTopics[ab && ab.topicId] = true;
            });

            redisCacheResponse = redisCacheResponse.map(arrAf => {
                return arrAf?.filter((af: { topicId: any }) => {
                    return !abTopics[af.topicId];
                });
            });
        }

        if (redisCacheResponse) {
            redisCacheResponse.forEach((resp: any) => {
                resp?.forEach((res: any) => {
                    res?.categories?.forEach((resCat: any) => {
                        resCat.categoryValues = resCat?.categoryValues.filter(
                            (set => (f: any) =>
                                !set.has(f.value) && set.add(f.value))(
                                new Set()
                            )
                        );
                    });
                });
                topics.push(resp);
            });
        }
        return topics.flat();
    }
    public async getSitemapDataFromCache(
        supportedMarket: string,
        prefixes: string[],
        brand: string,
        skipSitemapCheck?: boolean
    ): Promise<Topic[]> {
        const topics: Topic[][] = [];
        let cacheDataForPrefixes: Topic[][] = [];
        const redisCacheResponse = await this.getAstuteFromCacheForRegion(
            supportedMarket,
            brand
        );

        prefixes.forEach(prefix => {
            redisCacheResponse.forEach(
                cacheData =>
                    cacheData[prefix] &&
                    cacheDataForPrefixes.push(cacheData[prefix])
            );
        });

        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        const bbtTag = (BRAND_ATTRIBUTES as any)[brand].bbt;
        const prefixesArrToExclude = this.getPrefixesArrToExclude(
            prefixes,
            sitemapTag
        );

        if (
            this.validatePrefixesAgainstSiteMapAndBbtTag(
                prefixes,
                sitemapTag,
                bbtTag
            )
        ) {
            let arrAb: any[] = [];
            prefixesArrToExclude.forEach(prefix => {
                redisCacheResponse.forEach(
                    cacheData =>
                        cacheData[prefix] && arrAb.push(cacheData[prefix])
                );
            });

            arrAb = arrAb && arrAb.flat();
            const abTopics: any = {};
            arrAb.forEach(ab => {
                if (ab) abTopics[ab.topicId] = true;
            });

            cacheDataForPrefixes =
                prefixes === sitemapTag
                    ? cacheDataForPrefixes.map(arrAf => {
                          return arrAf?.map(af => {
                              af.noIndex = this.doesTopicIdExist(abTopics, af);
                              return af;
                          });
                      })
                    : cacheDataForPrefixes;

            cacheDataForPrefixes = this.getCacheDataForPrefixes(
                skipSitemapCheck,
                cacheDataForPrefixes,
                abTopics
            );
        }

        if (cacheDataForPrefixes) {
            cacheDataForPrefixes.forEach(resp => {
                resp?.forEach(res => {
                    res?.categories?.forEach((resCat: any) => {
                        resCat.categoryValues = resCat?.categoryValues.filter(
                            (set => (f: any) =>
                                !set.has(f.value) && set.add(f.value))(
                                new Set()
                            )
                        );
                    });
                });
                topics.push(resp);
            });
        }
        return topics.flat();
    }

    private getPrefixesArrToExclude(prefixes: string[], sitemapTag: any) {
        return prefixes === sitemapTag
            ? SUPPORT_HUB_PREFIXES_NOT_FOR_SITEMAP
            : SUPPORT_HUB_PREFIXES_NOT_FOR_BBT;
    }

    private validatePrefixesAgainstSiteMapAndBbtTag(
        prefixes: string[],
        sitemapTag: any,
        bbtTag: any
    ) {
        return prefixes === sitemapTag || prefixes === bbtTag;
    }

    private doesTopicIdExist(abTopics: any, af: Topic) {
        return abTopics[af.topicId] ? true : false;
    }

    private getCacheDataForPrefixes(
        skipSitemapCheck: undefined | boolean,
        cacheDataForPrefixes: Topic[][],
        abTopics: any
    ) {
        if (!skipSitemapCheck) {
            cacheDataForPrefixes = cacheDataForPrefixes.map(arrAf => {
                return arrAf?.filter(af => {
                    return !abTopics[af.topicId];
                });
            });
        }
        return cacheDataForPrefixes;
    }

    public cacheSiteMap() {
        ASTUTE.SUPPORTED_MARKETS.forEach(market => {
            ASTUTE.BRANDS.forEach(brand => {
                const marketDetails = siteMapService
                    .getMarketDetails()
                    .find(
                        configMarket =>
                            configMarket.languageRegionCode === market &&
                            configMarket.brand === brand
                    );
                const cachePrefixes = (BRAND_ATTRIBUTES as any)[brand].cache;
                marketDetails &&
                    cachePrefixes.forEach(async (prefix: string) => {
                        await this.cacheSupportedMarket(market, prefix, brand);
                    });
            });
        });
    }
    public async getAstuteCacheData(supportedMarket: string, brand: string) {
        const cachePrefixes = (BRAND_ATTRIBUTES as any)[brand].cache;
        return await Promise.all(
            cachePrefixes.map(async (prefix: string) => {
                return {
                    [prefix]: await cacheService.getFromRedis(
                        ASTUTE.SITE_MAP_KEY_PREFIX +
                            prefix +
                            supportedMarket +
                            '-' +
                            brand
                    ),
                };
            })
        );
    }
    public async cacheSupportedMarket(
        supportedMarket: string,
        supportHubPrefix: string,
        brand: string
    ) {
        let astuteSiteMapPageNumber = 0;
        const filteredTopics: Topic[] = [];
        const firstPageOfSiteMap = await this.getSiteMap(
            astuteSiteMapPageNumber,
            supportedMarket,
            brand
        );
        const languageCode = supportedMarket.split('-')[0];
        const siteMaps: SiteMapResponse[] = [firstPageOfSiteMap];
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        while (astuteSiteMapPageNumber < firstPageOfSiteMap.totalPages) {
            astuteSiteMapPageNumber++;
            siteMaps.push(
                await this.getSiteMap(
                    astuteSiteMapPageNumber,
                    supportedMarket,
                    brand
                )
            );
        }
        const cachedSitemap = await this.getSitemapDataFromCache(
            supportedMarket,
            sitemapTag,
            brand,
            true
        );
        siteMaps.forEach(sitemap => {
            sitemap.topics?.forEach(topic => {
                if (topic.categories && topic.categories.length > 0) {
                    const filteredCategories = topic.categories?.filter(
                        category =>
                            category.categoryName.startsWith(supportHubPrefix)
                    );
                    topic.topicLabel = topic.topicLabel?.replace(
                        SUPPORT_HUB_REGEX.AMPERSAND,
                        '&'
                    );
                    topic.topicLabelSeo = this.sanitizeString(
                        topic.topicLabel,
                        languageCode
                    );
                    const cachedTopic = cachedSitemap.find(
                        top => top.topicId === topic.topicId
                    );
                    topic.oldTopicLabels = !cachedTopic?.oldTopicLabels
                        ? []
                        : cachedTopic?.oldTopicLabels;
                    if (
                        topic.oldTopicLabels &&
                        (topic.oldTopicLabels.length === 0 ||
                            !topic.oldTopicLabels.includes(topic.topicLabelSeo))
                    )
                        topic.oldTopicLabels.push(topic.topicLabelSeo);
                    filteredCategories?.forEach(category => {
                        category.categoryName = category.categoryName?.replace(
                            supportHubPrefix,
                            ''
                        );
                        category.localizedCategoryName = category.localizedCategoryName?.replace(
                            supportHubPrefix,
                            ''
                        );
                        category.localizedCategoryNameSeo = this.sanitizeString(
                            category.localizedCategoryName,
                            languageCode
                        );

                        category.categoryValues.forEach(subCat => {
                            subCat.localizedCategoryValueSeo = this.sanitizeString(
                                subCat.localizedCategoryValue,
                                languageCode
                            );
                            subCat.categoryValueAbsoluteSeo = `/${category.localizedCategoryNameSeo}/${subCat.localizedCategoryValueSeo}/`;
                        });
                        category.categoryAbsoluteSeo = `/${category.localizedCategoryNameSeo}/`;
                        topic.topicAbsoluteSeo = `/${category.localizedCategoryNameSeo}/${category.categoryValues[0]?.localizedCategoryValueSeo}/${topic.topicLabelSeo}`;
                        topic.topicAbsoluteSeo = ASTUTE.SUPPORTED_MARKETS_WITH_ENDING_SLASH.includes(
                            supportedMarket
                        )
                            ? topic?.topicAbsoluteSeo?.concat('/')
                            : topic.topicAbsoluteSeo;
                        const topicCopy = {
                            topicId: topic.topicId,
                            topicName: topic.topicName,
                            topicLabel: topic.topicLabel,
                            topicAbsoluteSeo: topic.topicAbsoluteSeo,
                            topicLabelSeo: topic.topicLabelSeo,
                            oldTopicLabels: topic.oldTopicLabels,
                            categories: [category],
                        };
                        filteredTopics.push(topicCopy);
                    });
                }
            });
        });
        cacheService.putInRedis(
            ASTUTE.SITE_MAP_KEY_PREFIX +
                supportHubPrefix +
                supportedMarket +
                '-' +
                brand,
            filteredTopics,
            ASTUTE.SITE_MAP_CACHE_EXP_SEC
        );
    }
    public getHrefLangsForSupportedMarkets(topicId: number, brand: string) {
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        try {
            return ASTUTE.SUPPORTED_MARKETS.map(async market => {
                const topic = await this.getTopicFromCache(
                    market,
                    brand,
                    sitemapTag,
                    topicId
                );
                const baseUrl = siteMapService.getBaseUrl(market, brand);
                const howTosTopicUrl = siteMapService
                    .getPathFromRouterConfigs(
                        market,
                        brand,
                        'AstuteTopic',
                        topic?.topicAbsoluteSeo?.substring(1).split('/') || []
                    )
                    ?.concat(topic?.topicAbsoluteSeo?.endsWith('/') ? '/' : '');
                const url =
                    howTosTopicUrl &&
                    `${baseUrl.domain}${baseUrl.root}${howTosTopicUrl}`;
                const hrefLink =
                    topic &&
                    url &&
                    `<link rel="alternate" hreflang="${market}" href="${url}"/>`;
                return hrefLink;
            });
        } catch (error) {
            console.error(
                `getHrefLangsForSupportedMarkets - ${error} ${topicId}`
            );
            throw new Error(`getHrefLangsForSupportedMarkets - ${error}`);
        }
    }
    public async getTopicFromCache(
        market: string,
        brand: string,
        sitemapTag: string[],
        topicId: number
    ) {
        return (
            await this.getSitemapDataFromCache(market, sitemapTag, brand, true)
        ).find(topic => topic.topicId === topicId);
    }
    public async getHrefLangsForCategory(
        categoryId: number,
        brand: string
    ): Promise<string[]> {
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        try {
            return await Promise.all(
                ASTUTE.SUPPORTED_MARKETS.map(async market => {
                    const category = await this.getCategoryFromCache(
                        market,
                        brand,
                        sitemapTag,
                        categoryId
                    );
                    const baseUrl = siteMapService.getBaseUrl(market, brand);
                    const howTosCategoryUrl = siteMapService
                        .getPathFromRouterConfigs(
                            market,
                            brand,
                            'CategoryPageView',
                            category?.categoryAbsoluteSeo
                                ?.substring(1)
                                .split('/') || []
                        )
                        ?.concat(
                            category?.categoryAbsoluteSeo?.endsWith('/')
                                ? '/'
                                : ''
                        );
                    const url =
                        howTosCategoryUrl &&
                        `${baseUrl.domain}${baseUrl.root}${howTosCategoryUrl}`;
                    const hrefLink =
                        category.categoryName &&
                        url &&
                        `<link rel="alternate" hreflang="${market}" href="${url}"/>`;
                    return hrefLink || '';
                })
            );
        } catch (error) {
            console.error(`getHrefLangsForCategory - ${error} ${categoryId}`);
            throw new Error(`getHrefLangsForCategory - ${error}`);
        }
    }
    public async getCategoryFromCache(
        market: string,
        brand: string,
        sitemapTag: string[],
        categoryId: number
    ) {
        let category: Category = {
            categoryId: 0,
            categoryName: '',
            localizedCategoryName: '',
            categoryValues: [],
        };
        const sitemap = await this.getSitemapDataFromCache(
            market,
            sitemapTag,
            brand,
            true
        );
        sitemap.forEach(topic => {
            const categories = topic.categories?.find(
                cat => cat.categoryId === categoryId
            );
            if (categories) category = categories;
        });
        return category;
    }
    public async getHrefLangsForSubCategory(
        subCategoryId: number,
        brand: string
    ): Promise<string[]> {
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        try {
            return await Promise.all(
                ASTUTE.SUPPORTED_MARKETS.map(async market => {
                    const subCategory = await this.getSubCategoryFromCache(
                        market,
                        brand,
                        sitemapTag,
                        subCategoryId
                    );
                    const baseUrl = siteMapService.getBaseUrl(market, brand);
                    const howTosSubCategoryUrl = siteMapService
                        .getPathFromRouterConfigs(
                            market,
                            brand,
                            'SubCategoryPageView',
                            subCategory?.categoryValueAbsoluteSeo
                                ?.substring(1)
                                .split('/') || []
                        )
                        ?.concat(
                            subCategory?.categoryValueAbsoluteSeo?.endsWith('/')
                                ? '/'
                                : ''
                        );
                    const url =
                        howTosSubCategoryUrl &&
                        `${baseUrl.domain}${baseUrl.root}${howTosSubCategoryUrl}`;
                    const hrefLink =
                        subCategory.categoryValue &&
                        url &&
                        `<link rel="alternate" hreflang="${market}" href="${url}"/>`;
                    return hrefLink || '';
                })
            );
        } catch (error) {
            console.error(
                `getHrefLangsForSubCategory - ${error} ${subCategoryId}`
            );
            throw new Error(`getHrefLangsForSubCategory - ${error}`);
        }
    }
    public async getSubCategoryFromCache(
        market: string,
        brand: string,
        sitemapTag: string[],
        subCategoryId: number
    ) {
        let subCategory: CategoryValue = {
            categoryValueId: 0,
            categoryValue: '',
            localizedCategoryValue: '',
        };
        const sitemap = await this.getSitemapDataFromCache(
            market,
            sitemapTag,
            brand,
            true
        );
        sitemap.forEach(topic => {
            topic.categories?.forEach(cat => {
                const subCategories = cat.categoryValues.find(
                    subcat => subcat.categoryValueId === subCategoryId
                );
                if (subCategories) subCategory = subCategories;
            });
        });
        return subCategory;
    }
    public async getSearchResults(
        searchterm: string,
        languageRegionCode: string,
        domain: string,
        root: string,
        brand: string
    ) {
        const topicPage: TopicPage = {
            topicLabel: '',
            topicHeading: '',
            categoryName: '',
            subCategoryName: '',
            bodyWoHeading: '',
            metaDescription: '',
            hrefLangs: '',
            relatedTopics: '',
            canonical: '',
            topicId: 0,
            categoryId: 0,
            sessionId: '',
        };
        try {
            const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
            const siteMapData = await this.getSitemapDataFromCache(
                languageRegionCode,
                sitemapTag,
                brand,
                true
            );
            const sessionID = await this.getSessionIdForSearch(
                languageRegionCode,
                brand
            );
            const searchResults = await this.astuteSearch(
                searchterm,
                sessionID,
                brand,
                languageRegionCode
            );
            topicPage.topicId =
                searchResults.intent && parseInt(searchResults.intent);
            const topic = siteMapData.find(
                topic => topic.topicId === topicPage.topicId
            );
            topicPage.categoryId = this.validateAndGetCategoryIdForTopicPage(
                topic
            );

            if (topic) {
                const topicContentById = await this.getTopicContentByTopicId(
                    topic,
                    siteMapData,
                    languageRegionCode,
                    root,
                    sessionID,
                    brand,
                    true
                );
                topicPage.sessionId = sessionID;
                topicPage.topicHeading = topicContentById?.topicHeading;
                topicPage.bodyWoHeading = this.getBodyWoHeadingForTopicPage(
                    topicContentById
                );
                topicPage.topicLabel = topicContentById?.topicLabel;
                topicPage.categoryName = topicContentById?.categoryName;
                topicPage.subCategoryName = topicContentById?.subCategoryName;
            }
            const suggestedTopics =
                searchResults.suggestedTopics &&
                (await this.buildSuggestedTopics(
                    searchResults.suggestedTopics,
                    siteMapData,
                    root,
                    languageRegionCode,
                    brand
                ));
            if (
                !topicPage.bodyWoHeading ||
                topicPage.bodyWoHeading
                    .replace(SUPPORT_HUB_REGEX.FIND_ALL_TABS_NEW_LINE, '')
                    .replace(SUPPORT_HUB_REGEX.ALL_MARKUP_TAGS, '')
                    .replace(SUPPORT_HUB_REGEX.NON_BREAKING_SPACE, '')
                    .length === 0
            ) {
                topicPage.bodyWoHeading = this.sanitizeHtml(
                    searchResults.utterance
                );
            }
            const translatedRoute = siteMapService.getPathFromRouterConfigs(
                languageRegionCode,
                brand,
                'AstuteSearch',
                [searchterm]
            );

            const currentRoot = root.substring(0, root.length - 1);
            const link = `https://${domain}${currentRoot}${translatedRoute}`;
            topicPage.bodyWoHeading = topicPage.bodyWoHeading?.replace(
                SUPPORT_HUB_REGEX.HREF_HASH,
                `href="${link}#`
            );
            let metaDescription;
            try {
                metaDescription = await this.getTopicAttributes(
                    brand,
                    topicPage.topicId,
                    languageRegionCode
                );
            } catch (e) {
                console.error(
                    `Inside getSearchResults - getTopicAttributes - ${e} ${topicPage.topicId}`
                );
            }
            topicPage.metaDescription = this.validateForMetaDescription(
                metaDescription,
                topicPage.bodyWoHeading
            );

            if (suggestedTopics) {
                topicPage.relatedTopics = suggestedTopics.join(' ');
            }
            let canonicalRoute;
            if (topic?.topicAbsoluteSeo) {
                canonicalRoute = siteMapService
                    .getPathFromRouterConfigs(
                        languageRegionCode,
                        brand,
                        'AstuteTopic',
                        topic?.topicAbsoluteSeo?.substring(1).split('/') || []
                    )
                    ?.concat(topic?.topicAbsoluteSeo?.endsWith('/') ? '/' : '');
            }
            const baseUrl = siteMapService.getBaseUrl(
                languageRegionCode,
                brand
            );
            topicPage.canonical = topic?.topicAbsoluteSeo
                ? `${baseUrl.domain}${baseUrl.root}${canonicalRoute}`
                : `https://${domain}${currentRoot}/`;
        } catch (error) {
            console.error(`getSearchResults - ${error} ${searchterm}`);
            throw new Error(`getSearchResults - ${error} ${searchterm}`);
        }
        return topicPage;
    }

    private getBodyWoHeadingForTopicPage(topicContentById: TopicPage) {
        return topicContentById?.bodyWoHeading || '';
    }

    private validateAndGetCategoryIdForTopicPage(topic: Topic | undefined) {
        return topic && topic.categories && topic?.categories[0]?.categoryId;
    }

    public async buildSuggestedTopics(
        suggestedTopics: SuggestedTopic[],
        siteMapData: Topic[],
        root: string,
        languageRegionCode: string,
        brand: string
    ) {
        const markup: string[] = [];

        suggestedTopics.forEach(suggestedTopic => {
            const topic = siteMapData?.filter(
                topic => topic.topicId.toString() === suggestedTopic.intent
            );
            const currentRoot = root.substring(0, root.length - 1);
            const url =
                topic &&
                topic[0] &&
                `${currentRoot}${siteMapService
                    .getPathFromRouterConfigs(
                        languageRegionCode,
                        brand,
                        'AstuteTopic',
                        topic[0].topicAbsoluteSeo?.substring(1).split('/') || []
                    )
                    ?.concat(
                        topic[0].topicAbsoluteSeo?.endsWith('/') ? '/' : ''
                    )}`;
            const snippet = `<a href="${url}">${suggestedTopic.text}</a><br>`;
            url && markup.push(snippet);
        });
        return markup;
    }
    public async getAstuteArticlesFromCache(market: string, brand: string) {
        const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
        const categories = await this.getAllCategories(
            market,
            sitemapTag,
            brand,
            true
        );
        return categories.map(cat => {
            return {
                categoryName: cat.categoryName,
                categoryId: cat.categoryId,
                subCategories: cat.subCategories.map(subcat => {
                    return {
                        subCategoryName: subcat.subCategoryName,
                        subCategoryId: subcat.subCategoryId,
                        topics:
                            subcat.allTopics &&
                            subcat.allTopics.map(topic => {
                                return {
                                    topicLabel: topic.topicLabel,
                                    topicAbsoluteSeo: topic.topicAbsoluteSeo,
                                    topicId: topic.topicId,
                                    topicPreview: 'Coming soon...',
                                    oldTopicLabels: topic.oldTopicLabels,
                                };
                            }),
                    };
                }),
            };
        });
    }
    public async getAllCategories(
        languageRegionCode: string,
        prefixes: string[],
        brand: string,
        skipSitemapCheck?: boolean,
        skipCategory?: boolean
    ) {
        const categories: CategoryPage[] = [];
        const subCategories: SubCategoryPage[] = [];

        const brandAndLanguageRegionCode = {
            brand,
            languageRegionCode,
        };
        try {
            const siteMapData = await this.getSitemapDataFromCache(
                languageRegionCode,
                prefixes,
                brand,
                skipSitemapCheck
            );

            siteMapData.forEach(topic => {
                const topicWoCategories = {
                    topicId: topic.topicId,
                    topicName: this.getTopicName(topic),
                    topicLabel: topic.topicLabel,
                    topicAbsoluteSeo: this.getTopicAbsoluteSeo(topic),
                    topicLabelSeo: this.getTopicLabelSeo(topic),
                    oldTopicLabels: this.getOldTopicLabels(topic),
                };
                topic.categories?.forEach(categ => {
                    categ.categoryValues.forEach(categVal => {
                        const topicSplit = topic.topicAbsoluteSeo?.split('/');

                        const subCategory: SubCategoryPage = {
                            subCategorySeo: this.getSubCategorySeoFromCategoryValueSeo(
                                categVal
                            ),
                            subCategoryAbsoluteSeo:
                                siteMapService
                                    .getPathFromRouterConfigs(
                                        languageRegionCode,
                                        brand,
                                        'SubCategoryPageView',
                                        categVal.categoryValueAbsoluteSeo
                                            ?.substring(1)
                                            .split('/') || []
                                    )
                                    ?.concat(
                                        this.formatCategoryValueAbsoluteSeo(
                                            categVal
                                        )
                                    ) || '',
                            subCategoryId: categVal.categoryValueId,
                            subCategoryName: categVal.localizedCategoryValue,
                            allTopics: [],
                        };

                        const existingSubCat = subCategories.find(
                            subcat =>
                                subcat.subCategoryId ===
                                subCategory.subCategoryId
                        );

                        this.processSubCategories(
                            existingSubCat,
                            topicSplit,
                            subCategory,
                            topicWoCategories,
                            brandAndLanguageRegionCode,
                            topic,
                            subCategories
                        );

                        const categPage: CategoryPage = {
                            categoryId: categ.categoryId,
                            categoryName: categ.localizedCategoryName,
                            subCategories: [],
                            categorySeo: categ.localizedCategoryNameSeo,
                            categoryAbsoluteSeo:
                                siteMapService
                                    .getPathFromRouterConfigs(
                                        languageRegionCode,
                                        brand,
                                        'CategoryPageView',
                                        categ.categoryAbsoluteSeo
                                            ?.substring(1)
                                            .split('/') || []
                                    )
                                    ?.concat(
                                        this.formatCategoryAbsoluteSeo(categ)
                                    ) || '',
                        };
                        const existingCat = categories.find(
                            cat => cat.categoryId === categ.categoryId
                        );
                        const existingSubCatInCat = categories.find(cat => {
                            return cat.subCategories.find(subCat => {
                                return (
                                    subCat.subCategoryId ===
                                    subCategory.subCategoryId
                                );
                            });
                        });

                        this.populateCategoriesAndSubCategories(
                            existingCat,
                            categPage,
                            subCategory,
                            categories,
                            existingSubCatInCat
                        );
                    });
                });
            });
            categories.forEach(categ => {
                categ.subCategories = [...categ.subCategories].sort((a, b) =>
                    a.subCategoryName
                        ?.toLocaleUpperCase()
                        ?.localeCompare(b.subCategoryName?.toLocaleUpperCase())
                );
                categ.subCategories.forEach(
                    subcat =>
                        (subcat.allTopics = subcat.allTopics?.sort((a, b) =>
                            a.topicLabel
                                ?.toLocaleUpperCase()
                                ?.localeCompare(
                                    b.topicLabel?.toLocaleUpperCase()
                                )
                        ))
                );
            });
        } catch (error) {
            console.error(error);
            throw new Error(`getAllCategories: ${error}`);
        }
        const filteredCategories = this.getFilteredCategories(
            skipCategory,
            categories,
            brand
        );

        return filteredCategories.sort((a, b) =>
            a.categoryName
                ?.toLocaleUpperCase()
                .localeCompare(b.categoryName?.toLocaleUpperCase())
        );
    }

    private populateCategoriesAndSubCategories(
        existingCat: CategoryPage | undefined,
        categPage: CategoryPage,
        subCategory: SubCategoryPage,
        categories: CategoryPage[],
        existingSubCatInCat: CategoryPage | undefined
    ) {
        if (!existingCat) {
            categPage.subCategories.push(subCategory);
            categories.push(categPage);
        } else if (!existingSubCatInCat)
            existingCat.subCategories.push(subCategory);
    }

    private processSubCategories(
        existingSubCat: SubCategoryPage | undefined,
        topicSplit: string[] | undefined,
        subCategory: SubCategoryPage,
        topicWoCategories: {
            topicId: number;
            topicLabel: string;
            topicLabelSeo: string;
            oldTopicLabels: string[];
            topicName: string;
            topicAbsoluteSeo: string;
        },
        brandAndLanguageRegionCode: BrandAndLanguageRegionCode,
        topic: Topic,
        subCategories: SubCategoryPage[]
    ) {
        if (!existingSubCat) {
            this.populateSubCategoryTopics(
                topicSplit,
                subCategory,
                topicWoCategories,
                brandAndLanguageRegionCode.languageRegionCode,
                brandAndLanguageRegionCode.brand,
                topic,
                subCategories
            );
        } else if (
            topicSplit &&
            existingSubCat.subCategorySeo === topicSplit[2]
        ) {
            topicWoCategories.topicAbsoluteSeo =
                siteMapService
                    .getPathFromRouterConfigs(
                        brandAndLanguageRegionCode.languageRegionCode,
                        brandAndLanguageRegionCode.brand,
                        'AstuteTopic',
                        topic.topicAbsoluteSeo?.substring(1).split('/') || []
                    )
                    ?.concat(this.formatTopicAbsoluteSeo(topic)) || '';
            existingSubCat.allTopics &&
                existingSubCat.allTopics.push(topicWoCategories);
        }
    }

    private getFilteredCategories(
        skipCategory: undefined | boolean,
        categories: CategoryPage[],
        brand: string
    ) {
        const filteredCategories =
            skipCategory && categories !== null && categories !== undefined
                ? categories?.filter(
                      categ =>
                          !(BRAND_ATTRIBUTES as any)[
                              brand
                          ].excludeCatId.includes(categ.categoryId)
                  )
                : categories;
        return filteredCategories;
    }

    private formatCategoryAbsoluteSeo(categ: Category) {
        return categ.categoryAbsoluteSeo?.endsWith('/') ? '/' : '';
    }

    private formatTopicAbsoluteSeo(topic: Topic) {
        return topic.topicAbsoluteSeo?.endsWith('/') ? '/' : '';
    }

    private formatCategoryValueAbsoluteSeo(categVal: CategoryValue) {
        return categVal.categoryValueAbsoluteSeo?.endsWith('/') ? '/' : '';
    }

    private populateSubCategoryTopics(
        topicSplit: string[] | undefined,
        subCategory: SubCategoryPage,
        topicWoCategories: {
            topicId: number;
            topicLabel: string;
            topicLabelSeo: string;
            oldTopicLabels: string[];
            topicName: string;
            topicAbsoluteSeo: string;
        },
        languageRegionCode: string,
        brand: string,
        topic: Topic,
        subCategories: SubCategoryPage[]
    ) {
        const topicAbsoluteSeo = topic.topicAbsoluteSeo?.endsWith('/')
            ? '/'
            : '';
        if (topicSplit && subCategory.subCategorySeo === topicSplit[2]) {
            topicWoCategories.topicAbsoluteSeo =
                siteMapService
                    .getPathFromRouterConfigs(
                        languageRegionCode,
                        brand,
                        'AstuteTopic',
                        topic.topicAbsoluteSeo?.substring(1).split('/') || []
                    )
                    ?.concat(topicAbsoluteSeo) || '';
            subCategory.allTopics &&
                subCategory.allTopics.push(topicWoCategories);
        }
        subCategories.push(subCategory);
    }

    private getSubCategorySeoFromCategoryValueSeo(categVal: CategoryValue) {
        return categVal.localizedCategoryValueSeo || '';
    }

    public async getAllFilteredCatAndSubcat(
        languageRegionCode: string,
        prefixes: string[],
        brand: string,
        category: string,
        subcategory?: string,
        skipSitemapCheck?: boolean,
        skipCategory?: boolean
    ): Promise<CatSubcatFilteredResponse> {
        try {
            const categories = await this.getAllCategories(
                languageRegionCode,
                prefixes,
                brand,
                skipSitemapCheck,
                skipCategory
            );
            const filteredCategories = categories?.find(
                categ => categ.categorySeo === category
            );
            const filteredSubCategories = filteredCategories?.subCategories?.find(
                subCateg => subCateg.subCategorySeo === subcategory
            );
            return {
                categories,
                filteredCategories,
                filteredSubCategories,
            };
        } catch (error) {
            console.error(error);
            throw new Error(`getAllFilteredCatAndSubcat: ${error}`);
        }
    }

    public async getAstuteFromCacheForRegion(
        market: string,
        brand: string
    ): Promise<AstuteCacheResponse[]> {
        if (serverSideService.isServerSide()) {
            return Promise.resolve(
                await this.getAstuteCacheData(market, brand)
            ) as Promise<AstuteCacheResponse[]>;
        } else {
            const params = {
                market: market,
                brand: brand,
            };

            const appConfig = ServiceHandler.AppConfigurationService.getAppConfiguration();

            const currentRoot =
                appConfig?.root && appConfig.root !== `/${brand}/${market}/`
                    ? appConfig?.root.substring(0, appConfig?.root.length - 1)
                    : '';

            const basePath = process.env.REACT_APP_REDIS_CACHE_URL
                ? process.env.REACT_APP_REDIS_CACHE_URL
                : currentRoot;

            return await HttpService.get<AstuteCacheResponse[]>(
                `${basePath}/redis/getAstuteFromCache`,
                true,
                { httpsAgent, params, proxy: false }
            )
                .then(result => {
                    return result.data;
                })
                .catch(e => {
                    console.error(e.message);
                    return e.message;
                });
        }
    }
    public async astuteCallByTopicId(
        topicId: number,
        languageRegionCode: string,
        brand: string,
        sessionID?: string
    ) {
        const supportedMarkets = (BRAND_ATTRIBUTES as any)[brand]
            .SUPPORTED_MARKETS_RTE_SEARCH;
        return supportedMarkets.includes(languageRegionCode)
            ? await this.rteService.astuteCallByTopicId(topicId, sessionID)
            : await this.emplifiService.astuteCallByTopicId(
                  topicId,
                  languageRegionCode,
                  brand
              );
    }
    public async getSiteMap(
        pageNumber: number,
        languageRegionCode: string,
        brand: string
    ) {
        const supportedMarkets = (BRAND_ATTRIBUTES as any)[brand]
            .SUPPORTED_MARKETS_RTE_DISCOVERABILITY;
        return supportedMarkets.includes(languageRegionCode)
            ? await this.rteService.getSiteMap(
                  pageNumber,
                  languageRegionCode,
                  brand
              )
            : await this.emplifiService.getSiteMap(
                  pageNumber,
                  languageRegionCode,
                  brand
              );
    }

    public async getSuggestedResults(
        languageRegionCode: string,
        currentSearchTerm: string,
        sessionID: string,
        brand: string
    ) {
        let returnSessionId = sessionID;

        if (!returnSessionId) {
            returnSessionId = await this.rteService.getSessionIdForSearch(
                languageRegionCode,
                brand
            );
        }
        return {
            suggestions: await this.rteService
                .getSuggestedResultsElastic(currentSearchTerm, returnSessionId)
                .catch(async () => {
                    returnSessionId = await this.rteService.getSessionIdForSearch(
                        languageRegionCode,
                        brand
                    );
                    return await this.rteService.getSuggestedResultsElastic(
                        currentSearchTerm,
                        returnSessionId
                    );
                }),
            sessionID: returnSessionId,
        };
    }
    public async getSessionIdForSearch(
        languageRegionCode: string,
        brand: string
    ): Promise<string> {
        languageRegionCode =
            languageRegionCode === 'fr-lu' ? 'fr-be' : languageRegionCode;
        const supportedMarkets = (BRAND_ATTRIBUTES as any)[brand]
            .SUPPORTED_MARKETS_RTE_SEARCH;
        return supportedMarkets.includes(languageRegionCode)
            ? await this.rteService.getSessionIdForSearch(
                  languageRegionCode,
                  brand
              )
            : await this.emplifiService.getSessionIdForSearch(
                  languageRegionCode,
                  brand
              );
    }
    public async astuteSearch(
        currentSearchTerm: string,
        sessionID: string,
        brand: string,
        languageRegionCode: string,
        questionID?: number
    ) {
        if (sessionID) {
            const supportedMarkets = (BRAND_ATTRIBUTES as any)[brand]
                .SUPPORTED_MARKETS_RTE_SEARCH;
            return supportedMarkets.includes(languageRegionCode)
                ? await this.rteService.astuteSearch(
                      currentSearchTerm,
                      sessionID
                  )
                : await this.emplifiService.astuteSearch(
                      currentSearchTerm,
                      sessionID,
                      brand,
                      questionID
                  );
        }
    }

    public async sendEmplifiFeedback(
        sessionID: string,
        topicId: number,
        rating: number,
        comments: string,
        languageRegionCode: string,
        brand: string
    ) {
        const supportedMarkets = (BRAND_ATTRIBUTES as any)[brand]
            .SUPPORTED_MARKETS_RTE_SEARCH;
        return supportedMarkets.includes(languageRegionCode)
            ? await this.rteService.sendEmplifiFeedback(
                  sessionID,
                  topicId,
                  rating,
                  comments
              )
            : await this.emplifiService.sendEmplifiFeedback(
                  sessionID,
                  topicId,
                  rating,
                  comments
              );
    }

    public async getTopicAttributes(
        brand: string,
        topicId: number,
        languageRegionCode: string
    ) {
        const supportedMarkets = (BRAND_ATTRIBUTES as any)[brand]
            .SUPPORTED_MARKETS_RTE_DISCOVERABILITY;
        return supportedMarkets.includes(languageRegionCode)
            ? await this.rteService.getTopicAttributes(
                  brand,
                  topicId,
                  languageRegionCode
              )
            : await this.emplifiService.getTopicAttributes(
                  brand,
                  topicId,
                  languageRegionCode
              );
    }

    public async getTopicIdFromSeoTitle(
        urlTitle: string,
        market: string,
        brand: string
    ): Promise<number | null> {
        const cacheResponse = await this.getAstuteCacheData(market, brand);

        const siteArticles: SiteArticleSingleResponse[] = cacheResponse.reduce(
            (
                arr: SiteArticleSingleResponse[],
                item: {
                    [x: string]: {
                        topicId: number;
                        topicName: string;
                        topicLabelSeo: string;
                        categories: any[];
                    }[];
                }
            ) => {
                Object.keys(item).forEach(i => {
                    item[i].forEach(topic => {
                        arr.push({
                            topicId: topic.topicId,
                            topicName: topic.topicName,
                            topicSeo: topic.topicLabelSeo,
                        });
                    });
                });
                return arr;
            },
            []
        );

        const discoveredTopicId = siteArticles.find(
            t => t.topicSeo === urlTitle
        )?.topicId;
        return discoveredTopicId ?? null;
    }

    public async getCategoryNameFromTopicId(
        requestTopicId: number,
        market: string,
        brand: string
    ): Promise<FlatArticle | null> {
        const allArticles: any[] = await this.getAstuteArticlesFromCache(
            market,
            brand
        );

        const allArticlesFlat: FlatArticle[] = allArticles.reduce(
            (
                arr: FlatArticle[],
                item: {
                    categoryName: string;
                    subCategories: {
                        subCategoryName: string;
                        topics: {
                            topicLabel: string;
                            topicAbsoluteSeo: string;
                            topicId: number;
                            topicPreview?: string | null;
                        }[];
                    }[];
                }
            ) => {
                item.subCategories.forEach(subCategory => {
                    if (subCategory.topics && subCategory.topics.length > 0)
                        subCategory.topics.forEach(topic => {
                            arr.push({
                                topicId: topic.topicId,
                                category: item.categoryName,
                                subCategory: subCategory.subCategoryName,
                            });
                        });
                });
                return arr;
            },
            []
        );
        const result = allArticlesFlat.find(a => a.topicId === requestTopicId);

        return result || null;
    }
    public async getTopicContentById(
        topicId: number,
        languageRegionCode: string,
        root: string,
        brand: string
    ): Promise<PlaceholderResponse | undefined> {
        const foundSessionId = await this.getSessionIdForSearch(
            languageRegionCode,
            brand
        );
        try {
            const sitemapTag = (BRAND_ATTRIBUTES as any)[brand].sitemap;
            const siteMapData = await this.getSitemapDataFromCache(
                languageRegionCode,
                sitemapTag,
                brand,
                true
            );

            if (topicId) {
                const topicData = await this.astuteCallByTopicId(
                    topicId,
                    languageRegionCode,
                    brand,
                    foundSessionId
                );
                if (topicData) {
                    topicData.response = await this.buildAdditionalTopicUrls(
                        topicData,
                        siteMapData,
                        root,
                        languageRegionCode,
                        brand
                    );
                }
                return topicData;
            }
        } catch (error) {
            console.error(
                `------------ getTopicContentById - error ${error} ------------`
            );
            console.error({
                foundSessionId,
                brand,
                languageRegionCode,
                topicId,
            });
            console.error(`------------`);
        }
    }
}
