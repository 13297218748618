import React, { useCallback, useState } from 'react';
import './vehicle-search-form.scss';
import { FMButton, FMInput, FMModal } from '@own/fds-react';
const InfoIconL = './icons/info-l.svg';
import vinNumberWindowVehicle from '../../../../../assets/osb-v3/icons-vin-number-window-vehicle.svg';
import vinNumberDocumentation from '../../../../../assets/osb-v3/icons-vin-number-documentation.svg';
import vinNumberWindowVehicleFront from '../../../../../assets/osb-v3/icons-vin-number-window-vehicle-front.svg';
import regPlateNumber from '../../../../../assets/osb-v3/icons-reg-plate-number.svg';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import parse from 'html-react-parser';
import {
    getInitialVehicleDetails,
    useHttp,
    useVehicleStep,
} from '../../../../../hooks/owners-osb';
import { VehicleData } from '../../../../../models/osb-model/osb-vehicle-step';
import ServiceHandler from '../../../../../services/service-handler';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';
import { formatRegVinNumber } from '../../osb-v3-utils';
import { PC_DEFAULT_DEALER_CODE } from '../../../../../constants';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import VehicleImageService from '../../../vehicle-portal/services/vehicle-image-service/vehicle-image-service';
import { PageType } from '../../../view-template/page-type';
import { VehicleInfo } from '../../../../../models/osb-model/osb-vehicle-lookup';
import FordAccountVehicle from '../ford-account-vehicle/ford-account-vehicle';
import VehicleInfoSkeleton from '../vehicle-info-skeleton/vehicle-info-skeleton';
//import { OSB_AEM_PROPERTY_KEYS } from '../../../owners-osb/osb-constant';

const VehicleSearchForm = () => {
    const vehicleStepContent =
        useOSBContentFragment('common/osb-v3', 'vehicle-step') || {};
    let { brand } = new AppConfigurationService();
    brand = brand === 'demo' ? 'ford' : brand;
    console.log(brand);
    const page = 'OSBVehicleStep' as PageType;
    const { osbVehicleStep, setOSBVehicleStepPayload } = useVehicleStep();
    const { httpState, dispatch } = useHttp();
    const [vinRegErrorMsg, setVinRegErrorMsg] = useState<string>();
    const [mileageError, setMileageError] = useState<string>();
    const [isVinRegModalVisible, setIsVinRegModalVisible] = useState(false);
    const [serviceError, setServiceError] = useState('');
    const [isError, setIsError] = useState(false);
    const bookable = useAllOSBState();
    const [loading, setLoading] = useState<boolean>(true);
    console.log(isError);
    console.log(serviceError);
    console.log(httpState);
    const [isVehicleInfoPopupVisible, setIsVehicleInfoPopupVisible] = useState(
        false
    );

    const [vehicleImageUrl, setVehicleImageUrl] = useState<string>();
    const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>(
        getInitialVehicleDetails()
    );
    const isThisVIN = (vinRegNo: string) => {
        if (vinRegNo.length >= 10) {
            return true;
        }
        return false;
    };
    const isValidVin = (regVin: string, vinRegDataPattern: string) => {
        if (!regVin) {
            return false;
        }
        const vinRegistrationRegExp =
            (vinRegDataPattern && vinRegDataPattern.trim()) ||
            '^([a-zA-Z0-9]{17}|[a-zA-Z0-9][a-zA-Z0-9 -]{1,9}[a-zA-Z0-9])$';
        const vinRegex = new RegExp(vinRegistrationRegExp);
        return vinRegex.test(regVin);
    };

    const validateVinRegNo = (vinRegNo: string) => {
        if (vinRegNo === '') {
            setVinRegErrorMsg(
                vehicleStepContent?.vinregistrationValidationMessage?.toString()
            );
            return false;
        } else {
            if (isThisVIN(vinRegNo)) {
                //VIN
                const isVinValid = isValidVin(
                    vinRegNo,
                    '^([a-zA-Z0-9]{17}|[a-zA-Z0-9][a-zA-Z0-9 -]{1,7}[a-zA-Z0-9])$'
                );
                if (!isVinValid) {
                    setVinRegErrorMsg(
                        vehicleStepContent?.invalidVinErrorMessage?.toString()
                    );
                    return false;
                } else {
                    setVinRegErrorMsg('');
                    return true;
                }
            } else {
                // REG NO
                const isRegNoValid = isValidVin(
                    vinRegNo,
                    '^([a-zA-Z0-9]{17}|[a-zA-Z0-9][a-zA-Z0-9 -]{1,7}[a-zA-Z0-9])$'
                );
                if (!isRegNoValid) {
                    setVinRegErrorMsg(
                        vehicleStepContent?.invalidRegistrationErrorMessage?.toString()
                    );
                    return false;
                } else {
                    setVinRegErrorMsg('');
                    return true;
                }
            }
        }
    };

    const onVinRegChange = (e: any) => {
        console.log(e.target.value);
        validateVinRegNo(e.target.value);
        setOSBVehicleStepPayload({ vinRegNo: e.target.value });
    };
    const isValidMileage = (mileage: string) => {
        const correctMileage = mileage.replace(/[^0-9 .,']+$/, '');
        const sanitisedMileage = correctMileage
            ? correctMileage.replace(/[ .,']/g, '')
            : '';
        const finalMileage = sanitisedMileage.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ''
        );
        setOSBVehicleStepPayload({ vinMileage: finalMileage });
        if (finalMileage === '') {
            setMileageError(
                vehicleStepContent?.mileageValidationMessage?.toString()
            );
            return false;
        }
        return true;
    };

    const onMileageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const isMileageValid = isValidMileage(e.target.value);
        if (!isMileageValid) {
            setMileageError(
                vehicleStepContent?.mileageValidationMessage?.toString()
            );
        } else {
            setMileageError('');
        }
    };
    const onCloseVinRegInfo = () => {
        setIsVinRegModalVisible(false);
    };

    const renderHowToFindYourVin = () => {
        setIsVinRegModalVisible(true);
    };

    const togglePopup = useCallback(() => {
        setIsVehicleInfoPopupVisible(prev => !prev);
    }, []);

    //vehicle image service for fetching vehicle image
    const fetchVehicleImage = (vehicleAttributes: VehicleAttributes) => {
        new VehicleImageService()
            .getVehicleImage(vehicleAttributes, page)
            .then(vehicleImageUrl => {
                setVehicleImageUrl(
                    vehicleImageUrl !== null ? vehicleImageUrl : '#'
                );
            })
            .catch();
    };

    const getVehicleData = async () => {
        setServiceError('');
        setIsVehicleInfoPopupVisible(true);
        if (
            osbVehicleStep.vinRegNo !== '' &&
            osbVehicleStep.vinMileage !== ''
        ) {
            dispatch({ type: 'REQUEST' });
            let vehicleData: VehicleData;
            if (osbVehicleStep.vinRegNo.length <= 10) {
                vehicleData = {
                    registrationNumber: formatRegVinNumber(
                        osbVehicleStep.vinRegNo,
                        osbVehicleStep.allowSpecialCharacters
                    ),
                    mileage: osbVehicleStep.vinMileage,
                };
            } else {
                vehicleData = {
                    vin: formatRegVinNumber(
                        osbVehicleStep.vinRegNo,
                        osbVehicleStep.allowSpecialCharacters
                    ),
                    mileage: osbVehicleStep.vinMileage,
                };
            }
            await ServiceHandler.OsbVehicleLookUp.postVehicleInfo({
                vehicleData: vehicleData,
                prognosticsData: osbVehicleStep.vehiclePrognosticData,
                dealerCode: PC_DEFAULT_DEALER_CODE,
                bookable: bookable,
            })
                .then(result => {
                    fetchVehicleImage({
                        model: result.value.modelName,
                        make: brand,
                        vin: result.value.vin,
                        year: parseInt(result.value.buildDate.substring(0, 4)),
                    });
                    setVehicleInfo(result.value);
                    setLoading(false);
                    dispatch({ type: 'RESPONSE' });
                    setIsVehicleInfoPopupVisible(true);
                })
                .catch((error: any) => {
                    setIsError(true);
                    setLoading(false);
                    setIsVehicleInfoPopupVisible(false);
                    dispatch({
                        type: 'ERROR',
                        error: error.errorMessage,
                    });

                    if (error) {
                        console.log('error', error);
                        const errorMessage = error.errorCode;
                        if (errorMessage === 'OSB_VIN_EXISTS') {
                            setServiceError('ACTIVE_BOOKING');
                        } else if (
                            errorMessage === 'OSB_NO_DATAFOUND' ||
                            errorMessage === 'OSB_VEHICLE_DETAILS_NOT_FOUND' ||
                            errorMessage === 'VEHICLE_NOT_FOUND'
                        ) {
                            if (osbVehicleStep.vinRegNo.length <= 10) {
                                setServiceError('REG_NOT_FOUND');
                            } else {
                                setServiceError('VIN_NOT_FOUND');
                            }
                        } else {
                            setServiceError('SERVICE_ERROR');
                        }
                    }
                });
        } else {
            if (!osbVehicleStep.vinRegNo?.trim()) {
                validateVinRegNo('');
            }
            if (!osbVehicleStep.vinMileage?.trim()) {
                setMileageError(
                    vehicleStepContent?.mileageValidationMessage?.toString()
                );
            }
        }
    };

    function renderErrorMessages() {
        return (
            <>
                {serviceError && serviceError === 'ACTIVE_BOOKING' && (
                    <div className="error-message">
                        {vehicleStepContent?.activeBookingContent}
                    </div>
                )}
                {serviceError &&
                    ((serviceError === 'REG_NOT_FOUND' && (
                        <div className="error-message">
                            {vehicleStepContent?.regVehicleNotFoundMessage}
                        </div>
                    )) ||
                        (serviceError === 'VIN_NOT_FOUND' && (
                            <div className="error-message">
                                {vehicleStepContent?.vinVehicleNotFoundMessage}
                            </div>
                        )))}
                {serviceError && serviceError === 'SERVICE_ERROR' && (
                    <div className="error-message">
                        {vehicleStepContent?.vehicleServiceErrorMessage}
                    </div>
                )}
                {serviceError && serviceError === 'INTERNAL_ERROR_MESSAGE' && (
                    <div className="error-message">
                        {vehicleStepContent?.internalErrorMessage}
                    </div>
                )}
            </>
        );
    }

    const VehicleInfoPopUp = () => (
        <FMModal
            data-testid="modal"
            name={'osb-v3-vehicle-detail-info'}
            isVisible={isVehicleInfoPopupVisible}
            onClose={togglePopup}
        >
            <>
                {loading ? (
                    <div className="osb-v3-vehicle-info-skeleton-wrapper">
                        <VehicleInfoSkeleton />
                    </div>
                ) : (
                    <FordAccountVehicle
                        vehicleInfo={vehicleInfo}
                        vehicleImageUrl={vehicleImageUrl || ''}
                    />
                )}
            </>
        </FMModal>
    );

    return (
        <>
            <div
                className="osb-v3-vehicle-search-container"
                data-testId="vehicle-search-section"
            >
                {serviceError !== '' && renderErrorMessages()}
                <div
                    className="vehicle-search-section-title"
                    data-testid="vehicle-search-title"
                >
                    {vehicleStepContent?.vehicleSectionTitle}
                </div>
                <div
                    className="vehicle-search-section-desc"
                    data-testid="vehicle-search-deac"
                >
                    {parse(
                        (
                            vehicleStepContent?.vehicleSectionDescLabel || ''
                        ).toString()
                    )}
                </div>

                <div className="vehicle-search-input-section">
                    <div className="vehicle-vin-reg-input-section">
                        <div className="vin-input-label">
                            <div className="osb-v3-reg-num-or-vin-label">
                                {vehicleStepContent?.vinregistrationLabel}
                            </div>
                            <div
                                data-testid="VinAndRegInfoPopup"
                                className="vin-reg-info-icon"
                                onClick={() => renderHowToFindYourVin()}
                                tabIndex={0}
                                role="link"
                            >
                                <img src={InfoIconL} alt="Info" />
                            </div>
                        </div>

                        <div className="osb-v3-reg-vin-input-wrapper">
                            <FMInput
                                dataTestId="vin-reg-input"
                                type="text"
                                onChange={e => onVinRegChange(e)}
                                value={osbVehicleStep.vinRegNo}
                                state={vinRegErrorMsg ? 'error' : 'standard'}
                                label="VIN/Registration Number"
                            />
                        </div>
                        {vinRegErrorMsg ? (
                            <div className="osb-error-message">
                                {vinRegErrorMsg}
                            </div>
                        ) : (
                            <div className="vin-reg-input-placeholder">
                                {vehicleStepContent?.vinRegistrationPlaceholder}
                            </div>
                        )}
                    </div>
                    <div className="vehicle-mileage-input-section">
                        <div className="osb-v3-vehicle-mileage-label">
                            {vehicleStepContent?.mileageLabel}
                        </div>

                        <div className="osb-v3-vehicle-mileage-wrapper">
                            <FMInput
                                dataTestId="mileage-input"
                                type="text"
                                onChange={e => onMileageChange(e)}
                                value={osbVehicleStep.vinMileage}
                                state={mileageError ? 'error' : 'standard'}
                                label="Mileage"
                            />
                        </div>
                        {mileageError ? (
                            <div className="osb-error-message">
                                {mileageError}
                            </div>
                        ) : (
                            <div className="mileage-input-placeholder">
                                {vehicleStepContent?.mileagePlaceholder}
                            </div>
                        )}
                    </div>
                </div>
                <div className="cta-control-section">
                    <div className="continue-btn">
                        <FMButton
                            data-testid="continue-btn"
                            href=""
                            label={vehicleStepContent?.continueButtonLabel}
                            type="primary"
                            theme="light"
                            onClick={() => getVehicleData()}
                        />
                    </div>
                    <div className="do-not-know-details-btn">
                        <FMButton
                            href=""
                            label={vehicleStepContent?.iDontKnowDetailsLabel}
                            type="secondary"
                            theme="light"
                        />
                    </div>
                </div>
            </div>
            <FMModal
                name="osb-v3-vin-reg-modal-popup"
                onClose={onCloseVinRegInfo}
                isVisible={isVinRegModalVisible}
            >
                <div className="vin-title-container">
                    <span className="vin-title">
                        {vehicleStepContent?.modalFindYourVinTitle?.toString()}
                    </span>
                    <span className="vin-subtitle">
                        {vehicleStepContent?.findYourVinPlaceholder?.toString()}
                    </span>
                </div>
                <div className="vin-content" data-testId="vin-content">
                    <div className="find-my-vin-info-body-content">
                        <div
                            className="vin-subtitle"
                            data-testId="vin-subtitle"
                        >
                            {vehicleStepContent?.modalFindYourVinSubtitle}
                        </div>
                        <div className="vin-details" data-testId="vin-details">
                            <ul className="vin-info-list">
                                <li>
                                    <img
                                        alt="vinNumberWindowVehicle"
                                        className=""
                                        src={vinNumberWindowVehicle}
                                    />
                                    {parse(
                                        (
                                            vehicleStepContent?.modalDriversSideDoorframeDescription ||
                                            ''
                                        ).toString()
                                    )}
                                </li>
                                <li>
                                    <img
                                        alt="vinNumberDocumentation"
                                        className=""
                                        src={vinNumberDocumentation}
                                    />
                                    {parse(
                                        (
                                            vehicleStepContent?.modalYourVehicleDocumentationDescription ||
                                            ''
                                        ).toString()
                                    )}
                                </li>
                            </ul>
                            <ul className="vin-info-list">
                                <li>
                                    <img
                                        alt="vinNumberWindowVehicleFront"
                                        className=""
                                        src={vinNumberWindowVehicleFront}
                                    />
                                    {parse(
                                        (
                                            vehicleStepContent?.modalVisibleThroughWindshieldDescription ||
                                            ''
                                        ).toString()
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <>
                    <p className="modal-line" />
                    <div
                        className="reg-info-container"
                        data-testId="reg-info-container"
                    >
                        <div className="reg-title-container">
                            <span className="reg-info-title">
                                {vehicleStepContent?.modalFindYourRegistrationNumberLabel?.toString()}
                            </span>
                            <span className="reg-info-subtitle">
                                {vehicleStepContent?.findYourRegistrationPlaceholder?.toString()}
                            </span>
                        </div>

                        <div className="reg-content" data-testId="reg-content">
                            <img
                                alt="regPlateNumber"
                                className=""
                                src={regPlateNumber}
                            />
                            {parse(
                                (
                                    vehicleStepContent?.modalRegistrationNumberDescription ||
                                    ''
                                ).toString()
                            )}
                        </div>
                    </div>
                </>
            </FMModal>

            {isVehicleInfoPopupVisible && <VehicleInfoPopUp />}
        </>
    );
};

export default VehicleSearchForm;
