import './vehicle-step.scss';
import { useEffect } from 'react';
import { useHttp } from '../../../../hooks/owners-osb';
import { useOSBContentFragment } from '../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import FordAccountInfo from './ford-account-info/ford-account-info';
import VehicleSearchForm from './vehicle-search-form/vehicle-search-form';
import VehicleList from './vehicle-list/vehicle-list';

export const OsbLandingPage = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isAuth = searchParams.get('isAuth') === 'true';
    const { dispatch } = useHttp();
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'vehicle-step') || {};

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
    }, []);

    return (
        <div
            className="osb-v3-vehicle-step-component"
            data-testid="vehicle-step-component-section"
        >
            {!isAuth ? (
                <>
                    <div className="osb-v3-title" data-testid="osb-v3-title">
                        {osbContentData?.onlineBookingServiceTitleLabel}
                    </div>
                    <div className="osb-v3-vehicle-step-component-section">
                        <FordAccountInfo />

                        <div className="separator" data-testid="separator">
                            <span>{osbContentData?.orLabel}</span>
                        </div>
                        <VehicleSearchForm />
                    </div>
                </>
            ) : (
                <VehicleList />
            )}
        </div>
    );
};

export default OsbLandingPage;
