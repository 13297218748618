import ProfileService from '../profile-service/profile-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import AuthenticationService from '../authentication-service/authentication-service';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import { DataLayerService } from '../data-layer-service/data-layer-service';
import { LogService } from '../log-service/log-service';
import CheckExperienceType, {
    DeviceType,
} from '../check-device-type/check-experience-type';

export interface ShortcodeProviders {
    ymm?: VehicleAttributes | Promise<VehicleAttributes>;
    gdprModalButtonPressed?: GdprModalButtonType | Promise<GdprModalButtonType>;
    tileOnClickCtaInfo?: TileOnClickCtaInfo | Promise<TileOnClickCtaInfo>;
    categoryOnClickCtaInfo?:
        | CategoryOnClickCtaInfo
        | Promise<CategoryOnClickCtaInfo>;
    vehicleChecklistOnClickCtaInfo?:
        | VehicleChecklistOnClickCtaInfo
        | Promise<VehicleChecklistOnClickCtaInfo>;
    referredUrlPageName?: string;
    discoverabilityPageLoad?:
        | DiscoverabilityPageLoad
        | Promise<DiscoverabilityPageLoad>;
    osbPayload?: OSBPayload;
    pcPageLoad?: PCPageLoad;
    dyfComponentName?: string;
    question?: string;
    fieldName?: string;
    errorDescription?: string;
    categoryId?: string | Promise<string>;
    subcategoryId?: string | Promise<string>;
    searchNonSearch?: string;
    cxVehicle?: CxVehicle;
    couponsCategoryName?: string;
    couponsId?: string;
    rebatesStepName?: string;
    videoName?: string;
    selectVehicle?: string;
    updateVehicle?: string;
    ctaType?: string;
    guideName?: string;
    recordType?: string;
    maintenanceScheduleCtaContext?: string;
    ownerManuals?: string;
    ownerManualsDetails?: string;
    dylCtaName?: string;
    ownerManualsSitemap?: string;
    recalls?: string;
    selfHelpFaq?: string;
    recallsDetails?: string;
    categoryName?: string;
    notificationCategory?: string;
    notificationCtaName?: string;
    smashHomeClick?: SmashHomeClick;
    videoPercentage?: string;
    segmentViewed?: string;
    fileStatus?: string;
    topics?: string[];
    categoryDisplayName?: string;
    featureName?: string;
    sendToDealerTextName?: string;
    footerChecklist?: string;
    detailsCheckboxState?: string;
    namePlateValue?: string;
    featuresList?: string;
    system?: string;
    towingCalculatorCtaContext?: string;
    trailerChecklistConventionalCta?: string;
    trailerChecklistFifthWheelCta?: string;
    trailerChecklistFifthWheelReset?: string;
    trailerChecklistConventionalReset?: string;
    trailerChecklistGooseneckCta?: string;
    trailerChecklistGooseneckReset?: string;
    trailerChecklistCtaContext?: string;
    jumpToCategoryName?: string;
    pageNameAlias?: string;
    tsbVinSelectCTA?: string;
    tsbCtaClick?: string;
    selectDealerCTA?: string;
    chooseMethodCTAContext?: string;
    redeemSubmitCTA?: string;
    accordionClick?: AccordionClick | Promise<AccordionClick>;
    cxShareSocialIconName?: string;
    feature?: string;
    category?: string;
    nameplate?: string;
    subcategory?: string;
    qsgFileName?: string;
    tabTitle?: string;
    actionItemType?: string;
}

export type TileOnClickCtaInfo = {
    tileName?: string;
    ctaName?: string;
    referredTo?: string;
    analyticsName?: string;
};
export type CategoryOnClickCtaInfo = {
    tileName?: string;
    ctaName?: string;
    ctaUrl?: string;
    referredTo?: string;
    categoryType?: string;
    categoryPath?: string;
    panelTitle?: string;
};
export type AccordionClick = {
    category?: string;
    subcategory?: string;
    accordionName?: string;
};
export type VehicleChecklistOnClickCtaInfo = {
    cardType?: string;
    referredTo?: string;
};
export type DiscoverabilityPageLoad = {
    article?: string;
    category?: string;
    subcategory?: string;
    searchKeyword?: string;
};
export type SelfHelpFaq = {
    title?: string;
    question?: string;
};

export type CxVehicle = {
    cxVinOrYmm?: string;
    year?: string;
    model?: string;
    categoryId?: string;
    subCategoryId?: string;
};

export type OSBPayload = {
    dealerID?: string;
    userLocationSearchTerm?: string;
    searchDepth?: string;
    pageNameNoVehicle?: string;
    serviceType?: string;
    bookingValue?: string;
    bookingProductString?: string;
    bookingVoucherCode?: string;
    mileage?: string;
    modelYear?: string;
    toolDescriptor?: string;
    deepLinkType?: string;
    pTemplate?: string;
    cardType?: string;
    panelType?: string;
    reviewAmendField?: string;
    searchLocationType?: string;
    searchType?: string;
    errorDescription?: string;
    applyRemoveVoucher?: string;
    accessCodeStatus?: string;
    bookingID?: string;
    contentSubType?: string;
    selectedDealerFilters?: string;
    videoName?: string;
    videoPercentage?: string;
    segmentViewed?: string;
    fileStatus?: string;
    vinMileage?: string;
    serviceName?: string;
    nameplateData?: string;
    ctaName?: string;
};

export type PCPageLoad = {
    pcCta?: string;
    selectedInputField?: string;
};

export type SmashHomeClick = {
    cardName?: string;
    smashHomeSearchContentCta?: string;
    bbtCategoryName?: string;
    infoGridServiceName?: string;
    articleName?: string;
    tileName?: string;
    ctaName?: string;
};

interface ShortcodeParts {
    raw: string;
    name: string;
    parameters: ShortcodeParameter[];
}

interface ShortcodeParameter {
    name: string;
    value: string;
}

export type GdprModalButtonType = 'Accept' | 'Decline';

export class ShortcodeService {
    public constructor(private shortcodeProviders?: ShortcodeProviders) {}

    public async processShortcodes(value: string): Promise<string> {
        const shortcodes: ShortcodeParts[] = this.parseForShortcodes(value);
        return this.injectShortcodeValues(value, shortcodes);
    }

    private parseForShortcodes(value: string): ShortcodeParts[] {
        const shortcodes: ShortcodeParts[] = [];
        try {
            const rawShortcodes = this.getShortcodeMatches(value);
            if (rawShortcodes) {
                rawShortcodes.forEach((rawShortcode: string) => {
                    const shortcodeName = rawShortcode
                        .split(' ')[0]
                        .substr(1)
                        .replace(']', '');
                    const parameterMatches = this.getParameterMatches(
                        rawShortcode
                    );
                    let parameters: ShortcodeParameter[] = [];
                    parameters = parameterMatches.map(
                        (parameterUnparse: string) => {
                            const name: string = this.getParameterName(
                                parameterUnparse
                            );
                            const value = this.getParameterValue(
                                parameterUnparse
                            );
                            return {
                                name: name,
                                value: value,
                            };
                        }
                    );
                    const shortcodeParts: ShortcodeParts = {
                        raw: rawShortcode,
                        name: shortcodeName,
                        parameters: parameters,
                    };
                    shortcodes.push(shortcodeParts);
                });
            }
        } catch (e) {
            LogService.log(
                'Shortcodes',
                'There was an error parsing for shortcodes. Reason: ',
                (e as Error).message
            );
        }
        return shortcodes;
    }

    private getShortcodeMatches(value: string): string[] {
        const matches: string[] = [];
        const remaining = value;
        let cursor = 0;
        try {
            while (remaining.indexOf('[', cursor) >= 0) {
                const openingBracketIndex = remaining.indexOf('[', cursor);
                if (remaining[openingBracketIndex - 1] !== '\\') {
                    if (remaining.indexOf(']', cursor) >= 0) {
                        while (remaining.indexOf(']', cursor) >= 0) {
                            const closingBracketIndex = remaining.indexOf(
                                ']',
                                cursor
                            );
                            if (
                                closingBracketIndex >= openingBracketIndex &&
                                remaining[closingBracketIndex - 1] !== '\\'
                            ) {
                                cursor = closingBracketIndex + 1;
                                matches.push(
                                    remaining.substring(
                                        openingBracketIndex,
                                        closingBracketIndex + 1
                                    )
                                );
                                break;
                            } else {
                                cursor = closingBracketIndex + 1;
                            }
                        }
                    } else {
                        throw new Error(
                            `No closing bracket for opening bracket at char ${openingBracketIndex}.`
                        );
                    }
                } else {
                    cursor = openingBracketIndex + 1;
                }
            }
        } catch (e) {
            LogService.log(
                'Shortcodes',
                `Any error occured while trying to parse ${value} for shortcodes. Reason: ${
                    (e as Error).message
                }`
            );
        }
        if (matches) {
            return Array.from(matches);
        }
        return [];
    }

    private getParameterMatches(value: string): string[] {
        let cursor = 0;
        const matches: string[] = [];
        while (value.indexOf(` `, cursor) >= 0) {
            if (value.indexOf(` `, cursor) < value.indexOf(`='`, cursor)) {
                const startIndex = value.indexOf(` `, cursor);
                const middleIndex = value.indexOf(`='`, cursor);
                cursor = middleIndex + 2;
                if (value.indexOf(`'`, cursor) >= 0) {
                    while (value.indexOf(`'`, cursor) >= 0) {
                        if (value[value.indexOf(`'`, cursor) - 1] !== '\\') {
                            const endIndex = value.indexOf(`'`, cursor);
                            cursor = endIndex + 1;
                            matches.push(
                                value.substring(startIndex + 1, endIndex + 1)
                            );
                            break;
                        } else {
                            cursor = value.indexOf(`'`, cursor) + 1;
                        }
                    }
                } else {
                    throw new Error(
                        `No closing quotation mark for parameter starting at char ${startIndex}`
                    );
                }
            } else {
                cursor = value.indexOf(` `, cursor);
            }
        }
        if (matches) {
            return Array.from(matches);
        }
        return [];
    }

    private getParameterName(value: string): string {
        return value.split('=')[0].trim();
    }

    private removeTrailingColon(value: string): string {
        value = value.trim();
        if (value.endsWith(':')) {
            value = value.substring(0, value.length - 1);
        }
        return value;
    }

    private getParameterValue(value: string): string {
        const cleanedParameterValue = value
            .split('=')[1]
            .replace("\\'", "'")
            .replace('\\[', '[')
            .replace('\\]', ']')
            .replace('\\\\', '\\');
        return cleanedParameterValue.substr(
            1,
            cleanedParameterValue.length - 2
        );
    }

    private async injectShortcodeValues(
        value: string,
        shortcodes: ShortcodeParts[]
    ): Promise<string> {
        let processed: string = value;
        await ShortcodeService.asyncForEach(
            shortcodes,
            async (shortcode: ShortcodeParts) => {
                const shortcodeValue: string = await this.getShortcodeValue(
                    shortcode
                );
                if (shortcodeValue) {
                    processed = processed.replace(
                        shortcode.raw,
                        shortcodeValue
                    );
                }
            }
        );
        processed = this.removeTrailingColon(processed);
        return processed;
    }

    private async getShortcodeValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        let value: string;
        switch (shortcode.name) {
            case 'user-language': {
                const languageMap: any = {};
                languageMap['English'] = 'eng';
                languageMap['Français'] = 'fra';
                languageMap['German'] = 'deu';
                languageMap['Czech'] = 'cze';
                languageMap['Italian'] = 'ita';
                languageMap['Dutch'] = 'dut';
                languageMap['Turkish'] = 'tur';
                languageMap['Russian'] = 'rus';
                languageMap['Danish'] = 'dan';
                languageMap['Swedish'] = 'swe';
                languageMap['Polish'] = 'pol';
                languageMap['Greek'] = 'gre';
                languageMap['Rundi'] = 'run';
                languageMap['Norwegian'] = 'nor';
                languageMap['Finnish'] = 'fin';
                languageMap['Hungarian'] = 'hun';
                languageMap['Spanish'] = 'spa';
                languageMap['Portuguese'] = 'por';
                languageMap['Korean'] = 'kor';
                languageMap['Romanian'] = 'rom';
                languageMap['Espanol'] = 'esp';
                languageMap['Thai'] = 'tha';
                languageMap['Vietnamese'] = 'vnm';

                const currentLanguage = new AppConfigurationService()
                    .currentLanguage;
                if (languageMap[currentLanguage]) {
                    value = languageMap[currentLanguage];
                } else {
                    throw new Error(
                        `Not able to return three letter language code for ${currentLanguage}. Logic for shortcode [user-language] needs to be updated.`
                    );
                }
                break;
            }
            case 'rad-ui-version': {
                const width = document.body.clientWidth;
                if (width > 992) {
                    value = 'ui:rad:pc';
                } else if (width > 768) {
                    value = 'ui:rad:tablet';
                } else {
                    value = 'ui:rad:mobile';
                }
                break;
            }
            case 'selected-vehicle-year': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.year}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'selected-vehicle-make': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.make}`.toLowerCase();
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'selected-vehicle-model': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.model}`.toLowerCase();
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'selected-vehicle-delivery-status': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.ownerState === 0 ? 'pre' : 'post'}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'selected-vehicle-vin': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    if (ymm.vin) {
                        value = `${ymm.vin}`;
                    } else {
                        throw new Error(
                            `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                        );
                    }
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'select-vehicle': {
                if (this.shortcodeProviders?.selectVehicle) {
                    value = this.shortcodeProviders?.selectVehicle;
                } else {
                    throw new Error(`Could not populate Select Vehicle`);
                }
                break;
            }
            case 'update-vehicle': {
                if (this.shortcodeProviders?.updateVehicle) {
                    value = this.shortcodeProviders?.updateVehicle;
                } else {
                    throw new Error(`Could not populate Update Vehicle`);
                }
                break;
            }
            case 'login-status': {
                const authenticationService = new AuthenticationService();
                value = (await authenticationService.onIsAuthenticated())
                    ? 'logged in'
                    : 'logged out';
                break;
            }
            case 'registered-status': {
                const authenticationService = new AuthenticationService();
                if (await authenticationService.onIsAuthenticated()) {
                    value = 'registered';
                } else {
                    throw new Error(
                        'Cannot return registered status for unauthenticated user.'
                    );
                }
                break;
            }
            case 'country-code': {
                const currentCountryCode = new AppConfigurationService()
                    .currentCountryCode;
                value = currentCountryCode;
                break;
            }
            case 'user-guid': {
                const profile =
                    (await new AuthenticationService().onIsAuthenticated()) &&
                    (await new ProfileService().request());
                if (profile) {
                    value = profile.profile.userGuid.toUpperCase();
                } else {
                    throw new Error(
                        'Cannot return user guid. There was an issue getting the profile.'
                    );
                }
                break;
            }
            case 'customer-id': {
                const customerIdResponse =
                    (await new AuthenticationService().onIsAuthenticated()) &&
                    (await new ProfileService().retrieveCustomerId());
                if (customerIdResponse) {
                    value = customerIdResponse.consumer.id;
                } else {
                    throw new Error('Cannot return customer ID.');
                }
                break;
            }
            case 'gdpr-opt-status': {
                const retrievedCookieConfig = localStorage.getItem(
                    'cookie-configuration'
                );
                if (retrievedCookieConfig) {
                    const cookieSettings = JSON.parse(retrievedCookieConfig)
                        .value;
                    const analyticSettings: string[] = [];
                    for (const key in cookieSettings) {
                        const value = cookieSettings[key];
                        const analyticValue = value ? 'yes' : 'no';
                        analyticSettings.push(`${key}:${analyticValue}`);
                    }
                    value = analyticSettings.join('|');
                } else if (shortcode.parameters.length > 0) {
                    value = shortcode.parameters
                        .map(p => `${p.name.replace('-', ' ')}:${p.value}`)
                        .join('|');
                } else {
                    throw new Error(
                        'Cannot return opt in status. GDPR user preferences not found.'
                    );
                }
                break;
            }
            case 'from-datalayer': {
                const propertyDescriptorParameter = shortcode.parameters.filter(
                    parameter => parameter.name === 'property-descriptor'
                )[0];
                if (propertyDescriptorParameter) {
                    const propertyValue = DataLayerService.getProperty(
                        propertyDescriptorParameter.value,
                        (window as any).digitaldata
                    );
                    if (propertyValue) {
                        value = propertyValue;
                    } else {
                        throw new Error(
                            `Property "${propertyDescriptorParameter.value}" is not populate in digitaldata.`
                        );
                    }
                } else {
                    throw new Error(
                        `Parameter "property-descriptor" needs to be set for this shortcode to populate.`
                    );
                }
                break;
            }
            case 'gdpr-modal-button-pressed': {
                if (this.shortcodeProviders?.gdprModalButtonPressed) {
                    const gdprModalButtonPressed = await Promise.resolve(
                        this.shortcodeProviders?.gdprModalButtonPressed
                    );
                    const acceptParameter = shortcode.parameters.filter(
                        parameter => parameter.name === 'accept'
                    )[0];
                    const manageParameter = shortcode.parameters.filter(
                        parameter => parameter.name === 'decline'
                    )[0];
                    if (acceptParameter && manageParameter) {
                        if (gdprModalButtonPressed === 'Accept') {
                            value = acceptParameter.value;
                        } else {
                            value = manageParameter.value;
                        }
                    } else {
                        throw new Error(
                            `Both "accept" and "decline" are required properties, one of them is missing.`
                        );
                    }
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'tile-name': {
                if (this.shortcodeProviders?.tileOnClickCtaInfo) {
                    const tileOnClickCtaInfo: TileOnClickCtaInfo = await this
                        .shortcodeProviders.tileOnClickCtaInfo;
                    value = `${tileOnClickCtaInfo.tileName}`;
                } else if (this.shortcodeProviders?.smashHomeClick?.tileName) {
                    value = this.shortcodeProviders?.smashHomeClick?.tileName;
                } else {
                    throw new Error(`Could not populate Tile Name`);
                }
                break;
            }
            case 'analytics-name': {
                if (this.shortcodeProviders?.tileOnClickCtaInfo) {
                    const tileOnClickCtaInfo: TileOnClickCtaInfo = await this
                        .shortcodeProviders.tileOnClickCtaInfo;
                    value = `${tileOnClickCtaInfo.analyticsName}`;
                } else {
                    throw new Error(
                        `Could not populate Dynamic QuickLink Name`
                    );
                }
                break;
            }
            case 'category-cta-label': {
                if (this.shortcodeProviders?.categoryOnClickCtaInfo) {
                    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = await this
                        .shortcodeProviders.categoryOnClickCtaInfo;
                    const urlPaths = categoryOnClickCtaInfo.ctaUrl
                        ?.split('/')
                        .filter(path => path && path !== '');
                    value = `${
                        urlPaths
                            ? urlPaths[urlPaths.length - 1]?.replaceAll(
                                  '-',
                                  ' '
                              )
                            : categoryOnClickCtaInfo.ctaName
                    }`;
                } else {
                    throw new Error(
                        `Could not populate Dynamic Category Cta Label`
                    );
                }
                break;
            }
            case 'category-path': {
                if (this.shortcodeProviders?.categoryOnClickCtaInfo) {
                    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = await this
                        .shortcodeProviders.categoryOnClickCtaInfo;
                    value = `${categoryOnClickCtaInfo.categoryPath
                        ?.replaceAll('/', ':')
                        .replaceAll('-', ' ')}`;
                } else {
                    throw new Error(
                        `Could not populate Dynamic Category Cta Path`
                    );
                }
                break;
            }
            case 'category-faq-panel-title': {
                if (this.shortcodeProviders?.categoryOnClickCtaInfo) {
                    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = await this
                        .shortcodeProviders?.categoryOnClickCtaInfo;
                    value = `${categoryOnClickCtaInfo?.panelTitle}`;
                } else {
                    throw new Error(
                        `Could not populate Dynamic Category Cta Path`
                    );
                }
                break;
            }
            case 'vehicle-checklist-type': {
                if (this.shortcodeProviders?.vehicleChecklistOnClickCtaInfo) {
                    const vehicleChecklistOnClickCtaInfo: VehicleChecklistOnClickCtaInfo = await this
                        .shortcodeProviders.vehicleChecklistOnClickCtaInfo;
                    value = `${vehicleChecklistOnClickCtaInfo.cardType?.replaceAll(
                        '-',
                        ' '
                    )}`;
                } else {
                    throw new Error(`Could not populate Card Type`);
                }
                break;
            }
            case 'cta-name': {
                if (this.shortcodeProviders?.tileOnClickCtaInfo) {
                    const tileOnClickCtaInfo: TileOnClickCtaInfo = await this
                        .shortcodeProviders.tileOnClickCtaInfo;
                    value = `${tileOnClickCtaInfo.ctaName}`;
                } else if (this.shortcodeProviders?.smashHomeClick?.ctaName) {
                    value = this.shortcodeProviders?.smashHomeClick?.ctaName;
                } else {
                    throw new Error(`Could not populate CTA Name`);
                }
                break;
            }
            case 'component-name': {
                if (this.shortcodeProviders?.dyfComponentName) {
                    value = this.shortcodeProviders?.dyfComponentName;
                } else {
                    throw new Error(`Could not populate component Name`);
                }
                break;
            }
            case 'coupons-category': {
                if (this.shortcodeProviders?.couponsCategoryName) {
                    value = this.shortcodeProviders?.couponsCategoryName;
                } else {
                    throw new Error(`Could not populate category`);
                }
                break;
            }
            case 'coupon-id': {
                if (this.shortcodeProviders?.couponsId) {
                    value = this.shortcodeProviders?.couponsId;
                } else {
                    throw new Error(`Could not populate coupon Id`);
                }
                break;
            }

            case 'rebates-category': {
                if (this.shortcodeProviders?.rebatesStepName) {
                    value = this.shortcodeProviders?.rebatesStepName;
                } else {
                    throw new Error(`Could not populate rebates step name`);
                }
                break;
            }

            case 'referred-url-page-name': {
                if (this.shortcodeProviders?.referredUrlPageName) {
                    value = this.shortcodeProviders?.referredUrlPageName;
                } else {
                    throw new Error(
                        `Could not populate referred url page name`
                    );
                }
                break;
            }
            case 'category': {
                if (this.shortcodeProviders?.discoverabilityPageLoad) {
                    const discoverabilityPageLoad: DiscoverabilityPageLoad = await this
                        .shortcodeProviders.discoverabilityPageLoad;
                    value = discoverabilityPageLoad.category || '';
                } else if (this.shortcodeProviders?.accordionClick) {
                    const accordionClick: AccordionClick = await this
                        .shortcodeProviders.accordionClick;
                    value = accordionClick.category || '';
                } else if (this.shortcodeProviders?.categoryDisplayName) {
                    value = this.shortcodeProviders?.categoryDisplayName;
                } else if (this.shortcodeProviders?.category) {
                    value = this.shortcodeProviders?.category;
                } else {
                    throw new Error(`Could not populate category name`);
                }
                break;
            }
            case 'subcategory': {
                if (this.shortcodeProviders?.discoverabilityPageLoad) {
                    const discoverabilityPageLoad: DiscoverabilityPageLoad = await this
                        .shortcodeProviders.discoverabilityPageLoad;
                    value = discoverabilityPageLoad.subcategory || '';
                } else if (this.shortcodeProviders?.accordionClick) {
                    const accordionClick: AccordionClick = await this
                        .shortcodeProviders.accordionClick;
                    value = accordionClick.subcategory || '';
                } else {
                    throw new Error(`Could not populate subcategory name`);
                }
                break;
            }
            case 'article': {
                if (this.shortcodeProviders?.discoverabilityPageLoad) {
                    const discoverabilityPageLoad: DiscoverabilityPageLoad = await this
                        .shortcodeProviders.discoverabilityPageLoad;
                    value = discoverabilityPageLoad.article || '';
                } else {
                    throw new Error(`Could not populate article name`);
                }
                break;
            }
            case 'owner-manuals': {
                if (this.shortcodeProviders?.ownerManuals) {
                    value = this.shortcodeProviders.ownerManuals || '';
                } else {
                    throw new Error(`Could not populate ownerManuals `);
                }
                break;
            }
            case 'owner-manuals-details': {
                if (this.shortcodeProviders?.ownerManualsDetails) {
                    value = this.shortcodeProviders.ownerManualsDetails || '';
                } else {
                    throw new Error(`Could not populate ownerManualsDetails`);
                }
                break;
            }
            case 'dyl-cta-name': {
                if (this.shortcodeProviders?.dylCtaName) {
                    value = this.shortcodeProviders.dylCtaName || '';
                } else {
                    throw new Error(`Could not populate dyl5050Cards`);
                }
                break;
            }
            case 'owner-manuals-sitemap': {
                if (this.shortcodeProviders?.ownerManualsSitemap) {
                    value = this.shortcodeProviders.ownerManualsSitemap || '';
                } else {
                    throw new Error(`Could not populate ownerManualsSitemap`);
                }
                break;
            }
            case 'searchterm': {
                if (this.shortcodeProviders?.discoverabilityPageLoad) {
                    const discoverabilityPageLoad: DiscoverabilityPageLoad = await this
                        .shortcodeProviders.discoverabilityPageLoad;
                    value = discoverabilityPageLoad.searchKeyword || ' ';
                } else {
                    throw new Error(`Could not populate search term`);
                }
                break;
            }
            case 'searched-no-searched': {
                if (this.shortcodeProviders?.searchNonSearch) {
                    value = this.shortcodeProviders.searchNonSearch;
                } else {
                    throw new Error(`Could not populate searched-no-searched`);
                }
                break;
            }
            case 'recalls': {
                if (this.shortcodeProviders?.recalls) {
                    value = this.shortcodeProviders.recalls || '';
                } else {
                    throw new Error(`Could not populate recalls`);
                }
                break;
            }
            case 'recalls-details': {
                if (this.shortcodeProviders?.recallsDetails) {
                    value = this.shortcodeProviders.recallsDetails || '';
                } else {
                    throw new Error(`Could not populate recallsDetails`);
                }
                break;
            }
            case 'trailer-checklist-conventional-cta-context': {
                if (this.shortcodeProviders?.trailerChecklistConventionalCta) {
                    value = this.shortcodeProviders
                        ?.trailerChecklistConventionalCta;
                } else {
                    throw new Error(
                        `Could not populate trailer checklist landing cta context`
                    );
                }
                break;
            }
            case 'trailer-checklist-conventional-reset-context': {
                if (
                    this.shortcodeProviders?.trailerChecklistConventionalReset
                ) {
                    value = this.shortcodeProviders
                        ?.trailerChecklistConventionalReset;
                } else {
                    throw new Error(
                        `Could not populate trailer checklist conventional reset context`
                    );
                }
                break;
            }
            case 'trailer-checklist-gooseneck-cta-context': {
                if (this.shortcodeProviders?.trailerChecklistGooseneckCta) {
                    value = this.shortcodeProviders
                        ?.trailerChecklistGooseneckCta;
                } else {
                    throw new Error(
                        `Could not populate trailer checklist landing reset context`
                    );
                }
                break;
            }
            case 'trailer-checklist-fifthwheel-cta-context': {
                if (this.shortcodeProviders?.trailerChecklistFifthWheelCta) {
                    value = this.shortcodeProviders
                        ?.trailerChecklistFifthWheelCta;
                } else {
                    throw new Error(
                        `Could not populate trailer checklist landing reset context`
                    );
                }
                break;
            }
            case 'trailer-checklist-gooseneck-reset-context': {
                if (this.shortcodeProviders?.trailerChecklistGooseneckReset) {
                    value = this.shortcodeProviders
                        ?.trailerChecklistGooseneckReset;
                } else {
                    throw new Error(
                        `Could not populate trailer checklist gooseneck reset context`
                    );
                }
                break;
            }
            case 'trailer-checklist-fifthwheel-reset-context': {
                if (this.shortcodeProviders?.trailerChecklistFifthWheelReset) {
                    value = this.shortcodeProviders
                        ?.trailerChecklistFifthWheelReset;
                } else {
                    throw new Error(
                        `Could not populate trailer checklist gooseneck reset context`
                    );
                }
                break;
            }
            case 'tsb-vin-select-cta-context': {
                if (this.shortcodeProviders?.tsbVinSelectCTA) {
                    value = this.shortcodeProviders?.tsbVinSelectCTA;
                } else {
                    throw new Error(
                        `Could not populate tsb vin select cta context`
                    );
                }
                break;
            }
            case 'tsb-cta-context': {
                if (this.shortcodeProviders?.tsbCtaClick) {
                    value = this.shortcodeProviders?.tsbCtaClick;
                } else {
                    throw new Error(`Could not populate tsb cta context`);
                }
                break;
            }
            case 'select-dealer-cta-context': {
                if (this.shortcodeProviders?.selectDealerCTA) {
                    value = this.shortcodeProviders?.selectDealerCTA;
                } else {
                    throw new Error(
                        `Could not populate select dealer cta context`
                    );
                }
                break;
            }
            case 'redeem-submit-cta': {
                if (this.shortcodeProviders?.redeemSubmitCTA) {
                    value = this.shortcodeProviders?.redeemSubmitCTA;
                } else {
                    throw new Error(
                        `Could not populate redeem submit cta context`
                    );
                }
                break;
            }
            case 'faq-question': {
                if (this.shortcodeProviders?.selfHelpFaq) {
                    value = this.shortcodeProviders.selfHelpFaq || '';
                } else {
                    throw new Error(`Could not populate faq question`);
                }
                break;
            }
            case 'field-name': {
                if (this.shortcodeProviders?.fieldName) {
                    value = this.shortcodeProviders?.fieldName;
                } else {
                    throw new Error(`Could not populate fieldName`);
                }
                break;
            }
            case 'error-description': {
                if (this.shortcodeProviders?.errorDescription) {
                    value = this.shortcodeProviders?.errorDescription;
                } else {
                    throw new Error(`Could not populate errorDescription`);
                }
                break;
            }
            case 'warranty-status': {
                const warrantyElement = document.querySelector(
                    '.accordion-text'
                );
                const warrantyText =
                    warrantyElement != null ? warrantyElement.innerHTML : '';
                if (warrantyText.indexOf('active') != -1) {
                    value = 'active';
                } else if (warrantyText.indexOf('expired') != -1) {
                    value = 'expired';
                } else {
                    value = 'no items';
                }

                break;
            }
            case 'category-id': {
                if (this.shortcodeProviders?.categoryId) {
                    const categoryId: string = await this.shortcodeProviders
                        .categoryId;
                    const categoryIdUrlPath = categoryId;
                    value = `${categoryIdUrlPath
                        .replaceAll('-', ' ')
                        .replaceAll('/', ':')}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'subcategory-id': {
                if (this.shortcodeProviders?.subcategoryId) {
                    const subcategoryId: string = await this.shortcodeProviders
                        .subcategoryId;
                    const subcategoryIdUrlPath = subcategoryId;
                    value = `${subcategoryIdUrlPath
                        .replaceAll('-', ' ')
                        .replaceAll('/', ':')}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'MY': {
                if (this.shortcodeProviders?.cxVehicle?.year) {
                    const year: string = await Promise.resolve(
                        this.shortcodeProviders.cxVehicle.year
                    );
                    value = `${year}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'cx-article-category': {
                if (this.shortcodeProviders?.cxVehicle?.categoryId) {
                    const categoryId: string = await Promise.resolve(
                        this.shortcodeProviders.cxVehicle.categoryId
                    );
                    value = `${categoryId}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'cx-article-name': {
                if (this.shortcodeProviders?.cxVehicle?.subCategoryId) {
                    const subCategoryId: string = await Promise.resolve(
                        this.shortcodeProviders.cxVehicle.subCategoryId
                    );
                    value = `${subCategoryId}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'cx-vin-or-ymm': {
                if (this.shortcodeProviders?.cxVehicle?.cxVinOrYmm) {
                    const cxVinOrYmm: string = await Promise.resolve(
                        this.shortcodeProviders?.cxVehicle?.cxVinOrYmm
                    );
                    value = `${cxVinOrYmm}`;
                } else {
                    throw new Error(
                        `Could not populate smash home search content cta name`
                    );
                }
                break;
            }
            case 'dealerID': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.dealerID || '';
                } else {
                    throw new Error(`Could not populate dealer Id`);
                }
                break;
            }
            case 'mileage': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.mileage || '';
                } else {
                    throw new Error(`Could not populate mileage`);
                }
                break;
            }
            case 'bookingID': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.bookingID || '';
                } else {
                    throw new Error(`Could not populate bookingID`);
                }
                break;
            }
            case 'userLocationSearchTerm': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.userLocationSearchTerm || '';
                } else {
                    throw new Error(`Could not populate user Location`);
                }
                break;
            }
            case 'searchDepth': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.searchDepth || '';
                } else {
                    throw new Error(`Could not populate search Depth`);
                }
                break;
            }
            case 'experience-type': {
                const expType = CheckExperienceType();
                if (expType === DeviceType.MOBILE) {
                    value = 'mobile';
                } else if (expType === DeviceType.TABLET) {
                    value = 'tablet';
                } else {
                    value = 'desktop';
                }
                break;
            }
            case 'env-domain': {
                const {
                    appConfigurations,
                    currentLanguageRegionCode,
                    brand,
                } = new AppConfigurationService();
                const currentAppConfig = appConfigurations.filter(
                    appConfig =>
                        brand == appConfig.brand &&
                        appConfig.languageRegionCode ==
                            currentLanguageRegionCode
                );
                if (currentAppConfig.length != 0) {
                    value =
                        currentAppConfig[0].domain != ''
                            ? 'https://' + currentAppConfig[0].domain
                            : '';
                } else {
                    value = '';
                }
                break;
            }
            case 'pageNameNoVehicle': {
                const currentPageNameNoVehicle =
                    (window as any).digitaldata.page.pageNameNoVehicle || '';
                value = currentPageNameNoVehicle;
                break;
            }
            case 'serviceType': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.serviceType || '';
                } else {
                    throw new Error(`Could not populate service Type`);
                }
                break;
            }
            case 'toolDescriptor': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.toolDescriptor || '';
                } else {
                    throw new Error(`Could not populate Tool Descriptor `);
                }
                break;
            }
            case 'bookingValue': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.bookingValue || '';
                } else {
                    throw new Error(`Could not populate booking Value`);
                }
                break;
            }
            case 'bookingProductString': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.bookingProductString || '';
                } else {
                    throw new Error(
                        `Could not populate booking Product String`
                    );
                }
                break;
            }
            case 'bookingVoucherCode': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.bookingVoucherCode || '';
                } else {
                    throw new Error(`Could not populate booking Voucher Code`);
                }
                break;
            }
            case 'video-name': {
                if (this.shortcodeProviders?.videoName) {
                    value = this.shortcodeProviders?.videoName;
                } else if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.videoName || '';
                } else {
                    throw new Error(`Could not populate video name`);
                }
                break;
            }
            case 'deepLinkType': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.deepLinkType || '';
                } else {
                    throw new Error(`Could not populate deep link type`);
                }
                break;
            }
            case 'pTemplate': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.pTemplate || '';
                } else {
                    throw new Error(`Could not populate pTemplate`);
                }
                break;
            }
            case 'cardType': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.cardType || '';
                } else {
                    throw new Error(`Could not populate service card type`);
                }
                break;
            }
            case 'panelType': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.panelType || '';
                } else {
                    throw new Error(`Could not populate service panel type`);
                }
                break;
            }
            case 'reviewAmendField': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.reviewAmendField || '';
                } else {
                    throw new Error(`Could not populate review amend field`);
                }
                break;
            }
            case 'searchLocationType': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.searchLocationType || '';
                } else {
                    throw new Error(
                        `Could not populate service serch location type`
                    );
                }
                break;
            }
            case 'searchType': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.searchType || '';
                } else {
                    throw new Error(`Could not populate dealer search type`);
                }
                break;
            }
            case 'errorDescription': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.errorDescription || '';
                } else {
                    throw new Error(
                        `Could not populate service error description`
                    );
                }
                break;
            }
            case 'warranty-cta-context':
            case 'esb-cta-context':
            case 'maintenance-schedule-cta-context':
            case 'vhr-cta-category':
            case 'service-history-records-tab': {
                if (this.shortcodeProviders?.fieldName) {
                    value = this.shortcodeProviders?.fieldName;
                } else {
                    throw new Error(`Could not populate ` + shortcode.name);
                }
                break;
            }

            case 'applyRemoveVoucher': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.applyRemoveVoucher || '';
                } else {
                    throw new Error(`Could not populate Voucher status`);
                }
                break;
            }
            case 'cta-type': {
                if (this.shortcodeProviders?.ctaType) {
                    value = this.shortcodeProviders?.ctaType;
                } else {
                    throw new Error(`Could not populate cta type`);
                }
                break;
            }
            case 'guide-name': {
                if (this.shortcodeProviders?.guideName) {
                    value = this.shortcodeProviders?.guideName;
                } else {
                    throw new Error(`Could not populate guide name`);
                }
                break;
            }
            case 'accessCodeStatus': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.accessCodeStatus || '';
                } else {
                    throw new Error(`Could not populate access code status`);
                }
                break;
            }
            case 'contentSubType': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.contentSubType || '';
                } else {
                    throw new Error(`Could not populate content sub type`);
                }
                break;
            }

            case 'selectedDealerFilters': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.selectedDealerFilters || '';
                } else {
                    throw new Error(
                        `Could not populate selected dealer filters`
                    );
                }
                break;
            }
            case 'service-history-record-type': {
                if (this.shortcodeProviders?.recordType) {
                    value = this.shortcodeProviders.recordType;
                } else {
                    throw new Error(
                        `Could not populate service-history-record-type`
                    );
                }
                break;
            }
            case 'maintenance-schedule-landing-cta-context': {
                if (this.shortcodeProviders?.maintenanceScheduleCtaContext) {
                    value = this.shortcodeProviders
                        ?.maintenanceScheduleCtaContext;
                } else {
                    throw new Error(
                        `Could not populate maintenance schedule landing cta context`
                    );
                }
                break;
            }
            case 'category-name': {
                if (this.shortcodeProviders?.categoryName) {
                    value = this.shortcodeProviders?.categoryName;
                } else {
                    throw new Error(`Could not populate catergory name`);
                }
                break;
            }
            case 'nameplate': {
                if (this.shortcodeProviders?.namePlateValue) {
                    value = this.shortcodeProviders?.namePlateValue;
                } else if (this.shortcodeProviders?.cxVehicle?.model) {
                    const model: string = await Promise.resolve(
                        this.shortcodeProviders.cxVehicle.model
                    );
                    value = `${model}`;
                } else if (this.shortcodeProviders?.nameplate) {
                    value = this.shortcodeProviders?.nameplate;
                } else {
                    throw new Error(`Could not populate nameplate value`);
                }
                break;
            }
            case 'notification-category': {
                if (this.shortcodeProviders?.notificationCategory) {
                    value = this.shortcodeProviders?.notificationCategory;
                } else {
                    throw new Error(
                        `Could not populate notification catergory name`
                    );
                }
                break;
            }
            case 'notification-cta-name': {
                if (this.shortcodeProviders?.notificationCtaName) {
                    value = this.shortcodeProviders?.notificationCtaName;
                } else {
                    throw new Error(`Could not populate notification cta name`);
                }
                break;
            }
            case 'smash-home-search-content-cta': {
                if (
                    this.shortcodeProviders?.smashHomeClick
                        ?.smashHomeSearchContentCta
                ) {
                    value = this.shortcodeProviders?.smashHomeClick
                        ?.smashHomeSearchContentCta;
                } else {
                    throw new Error(
                        `Could not populate smash home search content cta name`
                    );
                }
                break;
            }
            case 'card-name': {
                if (this.shortcodeProviders?.smashHomeClick?.cardName) {
                    value = this.shortcodeProviders?.smashHomeClick?.cardName;
                } else {
                    throw new Error(
                        `Could not populate smash home click event card-name`
                    );
                }
                break;
            }
            case 'bbt-category-name': {
                if (this.shortcodeProviders?.smashHomeClick?.bbtCategoryName) {
                    value = this.shortcodeProviders?.smashHomeClick
                        ?.bbtCategoryName;
                } else {
                    throw new Error(
                        `Could not populate smash home click event bbt-category-name`
                    );
                }
                break;
            }
            case 'routine-services-name': {
                if (
                    this.shortcodeProviders?.smashHomeClick?.infoGridServiceName
                ) {
                    value = this.shortcodeProviders?.smashHomeClick
                        ?.infoGridServiceName;
                } else {
                    throw new Error(
                        `Could not populate smash home click event routine-services-name`
                    );
                }
                break;
            }
            case 'article-name': {
                if (this.shortcodeProviders?.smashHomeClick?.articleName) {
                    value = this.shortcodeProviders?.smashHomeClick
                        ?.articleName;
                } else {
                    throw new Error(
                        `Could not populate smash home click event article-name`
                    );
                }
                break;
            }
            case 'videoPercentage': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.videoPercentage || '';
                } else {
                    throw new Error(`Could not populate video percentage`);
                }
                break;
            }
            case 'segmentViewed': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.segmentViewed || '';
                } else {
                    throw new Error(`Could not populate segment viewed`);
                }
                break;
            }
            case 'fileStatus': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.fileStatus || '';
                } else {
                    throw new Error(`Could not populate file event`);
                }
                break;
            }
            case 'question': {
                if (this.shortcodeProviders?.question) {
                    value = this.shortcodeProviders?.question;
                } else {
                    throw new Error(`Could not identify question`);
                }
                break;
            }
            case 'topics': {
                if (this.shortcodeProviders?.topics) {
                    value = this.shortcodeProviders?.topics.join();
                } else {
                    throw new Error(`Could not identify topics`);
                }
                break;
            }
            case 'feature': {
                if (this.shortcodeProviders?.featureName) {
                    value = this.shortcodeProviders?.featureName;
                } else if (this.shortcodeProviders?.sendToDealerTextName) {
                    value = this.shortcodeProviders?.sendToDealerTextName;
                } else if (this.shortcodeProviders?.footerChecklist) {
                    value = this.shortcodeProviders?.footerChecklist;
                } else if (this.shortcodeProviders?.feature) {
                    value = this.shortcodeProviders?.feature;
                } else {
                    throw new Error(`Could not identify feature`);
                }
                break;
            }
            case 'checkbox-state': {
                if (this.shortcodeProviders?.detailsCheckboxState) {
                    value = this.shortcodeProviders?.detailsCheckboxState;
                } else {
                    throw new Error(`Could not identify checkbox-state`);
                }
                break;
            }
            case 'features': {
                if (this.shortcodeProviders?.featuresList) {
                    value = this.shortcodeProviders?.featuresList;
                } else {
                    throw new Error(`Could not identify features`);
                }
                break;
            }
            case 'system': {
                if (this.shortcodeProviders?.system) {
                    value = this.shortcodeProviders?.system;
                } else {
                    throw new Error(`Could not identify system`);
                }
                break;
            }
            case 'tc-landing-cta-context': {
                if (this.shortcodeProviders?.towingCalculatorCtaContext) {
                    value = this.shortcodeProviders?.towingCalculatorCtaContext;
                } else {
                    throw new Error(
                        `Could not populate towing calculator landing cta context`
                    );
                }
                break;
            }
            case 'trailer-checklist-landing-cta-context': {
                if (this.shortcodeProviders?.trailerChecklistCtaContext) {
                    value = this.shortcodeProviders?.trailerChecklistCtaContext;
                } else {
                    throw new Error(
                        `Could not populate trailer checklist landing cta context`
                    );
                }
                break;
            }
            case 'page-name-alias': {
                if (this.shortcodeProviders?.pageNameAlias) {
                    value = this.shortcodeProviders?.pageNameAlias;
                } else {
                    throw new Error(
                        `Could not populate scroll to top page name alias`
                    );
                }
                break;
            }
            case 'vinMileage': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.vinMileage || '';
                } else {
                    throw new Error(
                        `Could not populate vin tooltip click event`
                    );
                }
                break;
            }
            case 'page-category-type': {
                const pagePath = window?.location?.pathname;
                const urlSlugs = pagePath?.split('/')?.filter(str => str);
                const index = urlSlugs?.indexOf('category');
                const categorySlugs =
                    index !== -1 ? urlSlugs?.slice(index + 1) : [];
                value = 'category';
                if (categorySlugs.length > 1) {
                    value = 'subcategory';
                }
                break;
            }
            case 'serviceName': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.serviceName || '';
                } else {
                    throw new Error(
                        `Could not populate service tooltip click event`
                    );
                }
                break;
            }
            case 'nameplateData': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.nameplateData || '';
                } else {
                    throw new Error(`Could not populate nameplate data`);
                }
                break;
            }
            case 'pcCta': {
                if (this.shortcodeProviders?.pcPageLoad) {
                    const pcPageLoad: PCPageLoad = this.shortcodeProviders
                        .pcPageLoad;
                    value = pcPageLoad.pcCta || '';
                } else {
                    throw new Error(`Could not populate pcCta`);
                }
                break;
            }
            case 'selectedInputField': {
                if (this.shortcodeProviders?.pcPageLoad) {
                    const pcPageLoad: PCPageLoad = this.shortcodeProviders
                        .pcPageLoad;
                    value = pcPageLoad.selectedInputField || '';
                } else {
                    throw new Error(`Could not populate selectedInputField`);
                }
                break;
            }
            case 'jumpToCategoryName': {
                if (this.shortcodeProviders?.jumpToCategoryName) {
                    value = this.shortcodeProviders?.jumpToCategoryName;
                } else {
                    throw new Error(`Could not populate jump to category name`);
                }
                break;
            }
            case 'ctaName': {
                if (this.shortcodeProviders?.osbPayload) {
                    const osbPayload: OSBPayload = this.shortcodeProviders
                        .osbPayload;
                    value = osbPayload.ctaName || '';
                } else {
                    throw new Error(`Could not populate ctaName`);
                }
                break;
            }
            case 'choose-method-cta-context': {
                if (this.shortcodeProviders?.chooseMethodCTAContext) {
                    value = this.shortcodeProviders.chooseMethodCTAContext;
                } else {
                    throw new Error(
                        `Could not populate Rebates choose method CTA context`
                    );
                }
                break;
            }
            case 'accordion-name': {
                if (this.shortcodeProviders?.accordionClick) {
                    const accordionClick: AccordionClick = await this
                        .shortcodeProviders.accordionClick;
                    value = accordionClick.accordionName || '';
                } else {
                    throw new Error(`Could not populate accordion name`);
                }
                break;
            }
            case 'cx-share-social-icon-name': {
                if (this.shortcodeProviders?.cxShareSocialIconName) {
                    value = this.shortcodeProviders.cxShareSocialIconName;
                } else {
                    throw new Error(
                        `Could not populate Social media Share Icon Name`
                    );
                }
                break;
            }
            case 'qsg-file-name': {
                if (this.shortcodeProviders?.qsgFileName) {
                    value = this.shortcodeProviders?.qsgFileName;
                } else {
                    throw new Error(
                        `Could not populate Quick Start Guide filename`
                    );
                }
                break;
            }
            case 'tab-title': {
                if (this.shortcodeProviders?.tabTitle) {
                    value = this.shortcodeProviders.tabTitle;
                } else {
                    throw new Error(`Could not populate Tab Title`);
                }
                break;
            }
            case 'action-item-type': {
                if (this.shortcodeProviders?.actionItemType) {
                    value = this.shortcodeProviders.actionItemType.replace(
                        /-/g,
                        ' '
                    );
                } else {
                    throw new Error(`Could not populate Action Item type`);
                }
                break;
            }
            default: {
                throw new Error(
                    `Shortcode ${shortcode} not recognized. A value cannot be provided.`
                );
            }
        }
        return value;
    }

    private static async asyncForEach<T>(array: T[], callback: Function) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
}
