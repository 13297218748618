import {
    notify,
    SlackMessages,
} from '../slack-message-service/SlackMessageService';

const allowedWMICodes = [
    '1F0',
    '1F6',
    '1FA',
    '1FB',
    '1FC',
    '1FD',
    '1FM',
    '1FT',
    '1LN',
    '1ZV',
    '2FA',
    '2FB',
    '2FC',
    '2FM',
    '2FT',
    '2L1',
    '2LJ',
    '2LM',
    '2LN',
    '3FA',
    '3FE',
    '3FM',
    '3LN',
    '6FP',
    '7A5',
    '8AF',
    '9BF',
    'AFA',
    'LJX',
    'LVS',
    'MAJ',
    'MM0',
    'MM6',
    'MM7',
    'MM8',
    'MMZ',
    'MNA',
    'MNB',
    'MNC',
    'MPB',
    'MS5',
    'MS6',
    'NM0',
    'PE1',
    'PR8',
    'SFA',
    'VS6',
    'WF0',
    'WF1',
    'WF2',
    'WF3',
    'WF4',
    'WF5',
    'WF6',
    'WF7',
    'WF8',
    'X2F',
    'Z6F',
    '3FC',
    '3FR',
    'RL0',
    '3FT',
    '4M2',
    '8XD',
    '8YP',
    '8YT',
];
const allowedMercuryWMICodes = ['1M', '2M', '3M', '4M', '5L'];

export default function isSupportedVin(vin: string): boolean {
    const isAllowedVin = allowedWMICodes.includes(
        vin.slice(0, 3).toUpperCase()
    );
    const isAllowedMercuryVin = allowedMercuryWMICodes.includes(
        vin.slice(0, 2).toUpperCase()
    );

    if (!isAllowedVin && !isAllowedMercuryVin) {
        notify(SlackMessages.NON_MATCHING_VIN_ERROR + vin);
    }

    return isAllowedVin || isAllowedMercuryVin;
}

export const decodeVin = (vin: string): string => {
    const reO = /O/gi;
    const reI = /I/gi;
    const reQ = /Q/gi;
    const cleanVin = vin
        .replace(reO, '0')
        .replace(reI, '1')
        .replace(reQ, '0');
    return cleanVin;
};
