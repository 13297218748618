import React, { useState, useEffect, useReducer, ChangeEvent } from 'react';
import parse from 'html-react-parser';
import { Dropdown } from '../../../../common';
import {
    PERSONAL_DETAIL_STEP_KEYS,
    STEP_PROGRESS_BAR_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_CLIENT_STORAGE_KEYS,
} from '../../osb-constant';
import './light-personal-details-flow-lincoln.scss';
import {
    usePersonalDetailStep,
    useHttp,
    getInitialPersonalDetailState,
    useVehicleStep,
    useViewport,
    useStepProgressBar,
    useOSBStep,
    usePageRefresh,
    useDealerStep,
} from '../../../../../hooks/owners-osb';
import {
    PersonalDetail,
    PersonalDetailErrorField,
} from '../../../../../models/osb-model/osb-personal-details';
import errorWarning from '../../../../../assets/error-warning.svg';
import { personalDetailReducer } from '../../../../../reducers/osb-reducers';
import { LightBookingSummary } from '../../light-booking-summary/light-booking-summary';
import BookingSummaryAccordion from '../../light-booking-summary/booking-summary-accordion/booking-summary-accordion';
import { OSBSecondaryButton } from '../../common/osb-secondary-button/osb-secondary-button';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import {
    OsbPathBookingRetrieveSummaryStep,
    OsbPathBookingReviewStep,
    OsbPathCalenderStep,
    OsbPathPersonalDetailsStep,
} from '../../osb-controller';
import {
    triggerDealerContactDetailsAnalytics,
    triggerContactDetailsErrorAnalytics,
    triggerRetrieveAmendContactDetailsLoadAnalytics,
    triggerRetrieveAmendContactDetailsErrorAnalytics,
    triggerBookServiceGlobalCTAOnClickAnalytics,
} from '../../analytics/osb-analytics';
import { ContentElements } from '../../../../../models/osb-model/osb-content-details';
import ServiceHandler from '../../../../../services/service-handler';
import {
    setRouteToPersist,
    buildNavigationUrl,
    formatRegVinNumber,
    getClientStorageValue,
    getGoMainHeaderHeight,
    getObjectFromAEMJson,
} from '../../osb-utils';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import { VehicleData } from '../../../../../models/osb-model/osb-vehicle-step';
import CountryDialCodePicker, {
    CountryDialCodePickerContent,
    CountryDialCodePickerOptions,
} from '../../../../common/country-dial-code-picker/country-dial-code-picker';
import { useCountryDialCodePicker } from '../../../../../hooks/use-country-dial-code-picker';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import useDealerCalendarAvailability from '../../../../../hooks/owners-osb/use-dealer-calendar-availability';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';
import { FMButton } from '@own/fds-react';
interface Props {
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}
export const LightPersonalDetailsFlow = (props: Props) => {
    enum PersonalDetailsConsent {
        FIRST_CONSENT = 1,
        SECOND_CONSENT = 2,
    }
    const { fetchAvailability } = useDealerCalendarAvailability();
    const {
        osbStepProgressBar,
        updateProgressBarStatus,
    } = useStepProgressBar();
    const { osbVehicleStep, setOSBVehicleStepPayload } = useVehicleStep();
    const { isMobileView } = useViewport();
    const { osbDealerStep } = useDealerStep();
    const bookable = useAllOSBState();
    const [fireEvents] = useAnalytics();
    const [personalDetailsJson, setPersonalDetailsJson] = useState<any>();
    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('');
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState('');
    const [contactNumberErrorMsg, setContactNumberErrorMsg] = useState('');
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [isFirstConsentDisabled, setIsFirstConsentDisabled] = useState<
        boolean
    >(false);
    const [isSecondConsentDisabled, setIsSecondConsentDisabled] = useState<
        boolean
    >(false);
    const [firstConsentLabel, setFirstConsentLabel] = useState<string>('');
    const [secondConsentLabel, setSecondConsentLabel] = useState<string>('');
    const [firstConsentAnchorText, setFirstConsentAnchorText] = useState<
        string
    >('');
    const [secondConsentAnchorText, setSecondConsentAnchorText] = useState<
        string
    >('');
    const [firstConsentAnchorURL, setFirstConsentAnchorURL] = useState<string>(
        ''
    );
    const [secondConsentAnchorURL, setSecondConsentAnchorURL] = useState<
        string
    >('');
    const [
        isregistrationNumberLabelShow,
        setIsregistrationNumberLabelShow,
    ] = useState<boolean>(false);
    const [isEmailLabelShow, setIsEmailLabelShow] = useState<boolean>(false);
    const [isContactNumberLabelShow, setIsContactNumberLabelShow] = useState<
        boolean
    >(false);
    const [isLastNameLabelShow, setIsLastNameLabelShow] = useState<boolean>(
        false
    );
    const [isFirstNameLabelShow, setIsFirstNameLabelShow] = useState<boolean>(
        false
    );
    const invalidFormFieldsForAnalytic: PersonalDetailErrorField[] = [];
    const [
        registrationNumberErrorMsg,
        setRegistrationNumberErrorMsg,
    ] = useState('');
    const {
        osbPersonalDetail,
        setOSBPersonalDetail,
        callContentService,
    } = usePersonalDetailStep();
    const [personalDetail, setPersonalDetail] = useReducer(
        personalDetailReducer,
        getInitialPersonalDetailState()
    );
    const { httpState, dispatch } = useHttp();
    const { osbStep, invalidateAuthentication } = useOSBStep();
    const history = useHistory();
    const isRetrieveStep = osbStep.isRetrieveFlow;

    const [showSaveToMyFordCheckbox, setShowSaveToMyFordCheckbox] = useState<
        boolean
    >(false);
    const [saveToMyFordCheckboxValue, setSaveToMyFordCheckboxValue] = useState<
        boolean
    >(false);

    const [isDirtyTitle, setIsDirtyTitle] = useState<boolean>(false);
    const [isDirtyFirstName, setIsDirtyFirstName] = useState<boolean>(false);
    const [isDirtyLastName, setIsDirtyLastName] = useState<boolean>(false);
    const [isDirtyContactNumber, setIsDirtyContactNumber] = useState<boolean>(
        false
    );
    // master list of country and dial code data
    // Custom hook to fetch data from AEM - experience fragmant
    const countryDialCodeContent = useCountryDialCodePicker();
    // AEM authored flag
    const displayCountryDialCodeComponent =
        osbStep.displayCountryDialCodeComponent;
    // used to find out the default market code
    const appConfig = OsbUtilService.getAppConfiguration();
    // country json data authored in EF
    const [countryCodeData, setCountryCodeData] = useState<
        CountryDialCodePickerContent
    >();
    // default merket code displayed in dropdown
    const [
        defaultMarketCodeToDisplay,
        setDefaultMarketCodeToDisplay,
    ] = useState<string>('');

    const [
        disableReviewBookingButtonValue,
        setDisableReviewBookingButtonValue,
    ] = useState<boolean>(true);
    usePageRefresh();
    const setComponentPayload = (
        payload:
            | { [key: string]: string | boolean | CountryDialCodePickerOptions }
            | PersonalDetail
    ): void => {
        setPersonalDetail({
            type: 'SET',
            payload: payload,
            localStorageExpiry: osbStep.localStorageExpiry,
        });
    };
    const goToDateTimeStep = () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.BACK_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        invalidateAuthentication();
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.CONTACT, false);
        history.push(
            buildNavigationUrl(OsbPathCalenderStep(), osbStep.UrlQueryParams)
        );
    };

    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.osbAlertMessagePopup(message, success);
    };

    function isPrivacyAnchorTagPresent(
        consentId: PersonalDetailsConsent,
        elements: ContentElements[]
    ) {
        if (PersonalDetailsConsent.FIRST_CONSENT === consentId) {
            const consentLinkText: string = getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.FIRST_CONSENT_LINK_TEXT,
                elements
            );
            const targetUrl: string = getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.FIRST_CONSENT_PRIVACY_TARGET_URL,
                elements
            );
            if (targetUrl && consentLinkText?.trim()) {
                setFirstConsentAnchorText(consentLinkText);
                setFirstConsentAnchorURL(targetUrl);
                return true;
            }
        } else if (PersonalDetailsConsent.SECOND_CONSENT === consentId) {
            const consentLinkText: string = getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.SECOND_CONSENT_LINK_TEXT,
                elements
            );
            const targetUrl: string = getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.SECOND_CONSENT_PRIVACY_TARGET_URL,
                elements
            );
            if (targetUrl && consentLinkText?.trim()) {
                setSecondConsentAnchorText(consentLinkText);
                setSecondConsentAnchorURL(targetUrl);
                return true;
            }
        }
        return false;
    }
    const isShowSaveToMyFordCheckbox = (
        titleDirty: boolean,
        firstNameDirty: boolean,
        lastNameDirty: boolean,
        contactNumDirty: boolean
    ) => {
        if (
            (titleDirty ||
                firstNameDirty ||
                lastNameDirty ||
                contactNumDirty) &&
            getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.SAVE_TO_MY_FORD_LABEL,
                personalDetailsJson
            ) !== null &&
            getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.SAVE_TO_MY_FORD_LABEL,
                personalDetailsJson
            ) !== ''
        ) {
            setShowSaveToMyFordCheckbox(true);
        } else {
            setShowSaveToMyFordCheckbox(false);
        }
    };
    const onTitleChange = (label: string) => {
        const titleDefaultLabel = getObjectFromAEMJson(
            PERSONAL_DETAIL_STEP_KEYS.AUTOFILL_TITLE_LABEL,
            personalDetailsJson
        );

        if (label?.toUpperCase() !== titleDefaultLabel?.toUpperCase()) {
            setComponentPayload({ title: label || '' });
        }
        if (osbStep.isAuthenticatedFlow) {
            if (osbPersonalDetail.title !== label) {
                setIsDirtyTitle(true);
            } else {
                setIsDirtyTitle(false);
            }
            isShowSaveToMyFordCheckbox(
                osbPersonalDetail.title !== label,
                isDirtyFirstName,
                isDirtyLastName,
                isDirtyContactNumber
            );
        }
    };
    const handleFirstNameValidation = (value: string) => {
        if (
            (getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.FIRST_NAME_MANDATORY_FIELD,
                personalDetailsJson
            ) &&
                value.length <= 0) ||
            (value.length > 0 &&
                !value.match(
                    getObjectFromAEMJson(
                        PERSONAL_DETAIL_STEP_KEYS.FIRST_NAME_REGEX_PATTERN,
                        personalDetailsJson
                    )
                ))
        ) {
            setFirstNameErrorMsg(
                getObjectFromAEMJson(
                    PERSONAL_DETAIL_STEP_KEYS.FIRST_NAME_ERROR_MESSAGE,
                    personalDetailsJson
                )
            );
            const inputValue = value.length <= 0 ? 'no entry' : 'invalid entry';
            const analyticErrorField: PersonalDetailErrorField = {
                field: 'firstName',
                entry: inputValue,
            };
            invalidFormFieldsForAnalytic.push(analyticErrorField);
            return false;
        } else {
            setFirstNameErrorMsg('');
            return true;
        }
    };
    const isAEMConsentEnabled = (consentNumber: number) => {
        if (consentNumber === PersonalDetailsConsent.FIRST_CONSENT)
            return getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.ENABLE_FIRST_CONSENT_CHECKBOX,
                personalDetailsJson
            );
        else if (consentNumber === PersonalDetailsConsent.SECOND_CONSENT)
            return getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.ENABLE_SECOND_CONSENT_CHECKBOX,
                personalDetailsJson
            );
        else return false;
    };
    const enableReviewBookingButton = () => {
        if (
            getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.FIRST_NAME_MANDATORY_FIELD,
                personalDetailsJson
            )
        ) {
            if (personalDetail.firstName === '' || firstNameErrorMsg !== '') {
                return false;
            }
        }
        if (
            getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.LAST_NAME_MANDATORY_FIELD,
                personalDetailsJson
            )
        ) {
            if (personalDetail.lastName === '' || lastNameErrorMsg !== '') {
                return false;
            }
        }
        if (
            getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.CONTACT_NUMBER_MANDATORY_FIELD,
                personalDetailsJson
            )
        ) {
            if (
                personalDetail.contactNumber === '' ||
                contactNumberErrorMsg !== ''
            ) {
                return false;
            }
        }
        if (
            getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.EMAIL_MANDATORY_FIELD,
                personalDetailsJson
            )
        ) {
            if (personalDetail.email === '' || emailErrorMsg !== '') {
                return false;
            }
        }
        if (
            getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.REG_NUMBER_MANDATORY_FIELD,
                personalDetailsJson
            )
        ) {
            if (
                osbVehicleStep.vehicleDetails.vin === '' ||
                osbVehicleStep.vehicleDetails.vin === null
            ) {
                if (
                    personalDetail.registrationNumber === '' ||
                    registrationNumberErrorMsg !== ''
                ) {
                    return false;
                }
            }
        }
        if (
            isAEMConsentEnabled(PersonalDetailsConsent.FIRST_CONSENT) &&
            !personalDetail.isFirstConsentEnabled
        ) {
            return false;
        }
        if (
            isAEMConsentEnabled(PersonalDetailsConsent.SECOND_CONSENT) &&
            !personalDetail.isSecondConsentEnabled
        ) {
            return false;
        }
        return true;
    };
    const handleLastNameValidation = (value: string) => {
        if (
            (getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.LAST_NAME_MANDATORY_FIELD,
                personalDetailsJson
            ) &&
                value.length <= 0) ||
            (value.length > 0 &&
                !value.match(
                    getObjectFromAEMJson(
                        PERSONAL_DETAIL_STEP_KEYS.LAST_NAME_REGEX_PATTERN,
                        personalDetailsJson
                    )
                ))
        ) {
            setLastNameErrorMsg(
                getObjectFromAEMJson(
                    PERSONAL_DETAIL_STEP_KEYS.LAST_NAME_ERROR_MESSAGE,
                    personalDetailsJson
                )
            );
            const inputValue = value.length <= 0 ? 'no entry' : 'invalid entry';
            const analyticErrorField: PersonalDetailErrorField = {
                field: 'lastName',
                entry: inputValue,
            };
            invalidFormFieldsForAnalytic.push(analyticErrorField);
            return false;
        } else {
            setLastNameErrorMsg('');
            return true;
        }
    };

    const handleContactNumberValidation = (
        value: string,
        regexPattern?: string
    ) => {
        const finalRegexPattern =
            regexPattern && regexPattern.length > 0
                ? regexPattern
                : getObjectFromAEMJson(
                      PERSONAL_DETAIL_STEP_KEYS.CONTACT_NUMBER_REGEX_PATTERN,
                      personalDetailsJson
                  );
        if (
            (getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.CONTACT_NUMBER_MANDATORY_FIELD,
                personalDetailsJson
            ) &&
                value.length <= 0) ||
            (value.length > 0 && !value.match(finalRegexPattern))
        ) {
            setContactNumberErrorMsg(
                getObjectFromAEMJson(
                    PERSONAL_DETAIL_STEP_KEYS.CONTACT_NUMBER_ERROR_MESSAGE,
                    personalDetailsJson
                )
            );
            const inputValue = value.length <= 0 ? 'no entry' : 'invalid entry';
            const analyticErrorField: PersonalDetailErrorField = {
                field: 'contactNumber',
                entry: inputValue,
            };
            invalidFormFieldsForAnalytic.push(analyticErrorField);
            return false;
        } else {
            setContactNumberErrorMsg('');
            return true;
        }
    };
    const handleEmailValidation = (value: string) => {
        if (
            (getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.EMAIL_MANDATORY_FIELD,
                personalDetailsJson
            ) &&
                value.length <= 0) ||
            (value.length > 0 &&
                !value.match(
                    getObjectFromAEMJson(
                        PERSONAL_DETAIL_STEP_KEYS.EMAIL_REGEX_PATTERN,
                        personalDetailsJson
                    )
                ))
        ) {
            setEmailErrorMsg(
                getObjectFromAEMJson(
                    PERSONAL_DETAIL_STEP_KEYS.EMAIL_ERROR_MESSAGE,
                    personalDetailsJson
                )
            );
            const inputValue = value.length <= 0 ? 'no entry' : 'invalid entry';
            const analyticErrorField: PersonalDetailErrorField = {
                field: 'email',
                entry: inputValue,
            };
            invalidFormFieldsForAnalytic.push(analyticErrorField);
            return false;
        } else {
            setEmailErrorMsg('');
            return true;
        }
    };
    const handleRegistrationNumberValidation = (value: string) => {
        if (
            (getObjectFromAEMJson(
                PERSONAL_DETAIL_STEP_KEYS.REG_NUMBER_MANDATORY_FIELD,
                personalDetailsJson
            ) &&
                value.length <= 0) ||
            (value.length > 0 &&
                !value.match(
                    getObjectFromAEMJson(
                        PERSONAL_DETAIL_STEP_KEYS.REG_NUMBER_REGEX_PATTERN,
                        personalDetailsJson
                    )
                ))
        ) {
            setRegistrationNumberErrorMsg(
                getObjectFromAEMJson(
                    PERSONAL_DETAIL_STEP_KEYS.REG_NUMBER_ERROR_MESSAGE,
                    personalDetailsJson
                )
            );
            const inputValue = value.length <= 0 ? 'no entry' : 'invalid entry';
            const analyticErrorField: PersonalDetailErrorField = {
                field: 'registrationNumber',
                entry: inputValue,
            };
            invalidFormFieldsForAnalytic.push(analyticErrorField);
            return false;
        } else {
            setRegistrationNumberErrorMsg('');
            return true;
        }
    };

    const onFirstNameChange = (e: any) => {
        if (osbStep.isAuthenticatedFlow) {
            if (osbPersonalDetail.firstName !== e.target.value) {
                setIsDirtyFirstName(true);
            } else {
                setIsDirtyFirstName(false);
            }
            isShowSaveToMyFordCheckbox(
                isDirtyTitle,
                osbPersonalDetail.firstName !== e.target.value,
                isDirtyLastName,
                isDirtyContactNumber
            );
        }
        setComponentPayload({ firstName: e.target.value.trimStart() });
        handleFirstNameValidation(e.target.value.trimStart());
    };
    const onLastNameChange = (e: any) => {
        if (osbStep.isAuthenticatedFlow) {
            if (osbPersonalDetail.lastName !== e.target.value) {
                setIsDirtyLastName(true);
            } else {
                setIsDirtyLastName(false);
            }
            isShowSaveToMyFordCheckbox(
                isDirtyTitle,
                isDirtyFirstName,
                osbPersonalDetail.lastName !== e.target.value,
                isDirtyContactNumber
            );
        }
        setComponentPayload({ lastName: e.target.value.trimStart() });
        handleLastNameValidation(e.target.value.trimStart());
    };
    const onContactNumberChange = (e: any) => {
        if (osbStep.isAuthenticatedFlow) {
            if (osbPersonalDetail.contactNumber !== e.target.value) {
                setIsDirtyContactNumber(true);
            } else {
                setIsDirtyContactNumber(false);
            }
            isShowSaveToMyFordCheckbox(
                isDirtyTitle,
                isDirtyFirstName,
                isDirtyLastName,
                osbPersonalDetail.contactNumber !== e.target.value
            );
        }
        if (displayCountryDialCodeComponent) {
            let value = e.target.value.trim();
            if (
                !value.includes(
                    personalDetail.countryDialCodeObject.countryDialCode
                )
            ) {
                value = `${personalDetail.countryDialCodeObject.countryDialCode}`;
            }
            setComponentPayload({
                contactNumber: `${value}`,
            });
            handleContactNumberValidation(
                e.target.value.trimStart(),
                personalDetail.countryDialCodeObject.countryRegex
            );
        } else {
            setComponentPayload({ contactNumber: e.target.value.trimStart() });
            handleContactNumberValidation(e.target.value.trimStart());
        }
    };
    const onEmailChange = (e: any) => {
        setComponentPayload({ email: e.target.value.trimStart() });
        handleEmailValidation(e.target.value.trimStart());
    };
    const onRegistrationNumberChange = (e: any) => {
        setComponentPayload({ registrationNumber: e.target.value.trimStart() });
        handleRegistrationNumberValidation(
            e.target.value.toUpperCase().trimStart()
        );
    };

    const registrationNumberLabelShow = () => {
        setIsregistrationNumberLabelShow(true);
    };

    const registrationNumberLabelHide = () => {
        setIsregistrationNumberLabelShow(false);
    };

    const emailLabelShow = () => {
        setIsEmailLabelShow(true);
    };

    const emailLabelHide = () => {
        setIsEmailLabelShow(false);
    };

    const contactNumberLabelShow = () => {
        setIsContactNumberLabelShow(true);
    };

    const contactNumberLabelHide = () => {
        setIsContactNumberLabelShow(false);
    };

    const lastNameLabelShow = () => {
        setIsLastNameLabelShow(true);
    };

    const lastNameLabelHide = () => {
        setIsLastNameLabelShow(false);
    };

    const firstNameLabelShow = () => {
        setIsFirstNameLabelShow(true);
    };

    const firstNameLabelHide = () => {
        setIsFirstNameLabelShow(false);
    };
    function handleConsentClick(consentId: PersonalDetailsConsent) {
        if (PersonalDetailsConsent.FIRST_CONSENT === consentId) {
            setIsFirstConsentDisabled(false);
        }
        if (PersonalDetailsConsent.SECOND_CONSENT === consentId) {
            setIsSecondConsentDisabled(false);
        }
    }
    const createPersonalDetailsStep = () => {
        const vehicleDetails = osbVehicleStep.vehicleDetails;
        if (
            vehicleDetails.vin !== '' &&
            personalDetail?.registrationNumber?.length > 0
        ) {
            vehicleDetails.registrationNumber =
                personalDetail.registrationNumber;
            setOSBVehicleStepPayload({ vehicleDetails: vehicleDetails }, false);
        }
    };
    const handleVinRegFieldData = () => {
        if (osbVehicleStep.vehicleDetails.vin === '') {
            const isRegValid = handleRegistrationNumberValidation(
                personalDetail.registrationNumber
            );
            return isRegValid;
        } else {
            return true;
        }
    };
    const validatePersonalDetails = () => {
        const isValidFn = handleFirstNameValidation(personalDetail.firstName);
        const isValidLn = handleLastNameValidation(personalDetail.lastName);
        const isValidCn = displayCountryDialCodeComponent
            ? handleContactNumberValidation(
                  personalDetail.contactNumber,
                  personalDetail.countryDialCodeObject.countryRegex
              )
            : handleContactNumberValidation(personalDetail.contactNumber);
        const isValidEmail = handleEmailValidation(personalDetail.email);
        const isValidRn = handleVinRegFieldData();
        if (
            !isValidFn ||
            !isValidLn ||
            !isValidCn ||
            !isValidEmail ||
            !isValidRn
        ) {
            if (!isRetrieveStep) {
                triggerContactDetailsErrorAnalytics(
                    invalidFormFieldsForAnalytic,
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT
                );
            }
            if (isRetrieveStep) {
                triggerRetrieveAmendContactDetailsErrorAnalytics(
                    invalidFormFieldsForAnalytic,
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
                );
            }
        }

        if (isValidFn && isValidLn && isValidCn && isValidEmail && isValidRn) {
            return true;
        }
        return false;
    };
    const updateFordAccount = async () => {
        const payload: any = {};
        if (osbPersonalDetail.title !== personalDetail.title) {
            payload['title'] = personalDetail.title;
        }
        if (osbPersonalDetail.firstName !== personalDetail.firstName) {
            payload['firstName'] = personalDetail.firstName;
        }
        if (osbPersonalDetail.lastName !== personalDetail.lastName) {
            payload['lastName'] = personalDetail.lastName;
        }
        if (osbPersonalDetail.contactNumber !== personalDetail.contactNumber) {
            payload['phoneNumber'] = personalDetail.contactNumber;
        }
        await ServiceHandler.FordAccountService.updateUserPersonalInfo(payload);
    };
    const goToReviewBookingStep = () => {
        invalidateAuthentication();
        const isValid = validatePersonalDetails();
        if (isValid) {
            triggerBookServiceGlobalCTAOnClickAnalytics(
                LIGHT_JOURNEY_ANALYTICS.REVIEW_BOOKING_BTN_CTA_NAME,
                fireEvents,
                osbVehicleStep
            );
            if (personalDetail.registrationNumber) {
                /*calls a vehicle lookup async api call to validate reg no */
                const vehicleData: VehicleData = {
                    registrationNumber: formatRegVinNumber(
                        personalDetail.registrationNumber,
                        osbVehicleStep.allowSpecialCharacters
                    ),
                    mileage: osbVehicleStep.defaultMileage,
                };
                ServiceHandler.OsbVehicleLookUp.postVehicleInfo({
                    vehicleData: vehicleData,
                    prognosticsData: osbVehicleStep.vehiclePrognosticData,
                    dealerCode: osbDealerStep.selectedDealerProfile.dealerCode,
                    bookable: bookable,
                })
                    .then()
                    .catch((error: any) => {
                        dispatch({
                            type: 'ERROR',
                            error: error.errorMessage,
                        });
                    });
            }
            createPersonalDetailsStep();
            setOSBPersonalDetail(personalDetail, false);
            if (osbStep.isAuthenticatedFlow && saveToMyFordCheckboxValue) {
                // update personal details value to my ford account
                updateFordAccount();
            }
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.CONTACT, true);
            if (osbStep.isRetrieveFlow) {
                history.push(
                    buildNavigationUrl(
                        OsbPathBookingRetrieveSummaryStep(),
                        osbStep.UrlQueryParams
                    )
                );
            } else {
                setRouteToPersist(
                    OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
                    OsbPathBookingReviewStep(),
                    osbStep.localStorageExpiry
                );
                history.push(
                    buildNavigationUrl(
                        OsbPathBookingReviewStep(),
                        osbStep.UrlQueryParams
                    )
                );
            }
        }
    };
    const getPersonalDetailsContent = async (modelName: string) => {
        await callContentService(modelName)
            .then(results => {
                setPersonalDetailsJson(results.elements);
                setFirstConsentLabel(
                    getObjectFromAEMJson(
                        PERSONAL_DETAIL_STEP_KEYS.CONSENT_FIRST_CHECKBOX_LABEL,
                        results.elements
                    )
                );
                setSecondConsentLabel(
                    getObjectFromAEMJson(
                        PERSONAL_DETAIL_STEP_KEYS.CONSENT_SECOND_CHECKBOX_LABEL,
                        results.elements
                    )
                );
                // check if the consent has anchor tag or not.
                // if anchor tag present, disable consent checkbox till user click on anchor tag.
                if (
                    isPrivacyAnchorTagPresent(
                        PersonalDetailsConsent.FIRST_CONSENT,
                        results.elements
                    )
                ) {
                    setIsFirstConsentDisabled(true);
                }
                if (
                    isPrivacyAnchorTagPresent(
                        PersonalDetailsConsent.SECOND_CONSENT,
                        results.elements
                    )
                ) {
                    setIsSecondConsentDisabled(true);
                }

                if (results) {
                    dispatch({ type: 'RESPONSE' });
                }
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };

    function selectedCountry(
        countrySelected: CountryDialCodePickerOptions | undefined,
        initialLoad: boolean
    ) {
        if (countrySelected) {
            if (personalDetail.contactNumber.length > 0 && initialLoad) {
                setComponentPayload({
                    contactNumber: personalDetail.contactNumber.startsWith(
                        countrySelected.countryDialCode
                    )
                        ? personalDetail.contactNumber
                        : `${countrySelected.countryDialCode}${personalDetail.contactNumber}`,
                });
            } else {
                setComponentPayload({
                    contactNumber: countrySelected.countryDialCode,
                });
            }
            //countryDialCodeObject
            setComponentPayload({
                countryDialCodeObject: countrySelected,
            });
        }
    }

    function getCountryCodeFromContactNumber(contactNumber: string) {
        let countryCode = '';
        if (countryDialCodeContent?.dropdownOptions) {
            for (const item of countryDialCodeContent?.dropdownOptions || []) {
                if (contactNumber.startsWith(item.countryDialCode)) {
                    countryCode = item.countryCode;
                    break;
                }
            }
        }
        return countryCode;
    }

    useEffect(() => {
        if (countryDialCodeContent?.dropdownOptions) {
            if (osbPersonalDetail.contactNumber.length > 0) {
                const countryCode = getCountryCodeFromContactNumber(
                    osbPersonalDetail.contactNumber
                );
                if (countryCode.length > 0) {
                    setDefaultMarketCodeToDisplay(countryCode);
                } else {
                    setDefaultMarketCodeToDisplay(appConfig.countryCode);
                }
            } else {
                setDefaultMarketCodeToDisplay(appConfig.countryCode);
            }
            setCountryCodeData(countryDialCodeContent);
        }
    }, [countryDialCodeContent]);

    useEffect(() => {
        fetchAvailability();
    }, []);

    useEffect(() => {
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.CONTACT, false, true);
        const stepDetails = osbStepProgressBar.progressBarDetails.find(
            step =>
                step.key === STEP_PROGRESS_BAR_KEYS.CONTACT && step.isEditable
        );
        if (stepDetails || osbStep.isRetrieveFlow) {
            setRouteToPersist(
                OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
                OsbPathPersonalDetailsStep(),
                osbStep.localStorageExpiry
            );
            dispatch({ type: 'REQUEST' });
            getPersonalDetailsContent('osb-personal-details-step');
            setComponentPayload(
                getClientStorageValue(
                    OSB_CLIENT_STORAGE_KEYS.OSB_PERSONAL_DETAIL_STEP_KEY
                ) || osbPersonalDetail
            );
            // resetting consent checkbox value, to ensure checkbox is unchecked
            setComponentPayload({
                isFirstConsentEnabled: false,
                isSecondConsentEnabled: false,
            });
            if (
                osbVehicleStep.vehicleStepFlow === 'manual' &&
                !osbVehicleStep.vehicleDetails.vin
            ) {
                setComponentPayload({
                    registrationNumber:
                        osbPersonalDetail?.registrationNumber ||
                        osbVehicleStep.vehicleDetails.registrationNumber ||
                        '',
                });
            }

            if (!isRetrieveStep) {
                triggerDealerContactDetailsAnalytics(
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT
                );
            }
            if (isRetrieveStep) {
                triggerRetrieveAmendContactDetailsLoadAnalytics(
                    osbVehicleStep,
                    osbStep,
                    fireEvents,
                    LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
                );
            }
            if (serverSideService.isClientSide()) {
                window.scrollTo({
                    top: getGoMainHeaderHeight() - 2,
                    behavior: 'smooth',
                });
            }
        }
    }, []);

    useEffect(() => {
        if (personalDetailsJson) {
            setDisableReviewBookingButtonValue(!enableReviewBookingButton());
        }
    }, [
        personalDetail.firstName,
        personalDetail.lastName,
        personalDetail.contactNumber,
        personalDetail.email,
        personalDetail.registrationNumber,
        personalDetail.isFirstConsentEnabled,
        personalDetail.isSecondConsentEnabled,
        personalDetailsJson,
    ]);

    const getSubHeading = (): JSX.Element => {
        return (
            <div className="personal-details-flow-sub-header">
                {parse(getObjectFromAEMJson('text', personalDetailsJson))}
            </div>
        );
    };
    const handleOsbSaveToMyFordChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setSaveToMyFordCheckboxValue(event.currentTarget.checked);
    };
    return (
        <>
            {httpState.isLoading ? (
                <OsbLoader osbEmptyContentHeight={true} />
            ) : (
                <>
                    <div className="osb-light-journey-personal-details-page">
                        <div className="osb-personal-details-section">
                            <div className="osb-light-journey-personal-details-flow-container">
                                <>
                                    {(osbStep.isWebViewJourney ||
                                        !isMobileView) &&
                                        !isRetrieveStep && (
                                            <OSBSecondaryButton
                                                dataTestId="BackLinkToDataAndTimePage"
                                                type="svg"
                                                direction="left"
                                                text={getObjectFromAEMJson(
                                                    PERSONAL_DETAIL_STEP_KEYS.BACK_BUTTON,
                                                    personalDetailsJson
                                                )}
                                                onClickHandler={
                                                    goToDateTimeStep
                                                }
                                            />
                                        )}

                                    <div className="personal-details-flow-header">
                                        {parse(
                                            getObjectFromAEMJson(
                                                PERSONAL_DETAIL_STEP_KEYS.HEADING,
                                                personalDetailsJson
                                            )
                                        )}
                                    </div>

                                    <div className="osb-personal-details-form">
                                        <div className="osb-personal-details-title">
                                            <Dropdown
                                                dataTestId="PersonTitleDropDown"
                                                label={getObjectFromAEMJson(
                                                    PERSONAL_DETAIL_STEP_KEYS.AUTOFILL_TITLE_LABEL,
                                                    personalDetailsJson
                                                )}
                                                options={getObjectFromAEMJson(
                                                    PERSONAL_DETAIL_STEP_KEYS.OPTIONS,
                                                    personalDetailsJson
                                                )}
                                                value={personalDetail.title}
                                                onChange={e => onTitleChange(e)}
                                            />
                                        </div>
                                        <br></br>
                                        <div className="osb-personal-details-first-name">
                                            <label className="input-label">
                                                {(personalDetail?.firstName
                                                    ?.length > 0 ||
                                                    isFirstNameLabelShow) && (
                                                    <span>
                                                        {getObjectFromAEMJson(
                                                            PERSONAL_DETAIL_STEP_KEYS.FIRST_NAME_LABEL,
                                                            personalDetailsJson
                                                        )}
                                                    </span>
                                                )}
                                                {firstNameErrorMsg ? (
                                                    <img
                                                        className="osb-error-icon"
                                                        src={errorWarning}
                                                    />
                                                ) : (
                                                    <span></span>
                                                )}
                                            </label>
                                            <input
                                                id="firstName"
                                                data-testid="EnterFirstName"
                                                type="text"
                                                className={`input-field${
                                                    firstNameErrorMsg
                                                        ? '-error'
                                                        : ''
                                                } `}
                                                placeholder={getObjectFromAEMJson(
                                                    PERSONAL_DETAIL_STEP_KEYS.FIRST_NAME_PLACEHOLDER,
                                                    personalDetailsJson
                                                )}
                                                aria-label="firstName"
                                                aria-labelledby="firstName"
                                                name="firstName"
                                                value={personalDetail.firstName}
                                                onChange={e =>
                                                    onFirstNameChange(e)
                                                }
                                                onBlur={firstNameLabelHide}
                                                onFocus={firstNameLabelShow}
                                            />
                                            {firstNameErrorMsg ? (
                                                <div className="osb-error-message">
                                                    {firstNameErrorMsg}
                                                </div>
                                            ) : (
                                                <span></span>
                                            )}
                                        </div>
                                        <br></br>
                                        <div className="osb-personal-details-last-name">
                                            <label className="input-label">
                                                {(personalDetail?.lastName
                                                    ?.length > 0 ||
                                                    isLastNameLabelShow) && (
                                                    <span>
                                                        {getObjectFromAEMJson(
                                                            PERSONAL_DETAIL_STEP_KEYS.LAST_NAME_LABEL,
                                                            personalDetailsJson
                                                        )}
                                                    </span>
                                                )}
                                                {lastNameErrorMsg ? (
                                                    <img
                                                        className="osb-error-icon"
                                                        src={errorWarning}
                                                    />
                                                ) : (
                                                    <span></span>
                                                )}
                                            </label>
                                            <input
                                                id="lastName"
                                                data-testid="EnterLastName"
                                                type="text"
                                                className={`input-field${
                                                    lastNameErrorMsg
                                                        ? '-error'
                                                        : ''
                                                } `}
                                                placeholder={getObjectFromAEMJson(
                                                    PERSONAL_DETAIL_STEP_KEYS.LAST_NAME_PLACEHOLDER,
                                                    personalDetailsJson
                                                )}
                                                aria-label="lastName"
                                                aria-labelledby="lastName"
                                                name="lastName"
                                                value={personalDetail.lastName}
                                                maxLength={200}
                                                onChange={e =>
                                                    onLastNameChange(e)
                                                }
                                                onBlur={lastNameLabelHide}
                                                onFocus={lastNameLabelShow}
                                            />
                                            {lastNameErrorMsg ? (
                                                <div className="osb-error-message">
                                                    {lastNameErrorMsg}
                                                </div>
                                            ) : (
                                                <span></span>
                                            )}
                                        </div>
                                        <br></br>
                                        {displayCountryDialCodeComponent ? (
                                            <>
                                                <div className="osb-personal-details-country-dial-code">
                                                    <label className="input-label">
                                                        <span>
                                                            {getObjectFromAEMJson(
                                                                PERSONAL_DETAIL_STEP_KEYS.COUNTRY_DIAL_CODE_LABEL,
                                                                personalDetailsJson
                                                            )}
                                                        </span>
                                                    </label>
                                                    <CountryDialCodePicker
                                                        // country json data authored in EF
                                                        countryDialCodeContent={
                                                            countryCodeData
                                                        }
                                                        // default merket code displayed in dropdown
                                                        displayDefaultMarketCode={
                                                            defaultMarketCodeToDisplay
                                                        }
                                                        // method to handle selected country
                                                        selectedCountry={
                                                            selectedCountry
                                                        }
                                                    />
                                                </div>
                                                <br></br>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="osb-personal-details-contact-number">
                                            <label className="input-label">
                                                {(personalDetail?.contactNumber
                                                    ?.length > 0 ||
                                                    isContactNumberLabelShow) && (
                                                    <span>
                                                        {getObjectFromAEMJson(
                                                            PERSONAL_DETAIL_STEP_KEYS.CONTACT_NUMBER_LABEL,
                                                            personalDetailsJson
                                                        )}
                                                    </span>
                                                )}
                                                {contactNumberErrorMsg ? (
                                                    <img
                                                        className="osb-error-icon"
                                                        src={errorWarning}
                                                    />
                                                ) : (
                                                    <span></span>
                                                )}
                                            </label>
                                            <input
                                                id="contactNumber"
                                                data-testid="EnterContactNumber"
                                                type="text"
                                                className={`input-field${
                                                    contactNumberErrorMsg
                                                        ? '-error'
                                                        : ''
                                                } `}
                                                placeholder={getObjectFromAEMJson(
                                                    PERSONAL_DETAIL_STEP_KEYS.CONTACT_NUMBER_PLACEHOLDER,
                                                    personalDetailsJson
                                                )}
                                                aria-label="contactNumber"
                                                aria-labelledby="contactNumber"
                                                name="contactNumber"
                                                value={
                                                    personalDetail.contactNumber
                                                }
                                                onChange={e =>
                                                    onContactNumberChange(e)
                                                }
                                                onBlur={contactNumberLabelHide}
                                                onFocus={contactNumberLabelShow}
                                            />
                                            {contactNumberErrorMsg ? (
                                                <div className="osb-error-message">
                                                    {contactNumberErrorMsg}
                                                </div>
                                            ) : (
                                                <span></span>
                                            )}
                                        </div>
                                        <br></br>
                                        <div className="osb-personal-details-email">
                                            <label className="input-label">
                                                {(personalDetail?.email
                                                    ?.length > 0 ||
                                                    isEmailLabelShow) && (
                                                    <span>
                                                        {getObjectFromAEMJson(
                                                            PERSONAL_DETAIL_STEP_KEYS.EMAIL_LABEL,
                                                            personalDetailsJson
                                                        )}
                                                    </span>
                                                )}
                                                {emailErrorMsg ? (
                                                    <img
                                                        className="osb-error-icon"
                                                        src={errorWarning}
                                                    />
                                                ) : (
                                                    <span></span>
                                                )}
                                            </label>
                                            <input
                                                id="email"
                                                data-testid="EnterEmail"
                                                type="text"
                                                className={`input-field${
                                                    emailErrorMsg
                                                        ? '-error'
                                                        : ''
                                                } `}
                                                placeholder={getObjectFromAEMJson(
                                                    PERSONAL_DETAIL_STEP_KEYS.EMAIL_PLACEHOLDER,
                                                    personalDetailsJson
                                                )}
                                                aria-label="email"
                                                aria-labelledby="email"
                                                name="email"
                                                value={personalDetail.email}
                                                onChange={e => onEmailChange(e)}
                                                onBlur={emailLabelHide}
                                                onFocus={emailLabelShow}
                                            />
                                            {emailErrorMsg ? (
                                                <div className="osb-error-message">
                                                    {emailErrorMsg}
                                                </div>
                                            ) : (
                                                <span></span>
                                            )}
                                        </div>
                                        <br></br>
                                        {((!osbVehicleStep.vehicleDetails.vin &&
                                            !osbVehicleStep.vehicleDetails
                                                .registrationNumber) ||
                                            (osbStep.isRetrieveFlow &&
                                                osbVehicleStep.vehicleDetails
                                                    .registrationNumber &&
                                                !osbVehicleStep.vehicleDetails
                                                    .vin)) && (
                                            <div className="osb-personal-details-registration-number">
                                                <label className="input-label">
                                                    {(personalDetail
                                                        ?.registrationNumber
                                                        ?.length > 0 ||
                                                        isregistrationNumberLabelShow) && (
                                                        <span>
                                                            {getObjectFromAEMJson(
                                                                PERSONAL_DETAIL_STEP_KEYS.REG_NUMBER_LABEL,
                                                                personalDetailsJson
                                                            )}
                                                        </span>
                                                    )}
                                                    {registrationNumberErrorMsg ? (
                                                        <img
                                                            className="osb-error-icon"
                                                            src={errorWarning}
                                                        />
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                </label>
                                                <input
                                                    id="registrationNumber"
                                                    data-testid="EnterRegistrationNumber"
                                                    type="text"
                                                    className={`input-field${
                                                        registrationNumberErrorMsg
                                                            ? '-error'
                                                            : ''
                                                    } `}
                                                    placeholder={getObjectFromAEMJson(
                                                        PERSONAL_DETAIL_STEP_KEYS.REG_NUMBER_PLACEHOLDER,
                                                        personalDetailsJson
                                                    )}
                                                    aria-label="registrationNumber"
                                                    aria-labelledby="registrationNumber"
                                                    name="registrationNumber"
                                                    value={
                                                        personalDetail.registrationNumber
                                                    }
                                                    onChange={e =>
                                                        onRegistrationNumberChange(
                                                            e
                                                        )
                                                    }
                                                    onBlur={
                                                        registrationNumberLabelHide
                                                    }
                                                    onFocus={
                                                        registrationNumberLabelShow
                                                    }
                                                />

                                                {registrationNumberErrorMsg ? (
                                                    <div className="osb-error-message">
                                                        {
                                                            registrationNumberErrorMsg
                                                        }
                                                    </div>
                                                ) : (
                                                    <span></span>
                                                )}

                                                <br></br>
                                            </div>
                                        )}
                                        {showSaveToMyFordCheckbox && (
                                            <div className="osb-save-to-my-ford-account-container">
                                                <input
                                                    data-testid="CheckboxToSaveUpdatedPersonalDetails"
                                                    type="checkbox"
                                                    onChange={
                                                        handleOsbSaveToMyFordChange
                                                    }
                                                    aria-label="checkbox"
                                                    className="save-to-my-ford-account-checkbox"
                                                />
                                                <label className="osb-save-to-my-ford-account-label">
                                                    {getObjectFromAEMJson(
                                                        PERSONAL_DETAIL_STEP_KEYS.SAVE_TO_MY_FORD_LABEL,
                                                        personalDetailsJson
                                                    )}
                                                </label>
                                            </div>
                                        )}
                                        {getSubHeading()}
                                        {(isAEMConsentEnabled(
                                            PersonalDetailsConsent.FIRST_CONSENT
                                        ) ||
                                            isAEMConsentEnabled(
                                                PersonalDetailsConsent.SECOND_CONSENT
                                            )) && (
                                            <div className="consent-checkbox-container">
                                                {isAEMConsentEnabled(
                                                    PersonalDetailsConsent.FIRST_CONSENT
                                                ) && (
                                                    <div className="consent-checkbox-section">
                                                        <label
                                                            className="consent-checkbox-label"
                                                            htmlFor="consent-checkbox-1"
                                                        >
                                                            <input
                                                                id="consent-checkbox-1"
                                                                data-testid="checkbox-1"
                                                                type="checkbox"
                                                                className="consent-checkbox"
                                                                placeholder="consent-checkbox-1"
                                                                name="consent-checkbox-1"
                                                                value={personalDetail.isFirstConsentEnabled.toString()}
                                                                onClick={(
                                                                    e: any
                                                                ) =>
                                                                    setComponentPayload(
                                                                        {
                                                                            isFirstConsentEnabled:
                                                                                e
                                                                                    .target
                                                                                    .checked,
                                                                        }
                                                                    )
                                                                }
                                                                aria-label="consent-checkbox-1"
                                                                defaultChecked={
                                                                    false
                                                                }
                                                                disabled={
                                                                    isFirstConsentDisabled
                                                                }
                                                            />
                                                            <span>
                                                                {
                                                                    firstConsentLabel
                                                                }
                                                                <a
                                                                    href={
                                                                        firstConsentAnchorURL
                                                                    }
                                                                    onClick={() =>
                                                                        handleConsentClick(
                                                                            PersonalDetailsConsent.FIRST_CONSENT
                                                                        )
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {' '}
                                                                    {
                                                                        firstConsentAnchorText
                                                                    }
                                                                </a>
                                                            </span>
                                                        </label>
                                                    </div>
                                                )}
                                                {isAEMConsentEnabled(
                                                    PersonalDetailsConsent.SECOND_CONSENT
                                                ) && (
                                                    <div>
                                                        <label
                                                            className="consent-checkbox-label"
                                                            htmlFor="consent-checkbox-2"
                                                        >
                                                            <input
                                                                id="consent-checkbox-2"
                                                                data-testid="checkbox-2"
                                                                type="checkbox"
                                                                className="consent-checkbox"
                                                                placeholder="consent-checkbox-2"
                                                                name="consent-checkbox-2"
                                                                value={personalDetail.isSecondConsentEnabled.toString()}
                                                                onClick={(
                                                                    e: any
                                                                ) =>
                                                                    setComponentPayload(
                                                                        {
                                                                            isSecondConsentEnabled:
                                                                                e
                                                                                    .target
                                                                                    .checked,
                                                                        }
                                                                    )
                                                                }
                                                                aria-label="consent-checkbox-2"
                                                                defaultChecked={
                                                                    false
                                                                }
                                                                disabled={
                                                                    isSecondConsentDisabled
                                                                }
                                                            />
                                                            <span>
                                                                {
                                                                    secondConsentLabel
                                                                }
                                                                <a
                                                                    href={
                                                                        secondConsentAnchorURL
                                                                    }
                                                                    onClick={() =>
                                                                        handleConsentClick(
                                                                            PersonalDetailsConsent.SECOND_CONSENT
                                                                        )
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {' '}
                                                                    {
                                                                        secondConsentAnchorText
                                                                    }
                                                                </a>
                                                            </span>
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className="button-container">
                                            <div className="second-button">
                                                <FMButton
                                                    type="primary"
                                                    label={getObjectFromAEMJson(
                                                        PERSONAL_DETAIL_STEP_KEYS.SUBMIT_BUTTON,
                                                        personalDetailsJson
                                                    )}
                                                    disabled={
                                                        disableReviewBookingButtonValue
                                                    }
                                                    onClick={
                                                        goToReviewBookingStep
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                        {!isMobileView && (
                            <div className="osb-light-journey-booking-summary">
                                <LightBookingSummary
                                    showVoucherLink={true}
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {isMobileView && (
                        <div className="osb-mobile-booking-summary">
                            <div className="view-summary-accordion">
                                <BookingSummaryAccordion
                                    index="0"
                                    className=""
                                    expandMultiplePanels={true}
                                    showVoucherLink={true}
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
