import { PERSISTENCE_MESSAGE_TYPES } from '../eu-help-router';
import {
    FILTER_CHAT_HISTORY,
    REPLACE_CHAT_HISTORY,
    UPDATE_LAST_MESSAGE,
    UPDATE_QUICK_REPLIES_LAST,
} from '../actionTypes';
import { KEYS, MESSAGE_ORIGIN, MESSAGE_TYPES } from '../emplifi-wrapper';
import React, { Dispatch, SetStateAction } from 'react';

interface HelpRouterHandleMessage {
    isMessageTypeNewMessage: (message: any) => boolean;
    persistentMessageType: (message: any) => void;
    message: string;
}

interface ProcessFeedbackMessage {
    message: type;
}

type type = {
    type: string;
};

interface ReceiverMessageType {
    payload: any;
    dispatch: any;
}

interface HandleUserMessage {
    message: string;
    updateChatHistory: any;
    dispatch: any;
}

interface HandleBotResponse {
    message: message;
    dispatch: any;
}

type message = {
    type: number;
    isVertical: null | undefined;
    content: any;
    fullContent: null | undefined;
};

interface ConversationIdAndTabRegistryExists {
    conversationIdExists: boolean;
    tabRegistryExists: boolean;
    loadStoredConversation: any;
    handleTabSynchronization: any;
    setGoToAnalog: Dispatch<SetStateAction<boolean>>;
}

interface Cookies {
    cookies: any;
    setCookie: any;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
}

interface IsOpen {
    isOpen: boolean;
    onEscape: any;
}

export const EuHelpRouterHandleMessage = (props: HelpRouterHandleMessage) => {
    console.log('innn method', props.message);
    if (props.isMessageTypeNewMessage(props.message)) {
        console.log('innn if', props.message);
        props.persistentMessageType(props.message);
    }
};

export const EuHelpRouterProcessFeedbackMessage = (
    props: ProcessFeedbackMessage
) => {
    if (props.message?.type !== PERSISTENCE_MESSAGE_TYPES.FEEDBACK_RECEIVED)
        return null;
};

export const EuHelpRouterFeedbackReceiverMessageType = (
    props: ReceiverMessageType
) => {
    if (props.payload) {
        props.dispatch({ type: REPLACE_CHAT_HISTORY, payload: props.payload });
    }
};

export const EuHelpRouterHandleUserMessage = (props: HandleUserMessage) => {
    if (props.message !== '') {
        console.log('innn if', props.message);
        props.updateChatHistory(
            MESSAGE_ORIGIN.USER,
            MESSAGE_TYPES.TEXT,
            props.message
        );
        props.dispatch({
            type: FILTER_CHAT_HISTORY,
            payload: (msg: { type: number }) =>
                msg.type !== MESSAGE_TYPES.QUICK_REPLIES,
        });
        props.dispatch({ type: UPDATE_LAST_MESSAGE, payload: props.message });
        props.dispatch({ type: UPDATE_QUICK_REPLIES_LAST, payload: false });
    }
};

export const EuHelpRouterHandleBotResponse = (props: HandleBotResponse) => {
    if (
        props.message.type === MESSAGE_TYPES.QUICK_REPLIES &&
        !props.message.isVertical
    ) {
        props.dispatch({
            type: UPDATE_QUICK_REPLIES_LAST,
            payload: true,
        });
    }
};

export const UpdateChatHistoryIfNecessary = async (
    chatMessagesWithId: string | any[],
    chatHistoryRef: React.MutableRefObject<any>,
    dispatch: {
        (value: { type: string; payload?: any }): void;
        (arg0: { type: string; payload: any }): void;
    }
) => {
    const shouldBePersisted = (
        msg: { type: number },
        i: number,
        allMessages: string | any[]
    ) =>
        msg.type !== MESSAGE_TYPES.QUICK_REPLIES ||
        i === allMessages.length - 1;

    if (chatMessagesWithId.length > chatHistoryRef.current.length) {
        dispatch({
            type: REPLACE_CHAT_HISTORY,
            payload: chatMessagesWithId,
        });

        dispatch({
            type: FILTER_CHAT_HISTORY,
            payload: shouldBePersisted,
        });

        const persistenceData = {
            type: PERSISTENCE_MESSAGE_TYPES.FEEDBACK_RECEIVED,
        };

        localStorage.setItem(
            KEYS.LAST_MESSAGE,
            JSON.stringify(persistenceData)
        );
    }
};

export const EuHelpRouterConversationIdExists = (
    props: ConversationIdAndTabRegistryExists
) => {
    if (props.conversationIdExists && props.tabRegistryExists) {
        props.loadStoredConversation().then(() => {
            window.addEventListener('storage', props.handleTabSynchronization);
            props.setGoToAnalog(true);
        });
    } else if (props.conversationIdExists) {
        window.localStorage.removeItem(KEYS.CONVERSATION_ID);
    }
};

export const EUHelpRouterCookie = (props: Cookies) => {
    if (props.cookies['sticky-popup-displayed'] != 1) {
        props.setCookie('sticky-popup-displayed', 1, {
            expires: new Date(Date.now() + 86400000),
        });
    } else props.setIsVisible(false);
};

export const EuHelpRouterIsOpen = (props: IsOpen) => {
    if (props.isOpen) {
        document.addEventListener('keydown', props.onEscape, false);
    }
    return () => {
        if (props.isOpen) {
            document.removeEventListener('keydown', props.onEscape, false);
        }
    };
};
