import React, { useState, useRef, useEffect } from 'react';
import './dealer-search-dropdown.scss';
import { useLocation } from 'react-router-dom';
import useMarketDealerInfoService from '../../../../hooks/owners-osb/use-market-dealerInfo-service';
import { useOSBStep } from '../../../../hooks/owners-osb';
import GoogleMapService from '../../../../services/osb-service/google-map-service/google-map-service';
import { FMInput } from '@own/fds-react';

interface DealerOption {
    label: string;
    type: 'dealer';
    dealerCode: string;
}

interface LocationOption {
    label: string;
    type: 'location';
}

type CombinedOption = DealerOption | LocationOption;

interface Props {
    onSelect: (value: string, type: 'dealer' | 'location') => void;
    placeholder?: string;
    showFilter?: boolean;
    locationLabel?: string;
    serviceCentreLabel?: string;
    selectedValue?: string;
}

const DealerSearchDropdown = ({
    onSelect,
    placeholder = '',
    showFilter = true,
    locationLabel = '',
    serviceCentreLabel = '',
    selectedValue = '',
}: Props) => {
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const { osbStep } = useOSBStep();
    const [inputValue, setInputValue] = useState(selectedValue);
    const [filteredDealerOptions, setFilteredDealerOptions] = useState<
        { dealerCode: string; dealerName: string }[]
    >([]);
    const [filteredLocationOptions, setFilteredLocationOptions] = useState<
        string[]
    >([]);
    const [showOptions, setShowOptions] = useState(false);
    const [dealers, setDealers] = useState<
        { dealerCode: string; dealerName: string }[]
    >([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const inputRef = useRef<HTMLInputElement>(null);
    const optionsListRef = useRef<HTMLUListElement>(null);
    const deepLinkParams = new URLSearchParams(useLocation().search);
    const [showLocations, setShowLocations] = useState(true);
    const [showServiceCentres, setShowServiceCentres] = useState(true);

    useEffect(() => {
        GoogleMapService.loadScript('google');
        const fetchDealers = async () => {
            const data = await fetchMarketDealerInfo(deepLinkParams);
            const filteredDealers = data?.dealers.filter(
                (dealer: { dealerCode: string }) =>
                    dealer.dealerCode !== '00001'
            );
            filteredDealers && setDealers(filteredDealers);
        };
        fetchDealers();
    }, []);

    useEffect(() => {
        setInputValue(selectedValue);
    }, [selectedValue]);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        updateFilteredOptions(value);

        // Call GoogleMapService to get place predictions
        GoogleMapService.getPlacePredictions(
            value,
            updateLocationSuggestions,
            osbStep.geoCountryCode,
            false
        );
        setShowOptions(true);
    };

    const handleSelect = (option: CombinedOption) => {
        setInputValue(option.label);
        onSelect(
            option.type === 'dealer' ? option.dealerCode : option.label,
            option.type
        );
        setShowOptions(false);
    };

    const updateFilteredOptions = (value: string) => {
        const escapedValue = escapeString(value);
        const dealerMatches = dealers
            .filter(dealer =>
                dealer.dealerName
                    .toLowerCase()
                    .includes(escapedValue.toLowerCase())
            )
            .map(dealer => ({
                dealerCode: dealer.dealerCode,
                dealerName: dealer.dealerName,
            }));
        setFilteredDealerOptions(dealerMatches);
    };

    const updateLocationSuggestions = (predictions: any) => {
        const locationMatches = predictions.map(
            (prediction: any) => prediction.description
        );
        setFilteredLocationOptions(locationMatches);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target as Node)
        ) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!showOptions) return;

        if (event.key === 'ArrowDown') {
            setHighlightedIndex(prevIndex => {
                const newIndex =
                    prevIndex < combinedOptions.length - 1
                        ? prevIndex + 1
                        : prevIndex;
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex(prevIndex => {
                const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (event.key === 'Enter' && highlightedIndex >= 0) {
            handleSelect(combinedOptions[highlightedIndex]);
            setHighlightedIndex(-1);
        }
    };

    const scrollToItem = (index: number) => {
        if (optionsListRef.current) {
            const listItem = optionsListRef.current.children[
                index
            ] as HTMLElement;
            if (listItem) {
                listItem.scrollIntoView({ block: 'nearest' });
            }
        }
    };

    const handleLocationCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setShowLocations(event.target.checked);
    };

    const handleServiceCentreCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setShowServiceCentres(event.target.checked);
    };

    const escapeString = (unsafe: string): string => {
        return unsafe
            .replace(/\\/g, '\\\\')
            .replace(/\[/g, '\\[')
            .replace(/\]/g, '\\]')
            .replace(/\(/g, '\\(')
            .replace(/\)/g, '\\)');
    };
    const combinedOptions: CombinedOption[] = [
        ...filteredDealerOptions
            .filter(() => showServiceCentres)
            .map(option => ({
                label: option.dealerName,
                type: 'dealer' as const,
                dealerCode: option.dealerCode,
            })),
        ...filteredLocationOptions
            .filter(() => showLocations)
            .map(option => ({
                label: option,
                type: 'location' as const,
            })),
    ];

    return (
        <div className="osb-v3-dropdown-container" ref={inputRef}>
            <FMInput
                type="text"
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                required={true}
                placeholder={placeholder}
            />
            {showOptions && (
                <ul className="options-list" ref={optionsListRef}>
                    {showFilter && (
                        <li className="checkbox-item">
                            <div className="checkbox-container">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={showLocations}
                                        onChange={handleLocationCheckboxChange}
                                    />
                                    {locationLabel}
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={showServiceCentres}
                                        onChange={
                                            handleServiceCentreCheckboxChange
                                        }
                                    />
                                    {serviceCentreLabel}
                                </label>
                            </div>
                        </li>
                    )}
                    {combinedOptions.map((option, index) => (
                        <li
                            key={`option-${index}`}
                            tabIndex={0}
                            role="button"
                            onClick={() => handleSelect(option)}
                            onKeyDown={event => {
                                if (
                                    event.key === 'Enter' ||
                                    event.key === ' '
                                ) {
                                    handleSelect(option);
                                }
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(-1)}
                            className={`${
                                option.type === 'dealer'
                                    ? 'dealer-option'
                                    : 'location-option'
                            } ${
                                index === highlightedIndex ||
                                index === hoveredIndex
                                    ? 'highlighted'
                                    : ''
                            }`}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DealerSearchDropdown;
